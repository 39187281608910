<template>
  <div class="toggle-switch-container">
    <div class="toggle-switch" :class="{ 'checked': isChecked }" @click="toggleCheck">
      <div class="toggle-switch-inner"></div>
      <div class="toggle-switch-labels">
        <div class="open-label" :class="{ 'active': !isChecked }">Open</div>
        <div class="close-label" :class="{ 'active': isChecked }">Close</div>
      </div>
    </div>
  </div>
</template>

<style>
.toggle-switch {
  position: relative;
  width: 100px;
  height: 50px;
  background-color: #ddd;
  border-radius: 25px;
  cursor: pointer;
}

.toggle-switch-inner {
  position: absolute;
  top: 50%;
  left: 10px;
  width: 30px;
  height: 30px;
  transform: translateY(-50%);
  background-color: #E9F2EC;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.checked .toggle-switch-inner {
  left: calc(100% - 40px);
}

.toggle-switch-labels {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.open-label {
  margin-right: 10px;
  color: #666;
  font-size: 16px;
}


.close-label {
  color: #666;
  font-size: 16px;
}

.checked .close-label {
  margin-right: 10px;
}

.active {
  color: red
}

</style>

<script>
export default {
  data() {
    return {
      isChecked: false,
    };
  },
  methods: {
    toggleCheck() {
      this.isChecked = !this.isChecked;
    },
  },
};
</script>
