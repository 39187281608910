<template lang="pug">
drawer(:dismissRoute="'/user/address_book/'")
  .drawer-title
    i.mat-icon add_circle
    | New Contact

    contact-builder(:displaySave='true', @saveClick='createContact', :loading='loading')
</template>


<script>
import Drawer from '../layout/Drawer.vue'
import ContactBuilder from './blocks/ContactBuilder.vue'

function createContact ( data ) {
  const target = `/contacts`
  this.loading = true
  this.$simplecater.post(target, data).then((r)=>{
    this.$message("The contact has been created")
    this.loading = false
    this.$bus.$emit('contact-saved')
    this.$router.push('/user/address_book')
  }).catch(()=>{
    this.$message.error("Failed to create the contact")
    this.loading = false
  })
}

function data ( ) {
  return {
    loading: false,
  }
}

function created ( ) {}

const components = { Drawer, ContactBuilder }

const methods = { createContact }

let props = {
  id: {}
}

export default { props, data, created, components, methods }
</script>
