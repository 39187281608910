<template>
  <div class="drawer">
    <div class="drawer__dismisser" @click="dismiss">
      <!-- closes the drawer -->
    </div>
    <div class="drawer__content" :class="{ 'drawer__small': small }">
      <slot>
        Content slot
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dismissRoute: {required: true},
    small: Boolean
  },
  methods: {
    dismiss ( ) {
      console.log('Dimissing');
      this.$emit('close');
      this.$router.push(this.dismissRoute)
    }
  }
}
</script>

<style lang="sass">
.drawer
  overflow: hidden
  position: fixed
  z-index: 600
  top: 0
  left: 0
  right: 0
  bottom: 0

.drawer__dismisser
  position: absolute
  z-index: 610
  width: 100%
  height: 100%
  -webkit-animation-delay: 700ms
  -webkit-animation-duration: 1500ms
  background: rgba(51,51,51,0.3)
  top: 0
  left: 0

.drawer__content
  overflow: auto
  padding: 1rem 2rem
  position: absolute
  z-index: 620
  top: 0
  right: 0
  width: auto
  height: 100%
  background: #FAFAFA
  -webkit-box-shadow: -10px 0 31px -2px rgba(51,51,51,0.6)
  -moz-box-shadow: -10px 0 31px -2px rgba(51,51,51,0.6)
  box-shadow: -10px 0 31px -2px rgba(51,51,51,0.6)

.drawer__content::-webkit-scrollbar
    width: 10px
    
.drawer__small
  width: 520px    

.drawer__content::-webkit-scrollbar-track
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
    // border-radius: 10px
    // background-color: #bbb
    border-left: solid thin #eee

.drawer__content::-webkit-scrollbar-thumb
    border-radius: 10px
    background-color: #eee
    // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)

.drawer-title
  font-size: 1.2rem
  font-weight: 300
  line-height: $navbarHeight
  color: $colorPrimary
  background: white
  border-bottom: 1px solid #ddd
  margin: -1rem -2rem 1.5rem
  padding: 0 2rem
  vertical-align: middle
  i
    margin-right: 0.3rem
    vertical-align: middle
</style>
