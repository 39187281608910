<template lang='pug'>
el-dialog(
  :title='dialogTitle',
  :visible.sync='visibleDialog'
)
  el-form(
    :label-width='$labelWidth', label-position='right', ref='form'
    :model='delivery', :rules='rules'
    )

    el-alert(title="NOTE: Customer's credit card will be charged upon scheduling.", type="warning")
    br

    // TYPE
    el-form-item(label='Delivery type', prop='type')
      el-select(v-model='delivery.type', placeholder='Select an option')
        el-option(label='SimpleCater driver', value='SIMPLECATER_DRIVER')
        el-option(label='Customer pickup', value='CUSTOMER_PICKUP')

    // TIME
    el-form-item(label='Delivery date')
      el-date-picker(
        v-model='order.delivery_date',
        :disabled="!isDeliveries"
      )

    // TIME
    el-form-item(label='Delivery time')
      el-time-picker(
        v-model='order.delivery_time',
        format="hh:mm A",
        value-format="HH:mm"
        :disabled="!isDeliveries"
      )
    // TIME
    el-form-item(label='Pickup time', prop='pickup_time')
      el-time-picker(
        placeholder='Select a time', v-model='delivery.pickup_time',
        :picker-options="timePickerOptions",
        format="hh:mm A",
        value-format="HH:mm"
      )

    // NOTES
    el-form-item(:label='notesLabel', prop='notes', v-if='displayNotes')
      el-input(v-model='delivery.notes', placeholder='Michael')

  div.dialog-footer(slot='footer')
    // BUTTONS
    .buttons-section
      el-button(type='primary' @click='onSubmit', :loading='submitting')
        | {{isDeliveries ? 'Edit delivery' : 'Schedule delivery'}}
      el-button(@click='onCancel')
        | Cancel
</template>

<script>

  // import StatusTag from '../../common/StatusTag.vue'

  function data(){
    let deliveryId = null;
    let deliveryNotes = '';
    if (this.$parent.deliveries) {
      const delivery = this.$parent.deliveries.find( e => e.order_id === this.$parent.orderOnSchedule.id)
      deliveryId = delivery.type;
      deliveryNotes = delivery.notes;
    }
    return {
      visibleDialog: this.visible,
      submitting: false,
      rules: {

      },
      delivery: {
        type: deliveryId,
        notes: deliveryNotes,
        pickup_time: this.$parent.orderOnSchedule.delivery_pickup_time || null
      },
      timePickerOptions: {
        selectableRange: '00:00:00 - 23:59:00',
      },
      isDeliveries: this.$route.path === '/staff/deliveries'
    }
  }

  function onCancel(){
    this.$emit('update:visible', false)
  }

  function onSubmit(){
    this.$refs.form.validate((valid) => {
      if (valid) {
        this.scheduleDelivery()
      } else {
        this.$message.error('Some validations failed, please check your inputs')
        return false;
      }
    })
  }


  function scheduleDelivery(id){
    const target = `/deliveries`
    const body = Object.assign({}, this.delivery, {order_id: this.order.id})
    const getRequestType = () =>{
      if (this.isDeliveries) {
        return this.$simplecater.put(target, body)
      } else {
        return this.$simplecater.post(target, body)
      }
    };
    getRequestType().then((r)=>{
      this.visibleDialog = false
      this.$message("Delivery created")
      this.$bus.$emit('order-scheduled')
    }).catch(()=>{
      this.$message.error("Failed to schedule the delivery")
    })
  }

  const watch = {
    visibleDialog: function(newValue){
      this.$emit('update:visible', newValue)
    },
    visible: function(newValue){
      this.visibleDialog = newValue
    }
  }

  const computed = {
    notesLabel: function(){
      if (this.delivery.type == "SIMPLECATER_DRIVER") return "Driver's name"
      return "Notes"
    },
    displayNotes: function(){
      return this.delivery.type == "SIMPLECATER_DRIVER"
    },
    dialogTitle: function(){
      let title = `Schedule order "${this.order.request_name}" by ${this.order.customer_account_name}`;
      if (this.isDeliveries) {
        title = `Edit delivery "${this.order.request_name}" by ${this.order.customer_account_name}`
      }
      return title
    }
  }

  const components = {
  }

  const methods = {
    scheduleDelivery, onSubmit, onCancel
  }

  const props = {
    order: { required: true },
    visible: { required: true }
  }

  export default { methods, components, data, watch, props, computed }
</script>
