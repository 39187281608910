<template lang="pug">
.main-container
  .view-content
    el-row(:gutter='$rowGutter')

      el-col(:span='12')
        el-card
          div(slot='header')
            i.mat-icon assignment_turned_in
            | Orders
          template(v-if='loadingOrders')
            h4 Loading...
          template(v-else)
            el-row(:gutter='$rowGutter')
              el-col.count(:span='12')
                .number(:style="{color: orders.submitted.length > 0 ? '#F89333' : '#303133' }") {{ orders.submitted.length }}
                .desc Submitted
              el-col.count(:span='12')
                .number {{ orders.received.length }}
                .desc Received
            //- .card-cta
              el-button(type="primary", @click="viewOrders", style='float: right') View all

      el-col(:span='12')
        el-card
          div(slot='header')
            i.mat-icon assignment
            | Requests
          template(v-if='loadingRequests')
            h4 Loading...
          template(v-else)
            el-row(:gutter='$rowGutter')
              el-col.count(:span='12')
                .number {{ requests.open.length }}
                .desc Open
              el-col.count(:span='12')
                .number(:style="{color: requests.waitingForEstimates.length > 0 ? '#F89333' : '#303133' }") {{ requests.waitingForEstimates.length }}
                .desc Waiting
            //- .card-cta
              el-button(type="primary", @click="viewRequests", style='float: right') View all

    el-row(:gutter='$rowGutter')

      el-col(:span='24')
        el-card
          div(slot='header')
            i.mat-icon motorcycle
            | Deliveries
          template(v-if='loadingDeliveries')
            h4 Loading...
          template(v-else)
            el-row(:gutter='$rowGutter')
              el-col.count(:span='8')
                .number {{ deliveries.today.length }}
                .desc Today
              el-col.count(:span='8')
                .number {{ deliveries.tomorrow.length }}
                .desc Tomorrow
              el-col.count(:span='8')
                .number {{ deliveries.future.length }}
                .desc Future
            //- .card-cta
              el-button(type="primary", @click="viewDeliveries", style='float: right') View all

    el-row(:gutter='$rowGutter')
      el-col(:span='24')
        el-card
          div(slot='header')
            i.mat-icon attach_money
            | Sales Center
          template(v-if='loadingSales')
            h4 Loading...
          template(v-else)
            el-row(:gutter='$rowGutter')
              el-col.count(:span='6')
                .number.number-small {{ formatNumber(sales.day_total) }}
                .desc Day Total
              el-col.count(:span='6')
                .number.number-small {{ formatNumber(sales.day_month) }}
                .desc Day/Month
              el-col.count(:span='6')
                .number.number-small {{ formatNumber(sales.month) }}
                .desc Month
              el-col.count(:span='6')
                .number.number-small {{ formatNumber(sales.year) }}
                .desc Year
              el-col.count(:span='6')
                .number.number-small {{ sales.month_margin_percent }}%
                .desc Month/Margin

    el-row(:gutter='$rowGutter')
      el-col(:span='24')
        el-card
          div(slot='header')
            i.mat-icon receipt
            | Invoices
          template(v-if='loadingInvoices')
            h4 Loading...
          template(v-else)
            el-row(:gutter='$rowGutter')
              el-col.count(:span='3')
                .number.number-long {{ invoices.day_total }}
                .desc Day Total
              el-col.count(:span='3')
                .number.number-long {{ invoices.day_month }}
                .desc Day/Month
              el-col.count(:span='3')
                .number.number-long {{ invoices.month }}
                .desc Month
              el-col.count(:span='3')
                .number.number-long {{ invoices.year }}
                .desc Year
              el-col.count(:span='3')
                .number.number-long {{ formatNumber(invoices.avg_month) }}
                .desc Avg/Month
              el-col.count(:span='3')
                .number.number-long {{ formatNumber(invoices.avg_year) }}
                .desc Avg/Year
              el-col.count(:span='3')
                .number.number-long {{ formatNumber(invoices.total_open) }}
                .desc Open Total
              el-col.count(:span='3')
                .number.number-long {{ formatNumber(invoices.total_open_cc) }}
                .desc Open CC
              el-col.count(:span='3')
                .number.number-long {{ formatNumber(invoices.total_open_ha) }}
                .desc Open HA

    el-row(:gutter='$rowGutter')
      el-col(:span='24')
        el-card
          div(slot='header')
            i.mat-icon date_range
            | Invoice report
          template(v-if='loadingRequests')
            h4 Loading...
          template(v-else)
            el-row(:gutter='$rowGutter')
              el-col.count.count-range(:span='24')
                .overview__daterange
                  el-date-picker(
                    v-model='dateRange'
                    format='MM-dd-yyyy'
                    value-format='yyyy-MM-dd'
                    type='daterange'
                    range-separator='-'
                    start-placeholder='Start date'
                    end-placeholder='End date')
                  el-button(
                    type='primary'
                    @click='getInvoicesReports'
                    :loading='submitting')
                    | Generate report

</template>

<script>
import moment from 'moment'
  
  function data(){
    return {
      deliveries: null,
      invoices: null,
      orders: null,
      requests: null,
      sales: null,
      dateRange: [],
      loadingDeliveries: true,
      loadingInvoices: true,
      loadingOrders: true,
      loadingRequests: true,
      loadingSales: true,
      submitting: false
    }
  }

  function getDeliveries( ) {
    this.loadingDeliveries = true
    let deliveries = {
      today: [],
      tomorrow: [],
      future: [],
    }
    return this.$simplecater.get('/deliveries').then((r)=> {
      let today = moment(new Date(), "YYYY-MM-DD")
      let tomorrow = moment(new Date(), "YYYY-MM-DD").add(1, 'd')
      _.each(r.data.data, (x) => {
        if ( moment(x.date, "YYYY-MM-DD").isSame(today, 'day') ) {
          deliveries.today.push(x)
        } else if ( moment(x.date, "YYYY-MM-DD").isSame(tomorrow, 'day') ) {
          deliveries.tomorrow.push(x)
        } else if ( moment(x.date, "YYYY-MM-DD").isAfter(tomorrow) ) {
          deliveries.future.push(x)
        }
      })
      this.deliveries = deliveries
      this.loadingDeliveries = false
    })
  }

  function viewDeliveries ( ) {
    this.$router.push('/staff/deliveries')
  }

  function getInvoices ( ) {
    this.$simplecater.get('/statistics/invoices').then( ( r ) => {
      this.invoices = r.data.data
      this.loadingInvoices = false
    }).catch(() => {
      this.$message.error('Failed loading data')
    })
  }

  function getInvoicesReports() {
    this.submitting = true;
    const requestString = this.dateRange.length ? `?start_date=${this.dateRange[0]}&end_date=${this.dateRange[1]}` : '';
    this.$simplecater.get(`/statistics/invoices_report/${requestString}`).then(r => {
      this.submitting = false;
      if (r.status === 204) {
        this.$message.warning('No data for this date range');
      } else {
        this.downloadCsv(r.data);
      }
    }).catch(() => {
      this.$message.error('Failed loading data');
    })
  }

  function downloadCsv(data) {
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(data);
    hiddenElement.target = '_blank';
    hiddenElement.download = `invoices_range${this.dateRange[0]}/${this.dateRange[1]}.csv`;
    hiddenElement.click();
  }

  function formatNumber(cents){
    const fixedCents = (cents/100).toFixed(2);
    return `$${fixedCents.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`
}

  function viewInvoices ( ) {
    this.$router.push('/staff/invoices')
  }

  function getOrders ( ) {
    this.loadingOrders = true
    let orders = {
      submitted: null,
      received: null,
    }
    return this.$simplecater.get('/orders/submitted').then((r)=> {
      orders.submitted = r.data.data
      this.$simplecater.get('/orders/received').then((r)=> {
        orders.received = r.data.data
        this.orders = orders
        this.loadingOrders = false
      })
    })
  }

  function getSales ( ) {
    return this.$simplecater.get('/statistics/orders').then((r)=> {
      this.sales = r.data.data;
      this.loadingSales = false;
    }).catch(() => {
      this.$message.error('Failed loading data')
    })
  }

  function viewOrders ( ) {
    this.$router.push('/staff/orders')
  }

  function getRequests ( ) {
    this.loadingRequests = true
    return this.$simplecater.get('/requests/open').then((r)=> {
      let requests = {
        open: null,
        waitingForEstimates: null,
      }
      requests.open = _.filter(r.data.data, (x) => (x.status === 'ESTIMATES_AVAILABLE'))
      requests.waitingForEstimates = _.filter(r.data.data, (x) => (x.status === 'WAITING_FOR_ESTIMATES' || x.status === 'UPDATED_BY_CUSTOMER' || x.status === 'PENDING_CHANGES'))
      this.requests = requests
      this.loadingRequests = false
    })
  }

  function viewRequests ( ) {
    this.$router.push('/staff/requests')
  }

  function loadViewData ( ) {
    this.getDeliveries()
    this.getInvoices()
    this.getOrders()
    this.getRequests()
    this.getSales()
  }

  function created(){
    this.loadViewData()
  }

  const watch = {
    '$route' (to, from) {
      console.log("Reloading data")
      this.loadViewData()
    }
  }

  const methods = {
    getDeliveries,
    getInvoices,
    getOrders,
    getRequests,
    loadViewData,
    viewRequests,
    viewOrders,
    viewInvoices,
    viewDeliveries,
    getInvoicesReports,
    downloadCsv,
    getSales,
    formatNumber
  }

  export default { methods, data, created }

</script>

<style scoped lang="sass">
  .el-row
    display: flex
    flex-direction: rows
    margin: 1.2rem 0 0
    &:first-child
      margin: 0
    .el-col-12
      .el-card
        height: 100%
  .count
    padding-bottom: 1.5rem
    &-range
      padding: 13px 0
    .number
      font-size: 1.6rem
      font-weight: 700
      text-align: center
      padding: 10px 0
      &-small
        padding: 15px 0
        font-size: 1.2rem
      &-long
        padding: 18px 0 19px
        font-size: 0.9rem
    .desc
      color: #aaa
      font-size: 0.8rem
      text-align: center
      font-weight: 600
      text-transform: uppercase
  .card-cta
    margin: 1rem 0 0
    border-top: 1px solid #eee
    padding: 0.5rem 0 1.5rem
    .el-button
      width: 100%

  .invoices-summary
    display: flex
    flex-flow: wrap
    .owed
      display: flex
      flex-flow: column
      justify-content: flex-end
      flex: 0 0 33.3333%
      text-align: center
  // padding: 2rem 0

  .overview__daterange
    display: flex
    justify-content: center
    .el-date-editor
      width: 550px
      margin-right: 20px
</style>

