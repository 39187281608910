<style scoped lang="sass">
.load-more-btn
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  gap: .375rem
  color: #EB5B13
  border: 1px solid #EB5B13
  border-radius: .75rem
  height: 3rem
  cursor: pointer 
  font-size: .875rem
</style>
<template>
    <div class="load-more-btn" @click="$emit('loadMoreClick')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33398 6.66667C1.33398 6.66667 2.67064 4.84548 3.75654 3.75883C4.84244 2.67218 6.34305 2 8.00065 2C11.3144 2 14.0007 4.68629 14.0007 8C14.0007 11.3137 11.3144 14 8.00065 14C5.26526 14 2.95739 12.1695 2.23516 9.66667M1.33398 6.66667V2.66667M1.33398 6.66667H5.33398" stroke="#EB5B13" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Load More
    </div>
</template>
<script>
export default {
    
}
</script>
