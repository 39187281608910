<template lang='pug'>
div(
  v-loading='groups == null',
  style='min-height: 10rem'
)
  p(
    v-if='deliveries != null && deliveries.length == 0'
  ) No deliveries in this section at the moment
  div(
    v-for='group in groups', :key='group.name'
  )
    h5 {{group.name}}
    el-table(
      :data='group.deliveries'
    )
      el-table-column(type='expand')
        template(slot-scope='props')
          delivery(:delivery-summary='props.row')

      el-table-column(label='Pickup')
        template(slot-scope='scope')
          .vendor {{scope.row.vendor_name}}

      el-table-column(label='Delivery')
        template(slot-scope='scope')
          .customer {{scope.row.customer_account_name}}
          //- TODO - Contact name + phone

      el-table-column(label='Date')
        template(slot-scope='scope')
          div
            i.mat-icon.secondary date_range
            |  {{$formatDate(scope.row.date)}}
          div
            i.mat-icon.secondary access_time
            |  {{ $formatTime(scope.row.pickup_time) }}

      el-table-column(label='Type')
        template(slot-scope='scope')
          status-tag(:status='scope.row.type')

      el-table-column(label='Notes')
        template(slot-scope='scope')
          | {{scope.row.notes}}

      el-table-column(label='Actions')
        template(slot-scope='scope')
          ul.actions-list
            li
              el-button(type='text', @click='$openInBlank(`/print/orders/${scope.row.order_id}/po`)')
                i.mat-icon print
                | Purchase order
            li
              el-button(type='text', @click='$openInBlank(`/print/orders/${scope.row.order_id}/checklist`)')
                i.mat-icon playlist_add_check
                | Checklist
            li
              el-button(type='text', @click='editDelivery(scope.row)')
                i.mat-icon edit
                | Edit
  el-col()
    el-pagination( 
      v-if="pagination.pages > 1"
      @current-change="onPaginationChange"
      :page-size="pagination.per_page", 
      :pager-count="5", 
      layout="prev, pager, next", 
      :total="pagination.total") 
  edit-deliveries(v-if='showEditDelivery'
                  :delivery='currentDelivery'
                  @updateDeliveries='getData'
                  @closeModal="closeEditDeliveryModal")
</template>

<script>

  import StatusTag from '../../common/StatusTag.vue'
  import EditDeliveries from './EditDeliveries.vue'
  import Delivery from './Delivery.vue'

  import _ from 'underscore'
  import moment from 'moment'

  function data(){
    return {
      deliveries: null,
      displayScheduler: false,
      deliveryOnSchedule: null,
      orderOnSchedule: null,
      currentDelivery: {},
      showEditDelivery: false,
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null,
      }
    }
  }

  function onPaginationChange(current_page){
    this.getData(current_page);
  }

  function created(){
    this.getData()
  }

  function confirmDialog(id){
    const title = "Confirm delivery"
    const msg = "The customer will be deliveryd and charged. Continue?"
    this.$confirm(msg, title, {
      confirmButtonText: 'Confirm & bill delivery',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.confirm(id)
    }).catch(() => { })
  }

  function editDelivery(delivery) {
    this.currentDelivery = delivery;
    this.showEditDelivery = true;
  }

  function closeEditDeliveryModal() {
    this.showEditDelivery = false;
  }

  function confirm(id){
    const target = `/deliveries/${id}/confirm`
    this.$simplecater.post(target).then(r => {
      this.$message("The delivery has been confirmed")
      this.getData()
    }).catch(()=>{
      this.$message.error("Confirmation failed")
    })
  }

  function schedule(id){
    this.$message("PENDING WIRING")
  }

  function goToDelivery(id){
    this.$router.push(`/deliveries/${id}`)
  }

  function getData(current_page){
    let reqParams = (current_page ?{params:{ page:current_page}}:{params:{}});
    if(this.sorting){
      this.$isNumber(this.sorting)?reqParams.params.id=this.sorting:reqParams.params.q=this.sorting
    }
    this.$simplecater.get(this.targetUri,reqParams).then((r)=>{
      this.deliveries = r.data.data
      this.pagination = r.data.pagination;
    }).catch(()=>{
      this.$message.error('Failed to load data')
    })
  }

  const watch = {
    sorting: function(){
      this.getData();
    },
    filter: function(newFilter){
      console.log("FILTER FROM TO", this.filter, newFilter)
    }
  }

  const computed = {
    targetUri: function(){
      if(this.filter == 'all'){
        return '/deliveries'
      }else{
        return `/deliveries/${this.filter}`
      }
    },
    groups: function(){
      let grouped = _.chain(this.deliveries)
        .groupBy('date')
        .mapObject((items, date) =>{
          return {name: this.$formatDate(date) , deliveries: items, date: date }
        }).value()
      let ret = []
      _.each(grouped, ( value, key ) => {
        ret.push(value)
      })
      return _.sortBy(ret, 'date').reverse()
    }
  }

  const components = {
    StatusTag, EditDeliveries, Delivery
  }

  const methods = {
    getData, onPaginationChange, confirm, confirmDialog, schedule,
    goToDelivery, goToDelivery, editDelivery, closeEditDeliveryModal
  }

  const props = {
    sorting:{ required: false },
    filter: { required: true }
  }

  export default { methods, components, data, created, watch, props, computed }
</script>
