<style scoped lang="sass">

.numeric-summary
  display: flex
  flex-flow: row nowrap
  .label
    text-align: right
  .value
    text-align: left
    padding-left: 2rem
    font-size: .875rem

.po-title
  font-size: 16px
  font-weight: bold
  color: #dd711f

.printing-top-column
  padding: 0px
  width: 110%
  
</style>

<template lang='pug'>
div
  letter-head(v-if='fullInvoice && order', :no-padding='true')
    .printable-title.top
      .title Invoice \#{{ fullInvoice.id }}
        small Order <strong>\#{{ order.id }}</strong>
        .po-title(v-if="order !== null && order.customer_po") PO# {{ order.customer_po }}
        .po-title(v-else-if="request !== null && request.customer_po") PO# {{ request.customer_po }}
    //- big {{ $formatDate(fullInvoice.created_at) }}
  .printable-top-columns(v-if='fullInvoice && order && address && request')
    el-row(:gutter='$rowGutter')
      el-col(:span='8')
        .top-column(:class="isPrintingForm ? 'printing-top-column' : ''")
          .top-title FROM
          strong SimpleCater
          div P.O. Box 89
          div Hallandale, FL 33008
          a.link info@simplecater.com
          div Make checks payable to:
          div SIMPLECATER
        //- .title
          small Order <strong>\#{{ order.id }}</strong>
          small Event: <strong>{{ fullInvoice.request_name }}</strong>
          small
            | # of People: {{ request.people }}
      el-col(:span='8')
        .top-column(:class="isPrintingForm ? 'printing-top-column' : ''")
          .top-title TO
          strong {{ fullInvoice.customer_account_name }}
          div(v-if='contact')
            | {{ contact.salutation }} {{ contact.first_name }} {{ contact.last_name }}
            //- br
            //- | {{ contact.phone_number }}
          div(v-if='address')
            | {{ address.address_line_1 }}
          div(v-if='!request.suite')
            | {{ address.address_line_2 }}
          div(v-if='request !== null && request.suite')
            | {{ request.suite }}
          div {{ address.city }}, {{ address.state }} {{ address.zip_code }}
        //- .title
          small
            i.mat-icon date_range
            |  {{ $formatDate(order.delivery_date) }}
          small
            i.mat-icon access_time
            |  {{ $formatTime(order.delivery_time) }}
      el-col(:span='8')
        .top-column(:class="isPrintingForm ? 'printing-top-column' : ''")
          .top-title DELIVERY
          div
            strong {{ fullInvoice.request_name }}
          div &nbsp;
          div Del Date: {{ $formatDate(order.delivery_date) }}
          div Inv Date: {{ $formatDate(fullInvoice.created_at) }}
    br
    .products-from(v-if='order', style='font-size: 0.8rem')
      | Products from&nbsp;
      strong.cl-primary {{ order.vendor_name }}
  page-content(v-loading='fullInvoice == null')
    el-table(
      :data='itemsWithoutTaxTips'
    )
      el-table-column(prop='name', label='Name', :width="isPrintingForm ? 312 : mainWidth")
        template(slot-scope='scope')
          | {{ scope.row.name }}
          //- .secondary(v-if='scope.row.type == "PRODUCT"') {{ scope.row.product_description }}
      el-table-column(prop='quantity', label='Quantity', width='100')
        template(slot-scope='scope', v-if='scope.row.type != "TAX" && scope.row.type != "DELIVERY"')
          | {{scope.row.quantity}}
      el-table-column(prop='sale_price_cents', label='Unit price', width='120')
        template(slot-scope='scope', v-if='scope.row.type != "TAX" && scope.row.type != "DELIVERY"')
          | {{$formatCents(scope.row.sale_price_cents) }}
      el-table-column(prop='total_cents', label='Total Price', width='140')
        template(slot-scope='scope', v-if='scope.row.type != "TAX"')
          | {{$formatCents(scope.row.total_cents) }}
    el-row(:gutter='$rowGutter', style='margin-top: 1rem;')
      el-col(:span='isPrintingForm ? 10 : 6', v-if='totalCents && subtotalCents')
        .numeric-summary
          .label(style="text-align: left") Status:
          .value {{ invoceStatus }}
        .numeric-summary
          .label(style="text-align: left") Paid by:
          .value {{ paidBy }}
        .numeric-summary
          .label(style="text-align: left") Amount paid:
          .value {{ $formatCents(fullInvoice.total_cents - fullInvoice.balance_cents) }}
        .numeric-summary
          .label(style="text-align: left") Balance:
          .value {{ $formatCents(fullInvoice.balance_cents) }}
      el-col(:span='isPrintingForm ? 2 : 6', v-if='totalCents && subtotalCents')
        div &nbsp;
      el-col(:span='12', v-if='totalCents && subtotalCents')
        .numeric-summary
          .label Subtotal
          .value {{ $formatCents(subtotalCents) }}
        .numeric-summary
          .label Tax
          .value {{ $formatCents(tax.sale_price_cents) }}
        .numeric-summary(v-if='tips')
          .label Driver Tip
          .value {{ $formatCents(tips) }}
        .numeric-summary
          .label
            big Total
          .value
            big {{$formatCents(totalCents)}}
</template>

<script>
//payment_source
import mainWidth from '@/mixins/mainWidth'
import isPrintingForm from '@/mixins/mainWidth'
import LetterHead from './LetterHead.vue'
import PageContent from './PageContent.vue'
import _ from 'underscore'

function data() {
  return {
    fullInvoice: null,
    order: null,
    address: null,
    request: null,
    contact: null,
    cards: null
  }
}

function mounted() {
  this.getData()
}

function getData() {
  this.$simplecater.get(`/invoices/${this.id}`).then((r) => {
    this.fullInvoice = this.$mergeWithTips(r.data.data.tips, r.data.data)
    this.$simplecater.get(`/orders/${this.fullInvoice.order_id}`).then((r) => {
      this.order = r.data.data
      this.$simplecater.get(`/addresses/${this.order.request_address_id}`).then((r) => {
        this.address = r.data.data
      }).catch(() => {
        this.$message.error("Error loading the address information")
      })
      //const apiUrl = this.customerId ? `/customers/${this.customerId}/cards` : '/cards';
      // this.$simplecater.get(`/cards`).then((r)=> {
      //   this.cards = r.data.data
      // })
      // Request
      this.$simplecater.get(`/requests/${this.order.request_id}`).then((r) => {
        this.request = r.data.data
        // Contact
        this.$simplecater.get(`/contacts/${this.request.contact_id}`).then((r) => {
          this.contact = r.data.data
        }).catch(() => {
          this.$message.error("Error loading the contact information")
        })
      }).catch(() => {
        this.$message.error("Error loading the request information")
      })
    }).catch(() => {
      this.$message.error("Error loading the order information")
    })
  }).catch((e) => {
    this.$message.error(`Error loading the data. ${e.response.status}. ${e.response.data.error.reason}`)
  })
}

const mixins = [mainWidth, isPrintingForm]

const methods = { getData }

const components = { LetterHead, PageContent }

const props = {
  id: { required: true }
}

const computed = {
  invoceStatus() {
    const staus = this.fullInvoice.status;
    switch (staus) {
      case 'PAID':
        return 'Paid'
      case 'PENDING_PAYMENT':
        return 'Pending payment'
      case 'CANCELLED':
        return 'Cancelled'
      case 'REFUNDED':
        return 'Refunded'
    }
  },

  cardLast4NumbersLabel() {
    return this.fullInvoice.card_last4_numbers ? ` ending ${this.fullInvoice.card_last4_numbers}` : ''
  },

  paidBy() {
    const paidType = this.fullInvoice.payment_source.split('_')[0];

    switch (paidType) {
      case 'OFFLINE':
        return 'House account'
      case 'card':
        return 'Card' + this.cardLast4NumbersLabel
      default: ''
    }
  },

  totalCents() {
    return (this.fullInvoice) ? _.reduce(this.fullInvoice.items, (acc, i) => (acc + i.total_cents), 0) : 0
  },

  itemsWithoutTaxTips() {
    return (this.fullInvoice) ? _.filter(this.fullInvoice.items, (x) => (x.type != "TAX" && x.type != "TIP")) : []
  },

  tax() {
    return (this.fullInvoice) ? _.find(this.fullInvoice.items, (x) => (x.type === "TAX")) : null
  },

  tips() {
    return (this.fullInvoice.tips && this.fullInvoice.tips > 0) ? this.fullInvoice.tips : null
  },

  subtotalCents() {
    return _.reduce(this.fullInvoice.items, (memo, i) => {
      return (i.type != "TAX" && i.type != "TIP") ? memo + (i.sale_price_cents * i.quantity) : memo
    }, 0)
  },
}

export default { data, components, mixins, mounted, methods, props, computed }
</script>
