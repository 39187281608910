<style scoped lang="sass">

a
  font-weight: 600
.secondary
  margin: 0.2rem 0 0.5rem
  font-size: 20px
  color: #666
  .customer-name
    // 
  .small-details
    font-size: 0.9em
    margin-top: 0.7em
    line-height: 1.6em

.event-name
  font-weight: 500
  font-size: 1.25rem
  line-height: 100%
  letter-spacing: -0.03em
  color: #1F1F18

.event-id
  font-size: .875rem
  line-height: 1.25rem
  color: #70706B
  margin-top: .5rem
  margin-bottom: 1rem

.event-date-info
  display: flex
  flex-derection: row
  gap: .75rem
  .part
    display: flex
    align-items: center
    gap: .375rem

.card-content
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  padding-right: 1.25rem
  padding-bottom: 0.4rem
</style>

<template>
<el-card shadow="never">
  <div class="card-content">
  <div class="left-part">
  <router-link :to="'/user/requests/' + request.id" class="event-name">{{ request.name }}</router-link>
  <div class="event-id">
    Request #{{ request.id }}
  </div>
  <div class="event-date-info">
    <span class="part">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14 6.66659H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z" stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
       {{ $formatDate(request.delivery_date) }}
    </span>
    <span class="part">
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.3327 14C13.3327 13.0696 13.3327 12.6044 13.2179 12.2259C12.9593 11.3736 12.2924 10.7067 11.4401 10.4482C11.0616 10.3333 10.5964 10.3333 9.66601 10.3333H6.33269C5.40231 10.3333 4.93712 10.3333 4.55859 10.4482C3.70632 10.7067 3.03938 11.3736 2.78084 12.2259C2.66602 12.6044 2.66602 13.0696 2.66602 14M10.9993 5C10.9993 6.65685 9.6562 8 7.99935 8C6.34249 8 4.99935 6.65685 4.99935 5C4.99935 3.34315 6.34249 2 7.99935 2C9.6562 2 10.9993 3.34315 10.9993 5Z" stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      {{ request.people }}
    </span>  
  </div>
    </div>
      <div class="right-part">
    <status-tag :status="request.status"></status-tag>
  </div>
  </div>
  </div>
</el-card>
</template>

<script>
import moment from 'moment'
import StatusTag from '../../common/UserStatusTag.vue'

export default {
  props: {
    request: {
      required: true,
      type: Object,
    },
  },
  data ( ) {
    return {
      moment,
    }
  },
  components: {
    StatusTag,
  },
}
</script>