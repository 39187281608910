<template>
<router-link :class="{ 'submitted': order.status === 'SUBMITTED' }" :to="'/user/orders/' + order.id">
<div :class="{ 'borders': withBorders }" class="event-card">
  <span :class="{ 'hpad': !showDate }" class="order-id"> #{{ order.id }}</span>
  <!-- <router-link :class="{ 'submitted': order.status === 'SUBMITTED' }" :to="'/user/orders/' + order.id">
  {{ order.request_name }}
  <span>{{ order.status === 'SUBMITTED' ? ' (pending)' : '' }}</span></router-link> -->
  <div class="event-body">
    <div class="secondary vendor-info">{{ order.request_name }}<br/>
    <span v-show="showDate" class="event-date">{{ $formatDate(order.delivery_date) }}</span>
    <span :class="{ 'hpad': showDate, 'bold': withBorders }" class="event-date">{{ $formatTime(order.delivery_time) }}</span>
  </div>
  <div class="open-event">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.33398 10H16.6673M16.6673 10L11.6673 5M16.6673 10L11.6673 15" stroke="#125F43" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
  </div>
</div>
</router-link>
</template>

<script>
import moment from 'moment'

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
    showDate: {
      type: Boolean,
      required: false,
      default: false,
    },
    withBorders: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data ( ) {
    return {
      moment,
    }
  }
}
</script>

<style scoped lang="sass">
  .bold
    font-weight: 700
    font-size: 13px
  .borders
    border: 1px solid #EBEEF5
    border-radius: 4px
    padding: 5px
    .secondary
      margin-bottom: 0
    &:not(:last-of-type)
      margin-bottom: 5px
  a
    font-weight: 600
  .secondary
    margin: 0.2rem 0 0.5rem
    font-size: 0.8em
    color: #666

  .submitted
    color: #666
    font-style: italic

  .event-card
    background: #FAFAF5
    border-radius: 1rem
    display: flex
    padding: .5rem 1.5rem.5rem .75rem
    margin-top: .5rem
    font-family: 'Inter'
    font-style: normal
    align-items: center
    min-width: 17.5rem
    .order-id
      background: #F5F2D0
      border-radius: .5rem
      color: #CFA531
      font-weight: 500
      font-size: .875rem
      line-height: 1.25rem
      display: flex
      align-items: center
      justify-content: center
      padding: .375rem .25rem
      margin-right: 1rem
      max-height: 1.75rem
      min-width: fit-content
    .vendor-info
      color: #1F1F18
      font-weight: 500
      font-size: .875rem
      line-height: 1.25rem
    .event-date
      color: #6E6E68
    .event-body
      display: flex
      flex-direction: row
      align-items: center
      justify-content: space-between
      width: 100%

</style>