<style scoped lang="sass">
.to-upper
  text-transform: uppercase
</style>
<template lang='pug'>
div

  el-row(v-if='products != null', v-for='(prod, index) in products', :key='prod.id')
    el-checkbox(v-model='prod.checked', @change='productChanged(index)')
      | {{ prod.name }}
      el-tooltip(:content='prod.description' placement='right')
        | &nbsp;
        i.mat-icon.secondary info
      el-tag(
        type='info', size='mini', style='margin-left: 5px'
        ) Serves {{prod.serves}}
      //- - {{prod.id}} - {{prod.quantity}}

  el-row(v-if='others != null', v-for='fee,key in others', :key='key')
    el-checkbox(v-model='fee.checked', @change='feeChanged(key)')
      strong.cl-menu.to-upper(v-if="fee.name === 'Delivery'") {{ fee.name }}
      span(v-else) {{ fee.type == 'CUSTOM' ? "Custom fee" : fee.name }}
      //- - {{id}} - {{fee.quantity}}
</template>



<script>
import _ from 'underscore'
import Vue from 'vue'


function defaultOthers () {
  return {
    CUSTOM: {type: 'CUSTOM', quantity: 1, checked: false, sale_price_cents: 0, name: 'Custom fee' },
    DELIVERY: {type: 'DELIVERY', quantity: 1, checked: false, sale_price_cents: 0, name: 'Delivery' }
  }
}

function data(){
  return {
    products: null,
    others: null,
    oldVendorId: null,
  }
}

function created(){
  this.loadProducts(this.vendorId)
  this.eventBus.$on("resend_all_items", this.resendAllItems)
}

function feeChanged ( key ){
  if (this.others[key].checked) {
    // The item is now selected
    this.sendFee(key)
  }else{
    // The item is now unselected
    this.eventBus.$emit('remove_item', key)
  }
}

function sendFee(id){
  const fee = Object.assign({}, this.others[id])
  this.eventBus.$emit('add_item', id, fee)
}

function sendProduct(index){

  const product = Object.assign({}, this.products[index])
  const newItem = {
    name: product.name,
    sale_price_cents: product.sale_price_cents,
    quantity: product.quantity,
    type: "PRODUCT",
    product_id: product.id
  }
  this.eventBus.$emit('add_item', index, newItem)
}

function productChanged(index){
  if ( this.products[index].checked) {
    // The item is now selected
    this.sendProduct(index)
  }else{
    // The item is now unselected
    this.eventBus.$emit('remove_item', index)
  }
}

function selectApplicableItems(){
  const [productItems, otherItems] = _.chain(this.backendItems)
    .filter((i) => i.type != 'PRODUCT' || i.vendor_id == this.vendorId)
    .partition((i) => i.type == 'PRODUCT')
    .value()

  _.each(productItems, (i)=>{
    let target = null
    let ind = null
    _.each(this.products, (m,index)=>{
      if(m.id == i.product_id){ 
        target = m
        ind = index
        m.quantity = i.quantity
        m.checked = true
        this.productChanged(index)
      }
    })
  })
  _.each(otherItems, (i)=>{
    // Tax is not treated as a regular item
    if (i.type == 'TAX') return
    const target = this.others[i.type]
    console.log("TARGET", i.type, target)
    target.quantity = i.quantity
    target.sale_price_cents = i.sale_price_cents
    target.checked = true
    if(i.name) target.name = i.name
    this.feeChanged(i.type)
  })
}

function resendAllItems(){
  console.log("Resending all items")
  _.each( _.keys(this.products), (id, index) => this.sendProduct(id) )
  _.each( _.keys(this.others), (id) => this.sendFee(id) )
}

function loadProducts(newVendorId){
  return new Promise((resolve, reject)=>{
    const vendorProducts = `/vendors/${newVendorId}/products`
    this.$simplecater.get(vendorProducts).then((r)=>{
      if (this.vendorId != newVendorId) {
        // The vendor changed while fetching products
        resolve()
      }
      const indexedProducts = _.chain(r.data.data)
        .map( (x) => { return { ...x, checked: false, quantity: 0 } })
        .value()
      this.products = indexedProducts;

      this.others = defaultOthers()
      this.eventBus.$emit('reset_items')

      this.selectApplicableItems()
      // if ( this.isNewEstimate || (!!this.oldVendorId && newVendorId != this.oldVendorId) ) {
      //   // Forces delivery as checked on both:
      //   // a) new estimates
      //   // b) when vendor changes => This prevents settings delivery as checked for previously saved estimates that did NOT use it
      //   this.forceEnableDelivery()
      // }
      resolve()
    }).catch(reject)
  })
}

const computed = {
  empty: function(){
    return true
  }
}


const props = {
  vendorId: { required: true },
  eventBus: { required: true },
  backendItems: { required: true },
  backendVendor: { required: true },
  isNewEstimate: { required: true },
}

// function forceEnableDelivery ( ) {
//   let nOthers = _.extend({}, this.others, {
//     DELIVERY: _.extend({}, this.others.DELIVERY, {
//       checked: true
//     })
//   })
//   this.others = nOthers
//   this.feeChanged('DELIVERY')
// }

const watch = {
  vendorId: function(newVendorId, oldVendorId) {
    this.oldVendorId = oldVendorId
    this.loadProducts(newVendorId)
  },
}

const methods = {
  loadProducts, feeChanged, productChanged, selectApplicableItems,
  resendAllItems, sendFee, sendProduct, //forceEnableDelivery,
}

export default { data, methods, props, watch, created, computed }
</script>
