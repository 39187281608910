<style scoped lang="sass">
.user-settings-switcher
    display: flex
    flex-direction: column
    border-radius: .75rem
    background: white
    cursor: pointer
    .tab
      padding: 1rem
      width: 20rem
      border-bottom: 1px solid #EBEBE4
    .tab:last-child
      border-bottom: none
      border-radius: 0 0 .75rem .75rem
    .tab:first-child
      border-radius: .75rem .75rem 0 0
    .active-tab
      background: #E9F2EC
      color: #125F43    

</style>
<template>
    <div class="user-settings-switcher">
        <div class="tab" v-for="tab in tabs" :class="{['active-tab'] : tab === activeTab}" @click="setActiveTab(tab)">
            {{tab}}
        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            tabs: ['Address', 'Contact', 'Request Preferences', 'Change Password'],
            activeTab: 'Address',
        }
    },
    methods: {
        setActiveTab(tab) {
            this.activeTab = tab
            this.$emit('onActiveTabSet', tab)
        }
    }
}
</script>
