<style scoped lang="sass">
.edit-button
  display: flex
  gap: .375rem
  align-items: center
  justify-content: center
  font-size: .875rem
  cursor: pointer
  color: #125F43
  width: 6.875rem
  height: 1.25rem
</style>

<template>
    <div class="edit-button" @click="$emit('onClick')">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.66602 14.3334L5.36553 12.9105C5.60216 12.8195 5.72047 12.774 5.83116 12.7146C5.92948 12.6618 6.02322 12.6009 6.11138 12.5324C6.21063 12.4554 6.30027 12.3658 6.47954 12.1865L13.9994 4.66671C14.7357 3.93033 14.7357 2.73642 13.9994 2.00004C13.263 1.26366 12.0691 1.26366 11.3327 2.00004L3.81287 9.51985C3.6336 9.69912 3.54396 9.78876 3.46694 9.88801C3.39853 9.97617 3.33762 10.0699 3.28484 10.1682C3.22542 10.2789 3.17991 10.3972 3.0889 10.6339L1.66602 14.3334ZM1.66602 14.3334L3.0381 10.766C3.13628 10.5107 3.18537 10.3831 3.26958 10.3246C3.34316 10.2735 3.43422 10.2542 3.52221 10.271C3.6229 10.2902 3.7196 10.3869 3.913 10.5803L5.41906 12.0864C5.61246 12.2798 5.70916 12.3765 5.72839 12.4772C5.7452 12.5652 5.72587 12.6562 5.67478 12.7298C5.61631 12.814 5.48867 12.8631 5.2334 12.9613L1.66602 14.3334Z" stroke="#125F43" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        {{ text }}
    </div>
</template>

<script>

export default {
  name: "EditButton",
  props: {
    text: {
        type: String,
        default: 'Edit'
    }
  }
}
</script>