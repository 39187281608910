<template lang="pug">
drawer(:dismissRoute="'/staff/vendors/' + id")
  .drawer-title
    i.mat-icon add_circle
    | New Address

    address-builder(:displaySave='true', @saveClick='createAddress', :loading='loading', :has-notes='true')
</template>


<script>
  import Drawer from '../layout/Drawer.vue'
  import AddressBuilder from '../common/AddressBuilder.vue'

  function createAddress(address){
    const target = `/vendors/${this.id}/addresses`
    this.loading = true
    this.$simplecater.post(target, address).then((r)=>{
      this.$message("The address has been created")
      this.loading = false
      this.$bus.$emit('address-saved')
      this.$router.push('/staff/vendors/' + this.$route.params.id)
    }).catch(()=>{
      this.$message.error("Failed to create the address")
      this.loading = false
    })
  }

  function data ( ) {
    return {
      loading: false,
    }
  }

  function created ( ) {}

  const components = { Drawer, AddressBuilder }

  const methods = { createAddress }

  let props = {
    id: {}
  }

  export default { props, data, created, components, methods }
</script>
