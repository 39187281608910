<template>
  <div class="custome-checkbox">
    <div class="checkbox-form">
      <div class="answers">
        <label
          class="item"
        >
          <span>{{ option }}</span>
          <input
            v-if="!disable"
            :id="option"
            v-model="checked"
            type="checkbox"
            :value="option"
            @change="onChange"
          >
          <input
            v-else
            :id="option"
            v-model="disableChecked"
            type="checkbox"
            disabled="true"
          >
          <span class="checkmark" :class="squareShapes ? 'square-shapes' : ''" />
        </label>
      </div>
    </div>
    <div class="checkbox-title">
       {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      type: Object,
    },
    title: {
        typeof: String,
        default: ''
    },
    initialValue: {
        type: Boolean,
        default: false
    },
    disable: {
        type: Boolean,
        default: false
    },
    squareShapes: {
        type: Boolean,
        default: false,
        require: false
    }
  },
  data(){
    return {
      checked: false,
      disableChecked: true
    };
  },
  watch: {
    initialValue(val) { 
      this.checked = val
    }
  },

  mounted() {
    this.checked = this.initialValue
  },

  methods: {
    onChange() {
      this.$emit('input', this.checked);
    }
  }
};
</script>

<style scoped>
.custome-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkbox-form {
	display: flex;
	flex-direction: column;
	align-items: center;
    justify-content: center;
}

.checkbox-form .answers {
	display: flex;
	flex-direction: column;
	align-items: left;
	width: 100%;
}

.checkbox-form label {
	margin-left: 1em;
}

.checkbox-form .item {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 1em;
	height: 1.5625rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	align-items: center;
    margin: auto;
}

.checkbox-form .item input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkbox-form .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 25px;
	width: 25px;
    border-radius: 1.125rem;
	background-color: #F4F9F5;
}

.checkbox-form .square-shapes {
  border-radius: .375rem;
  border: 1px solid #EBEBE4;
}

.checkbox-form .item:hover input ~ .checkmark {
	background-color: #F4F9F5;
}

.checkbox-form .item input:checked ~ .checkmark {
	background-color: #F4F9F5;
}

.checkbox-form .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox-form .item input:checked ~ .checkmark:after {
	display: block;
}

.checkbox-form .item .checkmark:after {
	left: 9px;
	top: 5px;
	width: 5px;
	height: 10px;
	border: solid #125F43;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.checkbox-title {
    font-weight: 500;
    font-size: .875rem;
    color: #1F1F18
}


</style>