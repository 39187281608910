<template lang='pug'>
.main-container  
  .view-content
    el-card
      div(slot='header')
        i.mat-icon assignment
        | Customer Requests
        el-button(type="primary", @click="openModal", style='float: right')
          i.mat-icon add_circle
          | New request

      el-tabs( v-model='activeIndex', type='card' )
        el-row(:gutter='10')
          el-col(:span='9')
            el-input(v-model='sorting' prefix-icon="el-icon-search" placeholder="Search")
        el-tab-pane(v-for='filter in filters', :label='$upcaseFirst(filter)',
          :name='filter', :key='filter')
          request-list(:filter='filter', :sorting='sorting', v-if='filter == activeIndex')

  router-view
</template>

<script>

import RequestList from './blocks/RequestList';

function data(){
  return {
    filters: ['urgent', 'open', 'closed', 'all'],
    sorting:'',
    activeIndex: 'urgent'
  }
}

function openModal ( ) {
  this.$router.push('/staff/requests/new')
}

const components = { RequestList }

const methods = { openModal };

export default { methods, components, data }

</script>
