<template>
<div class="main-container" v-loading='!request'>
    <div class="view-content">
      <div class="request-header">
        <h2 class="page-title">{{request.name}}</h2>
        <div class="request-header-req-info">
            <div class="req-number">Request #{{request ? request.id : ''}}</div>
            <status-tag v-if="request" :status="request.status"></status-tag>
        </div>
      </div>
          
        <!-- <el-card class="el-card__with-bg" v-if="request">
            <div slot="header"><i class="mat-icon">assignment</i>
                <router-link to="/user/requests">Requests</router-link> &gt;<div class="request-name">{{ request.name }}</div>
                <status-tag :status="request.status"></status-tag>
                <div class="secondary"><i class="mat-icon">date_range</i> {{ $formatDate(request.delivery_date) }}
                    <div class="v-divider"></div><i class="mat-icon">access_time</i> {{ $formatTime(request.delivery_time) }}
                    <div class="v-divider"></div><i class="mat-icon">person</i>{{ request.people }}
                    <div class="v-divider"></div><span>{{$formatCents(request.budget_cents)}}/person</span>
                    <div class="v-divider"></div><em> Request #{{ request.id }}</em>
                </div>
            </div>
        </el-card> -->
        <request v-if="request" :request="request"></request>
        <router-view></router-view>
    </div>
</div>
</template>

<script>
import Request from './blocks/UserRequest.vue'
import StatusTag from '../common/UserStatusTag.vue'

function data ( )  {
  return {
    request: null,
    loading: true,
  }
}

function fetchData ( ) {
  if(this.$route.matched[1].path !== '/user/requests') {
   this.$simplecater.get('/requests/' + this.$route.params.id).then((r)=>{    
      let request = r.data.data
      request.estimates = _.sortBy(request.estimates, 'id');
      this.request = request
    }).catch(()=>{
      this.$message.error("Failed to load the request")
    })
  } 
}

function created ( ) {
  this.fetchData()
  this.$bus.$on('request-created', this.fetchData)
  this.$bus.$on('estimate-accepted', this.fetchData)
}

const methods = {
  fetchData
}

const components = { Request, StatusTag }

// TODO - Re-fetch on route change/update
export default { data, methods, created, components }
</script>

<style scoped lang="sass">
  .page-title
    margin-bottom: 1.75rem  
  .request-name
    display: inline-block
    font-weight: 600
    margin: 0 0.5rem
  .secondary
    // display: inline-block
    color: #888
    font-size: 0.8em
    margin-left: 1.7rem
    .v-divider
      display: inline-block
      width: 1px
      background: #ccc
      height: 1.5em
      margin: 0 0.5rem
      vertical-align: middle
  .request-header
    display: flex
    justify-content: space-between
    align-items: flex-start
    &-req-info
      max-width: fit-content
      margin-top: 26px
    .req-number
      font-size: .875rem
      color: #A2A29D
      margin-left: 7px
      margin-bottom: 8px

</style>
