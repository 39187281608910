<template lang='pug'>
.main-container

  .view-content
    el-card
      div(slot='header')
        i.mat-icon motorcycle
        | Deliveries
      el-tabs( v-model='activeIndex', type='card' )
        el-row(:gutter='10')
          el-col(:span='9')
            el-input(v-model='sorting' prefix-icon="el-icon-search" placeholder="Search")
        el-tab-pane(
          v-for='filter in filters' :label='$upcaseFirst(filter)',
          :name='filter', :key='filter'
        )
          delivery-list(:filter='filter',:sorting='sorting', v-if='filter == activeIndex')
</template>

<script>

  import DeliveryList from './blocks/DeliveryList.vue'

  function data(){
    return {
      filters: ['open','closed', 'all'],
      sorting:'',
      deliveries: null,
      activeIndex: 'open',
      loadingDeliveries: true,
      loadingContacts: true
    }
  }

  function getDeliveries(){
    this.loadingDeliveries = true
    return this.$simplecater.get('/deliveries').then((r)=> {
      this.deliveries = r.data.data
      this.loadingDeliveries = false
    })
  }

  // function goToAddressBuilder(){
  //   this.$message("Pending wiring")
  //   //this.$router.push("/user/deliveries/new")
  // }
  //
  //
  // function goToContactBuilder(){
  //   this.$message("Pending wiring")
  //   //this.$router.push("/user/deliveries/new")
  // }

  function created(){
    this.getDeliveries()
  }

  const watch = {
    '$route' (to, from) {
      console.log("Reloading data")
      this.getDeliveries()
    }
  }

  const components = { DeliveryList }

  const methods = {
    getDeliveries
  }

  export default { methods, components, data, created }

</script>
