<style scoped lang="sass">
  .sub-header
    display: flex
    flex-direction: row
    justify-content: space-between
  .search
    width: 12.5rem
  .sub-header-left-part
    display: flex
    gap: .75rem  
  .search
    max-width: 12.5rem
  .biling-header
    display: flex
    justify-content: space-between
  .page-title
    margin-bottom: 1.75rem
  .main-content
    margin-top: 1.5rem  
</style>
<template>
<div class="main-container">
    <div class="view-content">
      <div class="biling-header">
      <h2 class="page-title">Billing</h2>
      <default-button @onClick="addNewCard" :style="{marginTop: '1.2rem', fontSize: '.875rem'}">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.00065 3.33325V12.6666M3.33398 7.99992H12.6673" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        Add Сard
      </default-button>
      </div>
      <div class="sub-header">
      <div class="sub-header-left-part">
        <toggle-switch
          :options="toggleOption"
          :disabled="false"
          @change="switchTab($event.value)"
        />
      </div>
      <div class="search">
        <el-input v-show="activeIndex === 'invoices'" v-model="sorting" prefix-icon="el-icon-search" placeholder="Search"></el-input>
      </div>
    </div>
    <div class="main-content">
      <div v-if="activeIndex === 'invoices'" v-loading="invoices == null">
      <user-invoce-card v-for="data in invoices" :data="data" @viewInvoceClick="$openInBlank(`/print/invoices/${$event}`)"/>
      </div>
      <div v-else-if="activeIndex === 'payment_methods'">
        <user-card-view v-for="(data, index) in cards" :data="data" @deleteClick="deleteCard(data)"/>
      </div>
    </div>
        <!-- <el-card>
            <div slot="header"><i class="mat-icon">credit_card</i>Payment methods<el-button type="primary" @click="addNewCard" style="float: right"><i class="mat-icon">add_circle</i>Add card</el-button>
            </div>
            <el-table :data="cards" v-loading="cards == null">
                <el-table-column prop="name" label="Card"><template slot-scope="scope">{{scope.row.name}}</template></el-table-column>
                <el-table-column prop="expiration" label="Expiration"><template slot-scope="scope">{{scope.row.expiration}}</template></el-table-column>
                <el-table-column label="Actions"><template slot-scope="scope" v-if="scope.row.id != &quot;OFFLINE&quot;">
                        <el-button type="text" size="small" @click="deleteCardDialog(scope.row, scope.$index)">Delete</el-button>
                    </template></el-table-column>
            </el-table>
        </el-card>
        <el-card>
            <div slot="header"><i class="mat-icon">receipt</i>Invoices</div>
            <el-row :gutter="10">
                <el-col :span="9">
                    <el-input v-model="sorting" prefix-icon="el-icon-search" placeholder="Search"></el-input>
                </el-col>
            </el-row>
            <el-table :data="invoices" v-loading="invoices == null">
                <el-table-column prop="date" label="Date"><template slot-scope="scope">{{ $formatDate(scope.row.delivery_date) }}</template></el-table-column>
                <el-table-column prop="id" label="Invoice#" width="90"></el-table-column>
                <el-table-column prop="people" label="People" width="90"></el-table-column>
                <el-table-column prop="vendor_name" label="Vendor"></el-table-column>
                <el-table-column prop="total" label="Total"><template slot-scope="scope">{{ $formatCents(scope.row.total_cents) }}&nbsp;&nbsp;<el-tag>{{ formatStatus(scope.row.status) }}</el-tag></template></el-table-column>
                <el-table-column prop="total" label="Balance" width="90"><template slot-scope="scope">{{ $formatCents(scope.row.balance_cents) }}&nbsp;&nbsp;</template></el-table-column>
                <el-table-column prop="total" label="Actions"><template slot-scope="scope">
                        <el-button type="text" @click="$openInBlank(`/print/invoices/${scope.row.id}`)">View invoice &nbsp;<span class="mat-icon">open_in_new</span></el-button>
                    </template></el-table-column>
            </el-table>
            <el-col>
                <el-pagination v-if="pagination.pages &gt; 1" @current-change="onPaginationChange" :page-size="pagination.per_page" :pager-count="pagination.pages" layout="prev, pager, next" :total="pagination.total"> </el-pagination>
            </el-col>
        </el-card> -->
    </div>
</div>
</template>

<script>
import DefaultButton from '@/layout/Blocks/DefaultButton.vue';
import Toolbar from '../layout/Toolbar.vue'
import UserInvoceCard from './blocks/UserInvoceCard.vue';
import UserCardView from './blocks/UserCardView.vue';

    function data(){
      return {
        invoices: null,
        sorting: '',
        cards: null,
        loadingCards: true,
        loadingInvoices: true,
        activeIndex: 'invoices',
        pagination: {
          per_page: null,
          current: null,
          pages: null,
          total: null,
        },
        toggleOption: {
        layout: {
        color: 'black',
        backgroundColor: 'white',
        selectedColor: 'white',
        selectedBackgroundColor: 'green',
        borderColor: 'black',
        fontFamily: 'Inter',
        fontWeight: 'normal',
        fontWeightSelected: 'bold',
        squareCorners: false,
        noBorder: true
        },
        size: {
          fontSize: .875,
          height: 2.125,
          padding: .4375,
          width: 19.25
        },
      config: {
        delay: 0,
        preSelected: 'invoices',
        disabled: false,
        items: [
            { name: 'Invoices', value: 'invoices', color: '#125F43', backgroundColor: '#E9F2EC' },
            { name: 'Payment methods', value: 'payment_methods', color: '#125F43', backgroundColor: '#E9F2EC' }
        ]
      }
    }
      }
    }

    function onPaginationChange(current_page){
      this.getInvoices(current_page);
    }

    function getCards(){
      this.loadingCards = true
      return this.$simplecater.get('/cards').then((r)=> {
        this.cards = r.data.data
        this.loadingCards = false
      })
    }

    function getInvoices(current_page){
      this.loadingInvoices = true
      let reqParams = (current_page ?{params:{ page:current_page}}:{params:{}});
      if(this.sorting){
        this.$isNumber(this.sorting)?reqParams.params.id=this.sorting:reqParams.params.q=this.sorting
      }
      return this.$simplecater.get('/invoices',reqParams).then((r)=> {
        this.invoices = _.sortBy(r.data.data, 'created_at').reverse()
        // created_at
        this.loadingInvoices = false
        this.pagination = r.data.pagination;
      })
    }

    function addNewCard(){
      this.$checkout.open({
        name: 'Use your credit card',
        currency: 'USD',
        panelLabel: 'Save card',
        // amount: 9,
        billingAddress: false,
        allowRememberMe: false,
        token: (token) => {
          let data = {
            token: token.id
          }
          this.$simplecater.post('/cards', data).then((r)=> {
            this.getCards()
          })
        }
      })
    }

    function created(){
      this.getCards()
      this.getInvoices()
    }

    function formatUnixTimestamp(timestamp){
      const date = new Date(timestamp * 1000)
      return date.toLocaleDateString()
    }

    function deleteCardDialog ( card, idx ) {      
      console.log('Delete', card, idx)
      const title = "Delete Card"
      const msg = "Are you sure you want to delete this card?"
      this.$confirm(msg, title, {
        confirmButtonText: 'Yes, delete it',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.deleteCard(card)
      }).catch(() => { })
    }

    function deleteCard ( card, idx ) {
      this.$simplecater.delete('/cards/' + card.id).then( ( r )=> {
        let newCards = _.map(this.cards, _.id)
        newCards.splice(idx, 1)
        this.cards = newCards
        this.$message("The card was deleted")
        this.getCards()
      }).catch( ( err ) => {
        this.$message.error('This card cannot be deleted')
      })
    }

    function switchTab (e) {
      this.activeIndex = e
    }

    function formatStatus(str) {
      if (str === 'PENDING_PAYMENT') return 'pending';
      return str.replace(/_/g,' ').toLowerCase();
    }

    const watch = {
      sorting: function(){
        this.getInvoices();
      },
      '$route' (to, from) {
        console.log("Reloading data")
        this.getCards()
        this.getInvoices()
      }
    }

    const components = { Toolbar, DefaultButton, UserInvoceCard, UserCardView }

    function mounted () {
      this.$route.query.fromRequest ? this.addNewCard() : null
    }

    const methods = {
      getCards, onPaginationChange, switchTab, getInvoices, addNewCard, formatUnixTimestamp, deleteCardDialog, deleteCard, formatStatus
    }

    export default { methods, components, data, created,watch, mounted }

</script>
