const states = [{ name: "Florida", abbr: "FL" }, { name: "Texas", abbr: "TX" }, { name: "Georgia", abbr: "GA" }]

const zipCodes = [
  "30004", "30005", "30009", "30022", "30023", "30024", "30032", "30075", "30076", "30097", "30301", "30302", "30303", "30304", "30305", "30306", "30307", "30308",
  "30309", "30310", "30311", "30312", "30313", "30314", "30315", "30316", "30317", "30318", "30319", "30320", "30321", "30324", "30325", "30326", "30327", "30328",
  "30329", "30330", "30331", "30332", "30333", "30334", "30336", "30337", "30338", "30339", "30340", "30341", "30342", "30343", "30344", "30345", "30346", "30347",
  "30348", "30349", "30350", "30353", "30354", "30355", "30356", "30357", "30358", "30359", "30360", "30361", "30362", "30363", "30364", "30366", "30368", "30369",
  "30370", "30371", "30374", "30375", "30376", "30377", "30378", "30379", "30380", "30384", "30385", "30386", "30387", "30388", "30389", "30390", "30392", "30394",
  "30396", "30398", "30399", "31106", "31107", "31119", "31120", "31126", "31131", "31136", "31139", "31141", "31145", "31146", "31150", "31156", "31191", "31192",
  "31193", "31195", "31196", "31197", "31198", "31199", "33004", "33009", "33010", "33012", "33013", "33014", "33015", "33016", "33018", "33019", "33020", "33021",
  "33023", "33024", "33025", "33026", "33027", "33028", "33029", "33054", "33055", "33056", "33060", "33062", "33068", "33069", "33109", "33122", "33125", "33126",
  "33127", "33129", "33130", "33131", "33132", "33133", "33134", "33135", "33136", "33137", "33138", "33139", "33140", "33141", "33142", "33143", "33144", "33145",
  "33146", "33147", "33149", "33150", "33154", "33155", "33156", "33158", "33160", "33161", "33162", "33165", "33166", "33167", "33168", "33169", "33172", "33173",
  "33174", "33176", "33178", "33179", "33180", "33181", "33301", "33304", "33305", "33306", "33308", "33309", "33311", "33312", "33313", "33314", "33315", "33316",
  "33317", "33319", "33322", "33323", "33324", "33325", "33326", "33328", "33330", "33331", "33334", "33351", "33401", "33403", "33404", "33405", "33406", "33407",
  "33408", "33409", "33410", "33411", "33412", "33413", "33414", "33415", "33417", "33418", "33426", "33428", "33430", "33431", "33432", "33433", "33434", "33435",
  "33436", "33438", "33440", "33444", "33445", "33449", "33458", "33460", "33461", "33462", "33463", "33467", "33469", "33470", "33476", "33477", "33478", "33480",
  "33483", "33484", "33486", "33487", "33493", "33496", "33503", "33508", "33509", "33510", "33511", "33527", "33530", "33534", "33547", "33548", "33549", "33550",
  "33556", "33558", "33559", "33563", "33564", "33565", "33566", "33567", "33568", "33569", "33570", "33571", "33572", "33573", "33575", "33578", "33579", "33583",
  "33584", "33586", "33587", "33592", "33594", "33595", "33596", "33598", "33601", "33602", "33603", "33604", "33605", "33606", "33607", "33608", "33609", "33610",
  "33611", "33612", "33613", "33614", "33615", "33616", "33617", "33618", "33619", "33620", "33621", "33622", "33623", "33624", "33625", "33626", "33629", "33630",
  "33631", "33633", "33634", "33635", "33637", "33646", "33647", "33650", "33655", "33660", "33664", "33672", "33673", "33674", "33675", "33677", "33679", "33680",
  "33681", "33682", "33684", "33685", "33686", "33687", "33688", "33689", "33694", "33701", "33702", "33703", "33704", "33705", "33706", "33707", "33708", "33709",
  "33710", "33711", "33712", "33713", "33714", "33715", "33716", "33729", "33730", "33731", "33732", "33733", "33734", "33736", "33738", "33740", "33741", "33742",
  "33743", "33744", "33747", "33755", "33756", "33757", "33758", "33759", "33760", "33761", "33762", "33763", "33764", "33765", "33766", "33767", "33769", "33770",
  "33771", "33772", "33773", "33774", "33775", "33776", "33777", "33778", "33779", "33780", "33781", "33782", "33784", "33785", "33786", "34660", "34677", "34681",
  "34682", "34683", "34684", "34685", "34688", "34689", "34695", "34697", "34698", "39901", "73301", "73344", "75001", "75006", "75007", "75011", "75014", "75015",
  "75016", "75017", "75019", "75030", "75037", "75038", "75039", "75040", "75041", "75042", "75043", "75044", "75045", "75046", "75047", "75048", "75049", "75050",
  "75051", "75052", "75053", "75054", "75059", "75060", "75061", "75062", "75063", "75080", "75081", "75082", "75083", "75085", "75088", "75089", "75099", "75104",
  "75106", "75115", "75116", "75123", "75125", "75134", "75137", "75138", "75141", "75146", "75149", "75150", "75154", "75159", "75172", "75180", "75181", "75182",
  "75185", "75187", "75201", "75202", "75203", "75204", "75205", "75206", "75207", "75208", "75209", "75210", "75211", "75212", "75214", "75215", "75216", "75217",
  "75218", "75219", "75220", "75221", "75222", "75223", "75224", "75225", "75226", "75227", "75228", "75229", "75230", "75231", "75232", "75233", "75234", "75235",
  "75236", "75237", "75238", "75239", "75240", "75241", "75242", "75243", "75244", "75245", "75246", "75247", "75248", "75249", "75250", "75251", "75252", "75253",
  "75254", "75258", "75260", "75261", "75262", "75263", "75264", "75265", "75266", "75267", "75270", "75275", "75277", "75283", "75284", "75285", "75286", "75295",
  "75303", "75310", "75312", "75313", "75315", "75320", "75323", "75326", "75334", "75336", "75339", "75340", "75342", "75343", "75344", "75346", "75353", "75354",
  "75355", "75356", "75357", "75358", "75359", "75360", "75363", "75364", "75367", "75369", "75371", "75372", "75373", "75374", "75376", "75378", "75380", "75381",
  "75382", "75386", "75387", "75388", "75389", "75390", "75391", "75392", "75393", "75394", "75395", "75396", "75397", "75398", "77001", "77002", "77003", "77004",
  "77005", "77006", "77007", "77008", "77009", "77010", "77011", "77012", "77013", "77014", "77015", "77016", "77017", "77018", "77019", "77020", "77021", "77022", 
  "77023", "77024", "77025", "77026", "77027", "77028", "77029", "77030", "77031", "77032", "77033", "77034", "77035", "77036", "77037", "77038", "77039", "77040",
  "77041", "77042", "77043", "77044", "77045", "77046", "77047", "77048", "77049", "77050", "77051", "77052", "77053", "77054", "77055", "77056", "77057", "77058",
  "77059", "77060", "77061", "77062", "77063", "77064", "77065", "77066", "77067", "77068", "77069", "77070", "77071", "77072", "77073", "77074", "77075", "77076",
  "77077", "77078", "77079", "77080", "77081", "77082", "77083", "77084", "77085", "77086", "77087", "77088", "77089", "77090", "77091", "77092", "77093", "77094",
  "77095", "77096", "77098", "77099", "77201", "77202", "77203", "77204", "77205", "77206", "77207", "77208", "77209", "77210", "77212", "77213", "77215", "77216",
  "77217", "77218", "77219", "77220", "77221", "77222", "77223", "77224", "77225", "77226", "77227", "77228", "77229", "77230", "77231", "77233", "77234", "77235",
  "77236", "77237", "77238", "77240", "77241", "77242", "77243", "77244", "77245", "77248", "77249", "77251", "77252", "77253", "77254", "77255", "77256", "77257",
  "77258", "77259", "77261", "77263", "77265", "77266", "77267", "77268", "77269", "77270", "77271", "77272", "77273", "77274", "77275", "77277", "77279", "77280",
  "77282", "77284", "77287", "77288", "77289", "77290", "77291", "77292", "77293", "77297", "77299", "78681", "78701", "78702", "78703", "78704", "78705", "78708",
  "78709", "78710", "78711", "78712", "78713", "78714", "78715", "78716", "78717", "78718", "78719", "78720", "78721", "78722", "78723", "78724", "78725", "78726",
  "78727", "78728", "78729", "78730", "78731", "78732", "78733", "78734", "78735", "78736", "78737", "78738", "78739", "78741", "78742", "78744", "78745", "78746",
  "78747", "78748", "78749", "78750", "78751", "78752", "78753", "78754", "78755", "78756", "78757", "78758", "78759", "78760", "78761", "78762", "78763", "78764",
  "78765", "78766", "78767", "78768", "78772", "78773", "78774", "78778", "78779", "78783", "78799"
] 
export default { states, zipCodes }
