<template>
  <div v-if="!skip" style="padding-top: 20px;">
    <div class="service-fee">
      <div class="info-block">
        <svg
          style="margin-right: 12px;"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_4789_3714)">
            <path
              d="M9.99984 13.3333V10M9.99984 6.66667H10.0082M18.3332 10C18.3332 14.6024 14.6022 18.3333 9.99984 18.3333C5.39746 18.3333 1.6665 14.6024 1.6665 10C1.6665 5.39763 5.39746 1.66667 9.99984 1.66667C14.6022 1.66667 18.3332 5.39763 18.3332 10Z"
              stroke="#D92D20"
              stroke-width="1.66667"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_4789_3714">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div>
          Please note that this vendor charges a mandatory {{ percent }}% service fee, which is included in the item price.
        </div>
      </div>
      <svg
        @click="skip = true"
        style="cursor: pointer;"
        width="14"
        height="14"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11 1L1 11M1 1L11 11"
          stroke="#A2A29D"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.service-fee
  width: 100%
  background: white
  padding: 18px 24px
  display: flex
  align-items: center
  justify-content: space-between
  border-radius: 10px
  border: 1px solid #D92D20

.info-block
    display: flex
    align-items: center
</style>

<script>
export default {
  data() {
    return {
        skip: false
    }
  },
  props: {
    percent: {
      type: Number,
      require: true,
      default: 0
    }
  }
};
</script>
