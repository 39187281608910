<template>
  <div ref="table">
    <div v-if="estimate != null">
      <el-row :gutter="$rowGutter">
        <el-col :span="12">
          <div class="products-from estimate-title no-margin-bottom">
            <div class="tab-number request-title-text">{{ index + 1 }}</div>
            {{ estimate.vendor_name }}
          </div>
          <div class="price-info">
            <!-- <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M4.5 8.25V11.25M13.5 6.75V9.75M12.75 3C14.5865 3 15.5799 3.28107 16.0741 3.49908C16.1399 3.52812 16.1728 3.54263 16.2678 3.63328C16.3247 3.68761 16.4287 3.84705 16.4554 3.92107C16.5 4.04455 16.5 4.11205 16.5 4.24706V12.3084C16.5 12.9899 16.5 13.3307 16.3978 13.5059C16.2938 13.6841 16.1936 13.7669 15.999 13.8354C15.8076 13.9027 15.4215 13.8285 14.6491 13.6801C14.1085 13.5762 13.4674 13.5 12.75 13.5C10.5 13.5 8.25 15 5.25 15C3.41347 15 2.42015 14.7189 1.92591 14.5009C1.86009 14.4719 1.82718 14.4574 1.7322 14.3667C1.67526 14.3124 1.57134 14.153 1.5446 14.0789C1.5 13.9554 1.5 13.8879 1.5 13.7529L1.5 5.69164C1.5 5.01006 1.5 4.66928 1.60221 4.49411C1.70618 4.31592 1.80644 4.23309 2.00104 4.16461C2.19235 4.09729 2.57853 4.17149 3.35087 4.31989C3.89146 4.42376 4.53261 4.5 5.25 4.5C7.5 4.5 9.75 3 12.75 3ZM10.875 9C10.875 10.0355 10.0355 10.875 9 10.875C7.96447 10.875 7.125 10.0355 7.125 9C7.125 7.96447 7.96447 7.125 9 7.125C10.0355 7.125 10.875 7.96447 10.875 9Z" stroke="#2BB673" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->
            {{ $formatCents(pricePerPerson) }}/price per person -
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3334 14C13.3334 13.0696 13.3334 12.6044 13.2186 12.2259C12.9601 11.3736 12.2931 10.7067 11.4408 10.4482C11.0623 10.3333 10.5971 10.3333 9.66675 10.3333H6.33342C5.40304 10.3333 4.93785 10.3333 4.55932 10.4482C3.70705 10.7067 3.04011 11.3736 2.78157 12.2259C2.66675 12.6044 2.66675 13.0696 2.66675 14M11.0001 5C11.0001 6.65685 9.65694 8 8.00008 8C6.34323 8 5.00008 6.65685 5.00008 5C5.00008 3.34315 6.34323 2 8.00008 2C9.65694 2 11.0001 3.34315 11.0001 5Z"
                stroke="#123F43"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ people }} people
          </div>
          <div class="tax-info gray">
            Excludes Tax & Delivery
          </div>
        </el-col>
        <el-col class="print-version" :span="12">
          <el-button
            v-if="remoteId"
            type="text"
            @click="$openInBlank(`/print/estimate/${remoteId}`)"
          >
            <div class="print-btn">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.6665 5.2C2.6665 4.0799 2.6665 3.51984 2.88449 3.09202C3.07624 2.71569 3.3822 2.40973 3.75852 2.21799C4.18635 2 4.7464 2 5.8665 2H10.1332C11.2533 2 11.8133 2 12.2412 2.21799C12.6175 2.40973 12.9234 2.71569 13.1152 3.09202C13.3332 3.51984 13.3332 4.0799 13.3332 5.2V14L11.4998 12.6667L9.83317 14L7.99984 12.6667L6.1665 14L4.49984 12.6667L2.6665 14V5.2Z"
                  stroke="#125F43"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              Printable version
            </div> </el-button
          ><br />

          <el-button type="text" @click="getItemsReports" :loading="submitting">
            <div>Download CSV</div>
          </el-button>
        </el-col>
      </el-row>
      <div class="user-custome-estimates-table">
        <table class="user-custome-table">
          <thead>
            <tr>
              <th class="left-text">Item</th>
              <th>Qty</th>
              <th>Total Serves</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="user-custome-table-row"
              v-for="(item, i) in itemsWithoutTax"
              :key="i"
            >
              <td class="user-custome-table-item left-text">
                <div class="pos-name">{{ item.name }}</div>
                <div class="secondary" v-if="item.type == 'PRODUCT'">
                  {{ item.description }}
                </div>
                <div class="secondary serves-count" v-if="item.serves">
                  Serves: {{ item.serves }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3332 14C13.3332 13.0696 13.3332 12.6044 13.2183 12.2259C12.9598 11.3736 12.2929 10.7067 11.4406 10.4482C11.0621 10.3333 10.5969 10.3333 9.6665 10.3333H6.33317C5.4028 10.3333 4.93761 10.3333 4.55908 10.4482C3.70681 10.7067 3.03986 11.3736 2.78133 12.2259C2.6665 12.6044 2.6665 13.0696 2.6665 14M10.9998 5C10.9998 6.65685 9.65669 8 7.99984 8C6.34298 8 4.99984 6.65685 4.99984 5C4.99984 3.34315 6.34298 2 7.99984 2C9.65669 2 10.9998 3.34315 10.9998 5Z" stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->
                  {{ item.quantity }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3332 14C13.3332 13.0696 13.3332 12.6044 13.2183 12.2259C12.9598 11.3736 12.2929 10.7067 11.4406 10.4482C11.0621 10.3333 10.5969 10.3333 9.6665 10.3333H6.33317C5.4028 10.3333 4.93761 10.3333 4.55908 10.4482C3.70681 10.7067 3.03986 11.3736 2.78133 12.2259C2.6665 12.6044 2.6665 13.0696 2.6665 14M10.9998 5C10.9998 6.65685 9.65669 8 7.99984 8C6.34298 8 4.99984 6.65685 4.99984 5C4.99984 3.34315 6.34298 2 7.99984 2C9.65669 2 10.9998 3.34315 10.9998 5Z" stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg> -->
                  {{ item.serves * item.quantity }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00016 7.33335V10M12.0002 6.00002V8.66669M11.3335 2.66669C12.966 2.66669 13.8489 2.91653 14.2882 3.11032C14.3467 3.13612 14.376 3.14903 14.4604 3.2296C14.511 3.2779 14.6034 3.41962 14.6272 3.48541C14.6668 3.59518 14.6668 3.65518 14.6668 3.77518V10.9408C14.6668 11.5466 14.6668 11.8496 14.576 12.0053C14.4836 12.1636 14.3944 12.2373 14.2215 12.2981C14.0514 12.358 13.7081 12.292 13.0216 12.1601C12.5411 12.0678 11.9712 12 11.3335 12C9.3335 12 7.3335 13.3334 4.66683 13.3334C3.03436 13.3334 2.15141 13.0835 1.71208 12.8897C1.65358 12.8639 1.62433 12.851 1.5399 12.7704C1.48929 12.7221 1.39691 12.5804 1.37314 12.5146C1.3335 12.4049 1.3335 12.3449 1.3335 12.2249L1.3335 5.05926C1.3335 4.45341 1.3335 4.15049 1.42435 3.99478C1.51676 3.8364 1.60589 3.76276 1.77887 3.70189C1.94892 3.64205 2.29219 3.70801 2.97872 3.83992C3.45924 3.93225 4.02915 4.00002 4.66683 4.00002C6.66683 4.00002 8.66683 2.66669 11.3335 2.66669ZM9.66683 8.00002C9.66683 8.92049 8.92064 9.66669 8.00016 9.66669C7.07969 9.66669 6.3335 8.92049 6.3335 8.00002C6.3335 7.07954 7.07969 6.33335 8.00016 6.33335C8.92064 6.33335 9.66683 7.07954 9.66683 8.00002Z" stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> -->
                  {{ $formatCents(item.sale_price_cents) }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  <!-- <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M4.00016 7.33335V10M12.0002 6.00002V8.66669M11.3335 2.66669C12.966 2.66669 13.8489 2.91653 14.2882 3.11032C14.3467 3.13612 14.376 3.14903 14.4604 3.2296C14.511 3.2779 14.6034 3.41962 14.6272 3.48541C14.6668 3.59518 14.6668 3.65518 14.6668 3.77518V10.9408C14.6668 11.5466 14.6668 11.8496 14.576 12.0053C14.4836 12.1636 14.3944 12.2373 14.2215 12.2981C14.0514 12.358 13.7081 12.292 13.0216 12.1601C12.5411 12.0678 11.9712 12 11.3335 12C9.3335 12 7.3335 13.3334 4.66683 13.3334C3.03436 13.3334 2.15141 13.0835 1.71208 12.8897C1.65358 12.8639 1.62433 12.851 1.5399 12.7704C1.48929 12.7221 1.39691 12.5804 1.37314 12.5146C1.3335 12.4049 1.3335 12.3449 1.3335 12.2249L1.3335 5.05926C1.3335 4.45341 1.3335 4.15049 1.42435 3.99478C1.51676 3.8364 1.60589 3.76276 1.77887 3.70189C1.94892 3.64205 2.29219 3.70801 2.97872 3.83992C3.45924 3.93225 4.02915 4.00002 4.66683 4.00002C6.66683 4.00002 8.66683 2.66669 11.3335 2.66669ZM9.66683 8.00002C9.66683 8.92049 8.92064 9.66669 8.00016 9.66669C7.07969 9.66669 6.3335 8.92049 6.3335 8.00002C6.3335 7.07954 7.07969 6.33335 8.00016 6.33335C8.92064 6.33335 9.66683 7.07954 9.66683 8.00002Z" stroke="#1F1F18" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg> -->
                  {{ $formatCents(item.sale_price_cents * item.quantity) }}
                </div>
              </td>
            </tr>
            <tr class="user-custome-table-row sub-info">
              <td class="user-custome-table-item left-text">
                <div class="sub-total secondary-text fix-margin">Subtotal</div>
                <div class="sub-total secondary-text">Tax</div>
                <div class="secondary-text tip-row" v-if="itemsWithoutTax.some(i => i.type === 'DELIVERY')">
                  Driver tip
                  <InfoBtn width="220px" :alertInfo="`The tip goes directly to the driver. \n The tip does not go to SimpleCater`"/>
                </div>
              </td>
              <td colspan="3">
                <div class="sub-total">&nbsp;</div>
                <div class="sub-total">&nbsp;</div>
                <tip-block v-if="itemsWithoutTax.some(i => i.type === 'DELIVERY')" @tips-add="addTips($event)" :total="tipsTotalPrice" :initialValue="estimate.tips"/>
              </td>
              <td>
                <div class="sub-total sub-total-price fix-margin">
                  {{ $formatCents(subtotalCents) }}
                </div>
                <div class="sub-total sub-total-price">
                  {{ $formatCents(tax.sale_price_cents) }}
                </div>
                <div v-if="itemsWithoutTax.some(i => i.type === 'DELIVERY')">
                  {{ $formatCents(tips) }}
                </div>
              </td>
            </tr>
            <tr class="tip-row-container">
              <td class="user-custome-table-item left-text ">
                
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td ></td>
            </tr>
            <tr class="user-custome-table-row">
              <td class="user-custome-table-item left-text non-border">
                <div>Total</div>
              </td>
              <td class="non-border"></td>
              <td class="non-border"></td>
              <td class="non-border"></td>
              <td class="non-border">
                <div >{{ $formatCents(totalCents + tips) }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
      <div class="estimate-footer" v-if="estimate.status != 'REJECTED'">
        <div class="reject-btn" @click="onReject">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_904_34377)">
              <path
                d="M10.0002 6.00001L6.00016 10M6.00016 6.00001L10.0002 10M14.6668 8.00001C14.6668 11.6819 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00001C1.3335 4.31811 4.31826 1.33334 8.00016 1.33334C11.6821 1.33334 14.6668 4.31811 14.6668 8.00001Z"
                stroke="#D92D20"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_904_34377">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
          Reject This Estimate
        </div>
        <div class="estimate-req-sub">
          <user-default-button
            :textColor="'#1F1F18'"
            :borderColor="'#F4F4EF'"
            btnColor="white"
            :withBorder="true"
            @onClick="onRequestChanges"
          >
            Request changes
          </user-default-button>
          <user-default-button
            @onClick="onAccept"
            :borderColor="'#125F43'"
            btnColor="#125F43"
          >
            <div class="submit-btn">
              Submit
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.6665 8H13.3332M13.3332 8L9.33317 4M13.3332 8L9.33317 12"
                  stroke="white"
                  stroke-width="1.33333"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </user-default-button>
        </div>
      </div>
      <div v-else class="estimate-rejected">
        Estimate was rejected
      </div>
      <accept-estimate-dialog
        :estimate="estimate"
        :contact="contact"
        :request="this.request"
        :address="this.address"
        :visible.sync="displayAcceptDialog"
      ></accept-estimate-dialog>
      <pop-up
        :showPopUp="showChangesPopUp"
        :title="'Request changes'"
        @closePopUp="showChangesPopUp = false"
      >
        <div class="change-pop-text">
          What would you like to be changed?
        </div>
        <el-input v-model="changeText"></el-input>
        <div class="change-pop-up-footer">
          <base-button @onClick="showChangesPopUp = false">Cancel</base-button>
          <base-button :confirmBtn="true" @onClick="changeRequest()"
            >Request changes</base-button
          >
        </div>
      </pop-up>
      <pop-up
        :showPopUp="showRejectPopUp"
        :title="'Reject'"
        @closePopUp="showRejectPopUp = false"
      >
        <div class="change-pop-text">
          What didn’t you like about this menu?
        </div>
        <el-input v-model="rejectText"></el-input>
        <div class="change-pop-up-footer">
          <base-button @onClick="showRejectPopUp = false">Cancel</base-button>
          <base-button :confirmBtn="true" @onClick="rejectRequest()"
            >Reject submit</base-button
          >
        </div>
      </pop-up>
    </div>
  </div>
</template>

<script>
import PopUp from "@/common/PopUp.vue";
import EstimateViewer from "./EstimateViewer.vue";
import UserDefaultButton from "./UserDefaultButton.vue";
import BaseButton from "./BaseButton.vue";
import TipBlock from "./TipBlock.vue";
import InfoBtn from "./InfoBtn.vue";

export default {
  components: { UserDefaultButton, PopUp, BaseButton, TipBlock, InfoBtn },
  mixins: [EstimateViewer],
  data() {
    return {
      showChangesPopUp: false,
      showRejectPopUp: false,
      changeText: "",
      rejectText: "",
      tips: 0
    };
  },
  props: {
    contact: {
      require: false
    }
  },
  methods: {
    onRequestChanges() {
      this.showChangesPopUp = true;
    },
    onReject() {
      this.showRejectPopUp = true;
    },
    changeRequest() {
      const target = `/estimates/${this.estimate.id}/request_changes`;
      this.$simplecater
        .post(target, { customer_notes: this.changeText })
        .then((r) => {
          this.$message("The have changes have been requested");
          this.showChangesPopUp = false;
          this.$router.push("/user/requests");
        })
        .catch(() => {
          this.$message.error("The request for changes failed");
        });
    },
    rejectRequest() {
      const target = `/estimates/${this.estimate.id}/reject`;
      this.$simplecater
        .post(target, { customer_notes: this.rejectText })
        .then((r) => {
          this.estimate = r.data.data;
          this.showRejectPopUp = false;
          this.$message("The estimate has been rejected");
        })
        .catch(() => {
          this.$message.error("Rejection failed");
        });
    },
    getItemsReports() {
      this.submitting = true;
      this.$simplecater
        .get(`/estimates/${this.estimate.id}/csv_report`)
        .then((r) => {
          this.submitting = false;

          if (r.status === 204) {
            this.$message.warning("No data for this estimate");
          } else {
            this.downloadCsv(r.data);
          }
        })
        .catch(() => {
          this.$message.error("Failed loading data");
        });
    },
    downloadCsv(data) {
      const hiddenElement = document.createElement("a");
      hiddenElement.href = "data:text/csv;charset=utf-8," + encodeURI(data);
      hiddenElement.target = "_blank";
      hiddenElement.download = `items-from-request-${this.request.id}-vendor-${this.estimate.vendor_name}.csv`;
      hiddenElement.click();
    },
    addTips(tips) {
      this.tips = tips
      this.request.tips = this.tips.toFixed(2)
    }
  }
};
</script>

<style lang="sass">
.request-title-text
  margin-left: 18px
.estimate-rejected
  color: #D92D20
  text-align: center
  margin-top: 2rem
  font-size: 1.125rem
.change-pop-text
  margin-bottom: 2rem
.change-pop-up-footer
  display: flex
  gap: 2rem
  margin-top: 2rem
.numeric-summary
  width: 100%
  display: flex
  & > div
    flex: 0 0 50%
  .label
  // max-width: 10rem
  .value
// width: 4rem
.status-wrapper
  margin: 0 0 0.5rem
.secondary
  font-size: 12px
  font-style: italic
  white-space: pre-line
  word-break: break-word

.print-version
  text-align: right

.products-from
   display: flex
   border-bottom: none
   gap: .5rem
   font-size: 20px
   color: black
.price-info
   display: flex
   align-items: center
   gap: .375rem
   font-size: .875rem
   margin-left: 2.8rem
   width: 100%
.print-btn
  display: flex
  align-items: center
  gap: .5rem
  color: #125F43
.user-custome-table
  margin-top: 1.5rem
  width: 100%
  text-align: center
  th, td
      padding: 1rem
  &-item
      max-width: 20vw
  thead
      background: #FAFAF5
      height: 2.625rem
      border: none
      font-size: .75rem
      font-weight: 500
      color: #70706B
  .left-text
      text-align: left
  td
      border-bottom: 1px solid #EBEBE4
  .non-border
      border-bottom: none
  .td-content-with-icon
      display: flex
      align-items: center
      justify-content: center
      gap: .375rem
  .pos-name
      font-size: 1.25rem
      margin-bottom: .375rem
      hyphens: auto
  .sub-total
      margin-bottom: .75rem
  .secondary-text
      color: #70706B
      font-size: 14px
  .sub-total-price

.submit-btn
      display: flex
      align-items: center
      gap: .5rem
.reject-btn
      display: flex
      align-items: center
      gap: .5rem
      color: #D92D20
      cursor: pointer
.estimate-footer
      display: flex
      justify-content: space-between
.estimate-req-sub
      display: flex
      gap: 1.25rem
.tax-info
  font-size: 0.8rem
  margin-left: 2.8rem
  margin-top: 6px

.estimate-title
  color: black !important
  border-bottom: none !important

.no-margin-bottom
  margin-bottom: 6px !important
  padding-bottom: 0 !important

.tip-row
  display: flex
  align-items: center
  gap: 6px

.sub-info
  td
    border-bottom: none
    padding-bottom: 0

.tip-row-container
  td
    padding-top: 0

.fix-margin
  margin-bottom: 18 px !important       
</style>
