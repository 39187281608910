<template lang='pug'>
div
  el-table(:data='requests', v-loading='requests == null', :row-class-name='getRowClassName')
    el-table-column(type='expand')
      template(slot-scope='props')
        request(:request-summary='props.row' :updateRequests="getData" @clearAssignee="clearAssignee" @estimatePrePublished="(some) => switchAssignee(props.row.id, some)")
    el-table-column(label='Event')
      template(slot-scope='scope')
        .request-name {{scope.row.name}} ({{ $formatCents(scope.row.budget_cents * scope.row.people) }})
        | ({{ $formatCents(scope.row.budget_cents * scope.row.people) }})
        div.secondary
          i.mat-icon person
          | {{scope.row.people}}
          span.hpad
            | {{$formatCents(scope.row.budget_cents)}}/person
          br
          | Request \#{{scope.row.id}}
          br
          //- .opportunity {{ $formatCents(scope.row.budget_cents * scope.row.people) }} opportunity
    el-table-column(label='Delivery')
      template(slot-scope='scope')
        div(v-show='isDueTomorrow(scope.row.delivery_date)')
          .sc-label.sc-label__danger
            i.mat-icon error
            |  DUE TOMORROW
        div(v-show='isDueToday(scope.row.delivery_date)')
          .sc-label.sc-label__danger
            i.mat-icon error
            |  DUE TODAY
        div
          i.mat-icon.secondary date_range
          |  {{$formatDate(scope.row.delivery_date)}}
        div
          i.mat-icon.secondary access_time
          |  {{ $formatTime(scope.row.delivery_time) }}

    el-table-column(label='Status')
      template(slot-scope='scope')
        status-tag(:status='scope.row.status')
    el-table-column(label='Customer/Assignee')
      template(slot-scope='scope')
        | {{scope.row.customer_account_name}}
        br(v-if="scope.row.assignee")
        span(v-if="scope.row.assignee")
          | {{scope.row.assignee}}
    el-table-column(label='Actions')
      template(slot-scope='scope')
        ul.actions-list
          li(v-if='scope.row.status != "CANCELLED"')
            el-button(type='text', @click='markCancelledDialog(scope.row.id)')
              | Cancel
          li(v-if='scope.row.status != "CANCELLED"')
            el-button(type='text', @click='onEditDialog(scope.row.id)' )
              | Edit
          li
            el-button(type="text", @click='switchAssignee(scope.row.id)')
              | {{assigneeHeader(scope.row.assignee)}}
  el-dialog(
    title="Assignee"
    :visible="dialogVisible"
    @close="dialogId=''"
    width="30%")
    el-input(v-model="newAssignee" v-loading="dialogLoading")
    span(slot="footer" class="dialog-footer")
      el-button(@click="switchAssignee('')")| Cancel
      el-button(type="primary" @click="createOrChangeAssignee")| OK


  el-col()
    el-pagination(
        v-if="pagination.pages > 1"
        @current-change="onPaginationChange"
        :page-size="pagination.per_page",
        :pager-count="5",
        layout="prev, pager, next",
        :total="pagination.total")
</template>

<script>

  import StatusTag from '../../common/StatusTag.vue'
  import Request from './Request.vue'
  import moment from 'moment'

  function data(){
    return {
      withPostData: false,
      newAssignee: '',
      dialogLoading: false,
      dialogId: '',
      requests: null,
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null,
      }
    }
  }

  function onEditDialog(id) {
    const target = `requests/${id}/edit`;
    this.$router.push(target)
  }

  function onPaginationChange(current_page){
    this.getData(current_page);
  }

  function created(){
    this.getData()
    this.$bus.$on('estimates-published', this.getData)
  }

  function getData(current_page){
    let reqParams = (current_page ?{params:{ page:current_page}}:{params:{}});
    if(this.sorting){
      this.$isNumber(this.sorting)?reqParams.params.id=this.sorting:reqParams.params.q=this.sorting
    }

    this.$simplecater.get(this.targetUri,reqParams).then((r)=>{
      this.requests = r.data.data
      this.pagination = r.data.pagination;
    }).catch(()=>{
      this.$message.error('Failed to load data')
    })
  }

  function markCancelledDialog ( id ) {
    const title = "Cancel request"
    const msg = "The request will be cancelled. Continue?"
    this.$confirm(msg, title, {
      confirmButtonText: 'Yes, cancel request',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.markCancelled(id)
    }).catch(() => { })
  }

  function markCancelled ( id ) {
    const target = `/requests/${id}/mark_cancelled`
    this.$simplecater.post(target).then(() => {
      this.$message("The request has been cancelled")
      this.getData();
    }).catch(()=>{
      this.$message.error("Cancelling failed")
    })
  }

  function isDueTomorrow ( date ) {
    let now = new Date()
    let format = 'YYYY-MM-DD'
    let tomorrowDate = moment(new Date((new Date()).setDate(now.getDate()+1)), format)
    return moment(date, format).isSame(tomorrowDate, 'day')
  }

  function isDueToday ( date ) {
    let now = new Date()
    let format = 'YYYY-MM-DD'
    let tomorrowDate = moment(now, format)
    return moment(date, format).isSame(tomorrowDate, 'day')
  }

  function assigneeHeader (assignee) {
    return assignee ? 'Change Assignee' : 'Create Assignee'
  }

  function switchAssignee(id, getDataAfterChange) {
    this.withPostData = !!getDataAfterChange
    this.dialogId = id
    id && (this.newAssignee = this.requests.find((r) => r.id === id).assignee ?? '')
    !!this.newAssignee && (this.newAssignee = id ? this.requests.find((r) => r.id === id).assignee ?? '' : '')
  }

  function clearAssignee(id) {
    this.requests[this.requests.findIndex((r) => r.id === id)].assignee = ''
  }

  async function createOrChangeAssignee() {
    this.dialogLoading = true;
    const formData = new FormData()
    formData.append('assignee', this.newAssignee)
    this.$simplecater.post(`/requests/${this.dialogId}/assign`, formData).then(async () => {
      this.$message.success("Assingee assigned successfully")
      this.withPostData ? (await this.getData()) : (this.requests[this.requests.findIndex((r) => r.id === +this.dialogId)].assignee = this.newAssignee)
      this.dialogId = '';
    }).catch(() => {
      this.$message.error("Error while assigned")
    }).finally(() => {
      this.dialogLoading = false;
    })
  }

  function getRowClassName(data) {
    if(data.row.status == 'PENDING_CHANGES') {
      return 'pending-changes-request'
    } else if (data.row.first) {
      return 'first-request'
    } else {
      return ''
    }
  }

  const watch = {
    sorting: function(){
      this.getData();
    },
    filter: function(newFilter){
      console.log("FILTER FROM TO", this.filter, newFilter)
    },
    $route: function() {
      this.getData();
    }
  }

  const computed = {
    dialogVisible: function(){
      return !!this.dialogId
    },
    targetUri: function(){
      if(this.filter == 'all'){
        return '/requests'
      }else{
        return `/requests/${this.filter}`
      }
    }
  }

  const components = {
    StatusTag, Request
  }

  const methods = {
    getData,
    onPaginationChange,
    markCancelled,
    markCancelledDialog,
    isDueToday,
    isDueTomorrow,
    onEditDialog,
    assigneeHeader,
    switchAssignee,
    createOrChangeAssignee,
    clearAssignee,
    getRowClassName
  }

  const props = {
    filter: { required: true },
    sorting:{ required: false }
  }

  export default { methods, components, data, created, watch, props, computed }
</script>

<style lang="sass">
  .el-table__row.first-request
    background-color: #e1fcf7
  .el-table__row.pending-changes-request
    background-color: #fdecec
</style>

<style scoped lang="sass">
  .request-name
    color: #000
  .opportunity
    color: #222
  .request-list__link
    display: inline-block
    padding-right: 10px
  </style>
