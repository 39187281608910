
<template lang="pug">
drawer(:dismissRoute="dismissRoute")
  .drawer-title
    i.mat-icon add_circle
    | New Product

  el-form(
    :label-width='$labelWidth', label-position='right', ref='productForm'
    v-loading='loadingForm', :model='product', :rules='rules'
    )

    // VENDOR
    el-form-item(label='Vendor', prop='vendor_id')
      el-select(v-model='product.vendor_id', filterable, :filter-method="filteringVendors" placeholder='Choose one', class='full-width-select')
        el-option(v-for='item in filteredVendors', :key='item.id', :label='item.name', :value='item.id')

    // NAME
    el-form-item(label='Name', prop='name')
      el-input(v-model='product.name')

    // DESCRIPTION
    el-form-item(label='Description', prop='description')
      el-input(
        type='textarea' v-model='product.description' max='250',
        :autosize='{ minRows: 3, maxRows: 7 }'
      )

    // SERVES
    el-form-item(label='Serves', prop='serves')
      el-input-number(v-model='product.serves', :min='1')

    // INTERNAL_UNIT_PRICE_CENTS
    el-form-item(label='Unit price', prop='base_price_dollars')
      el-input(v-model='product.base_price_dollars')
        template(slot='prepend')
          | $

    // Sale unit price
    el-form-item(label='Sale price', prop='sale_price')
      el-input(v-model='product.sale_price_dollars', disabled, v-loading='quotingProduct')
        template(slot='prepend')
          | $

    // Purchase unit price
    el-form-item(label='Purchase price', prop='purchase_price')
      el-input(v-model='product.purchase_price_dollars', disabled, v-loading='quotingProduct')
        template(slot='prepend')
          | $

    // NOTES
    el-form-item(label='Delivery notes', prop='notes')
      el-input(
        type='textarea' v-model='product.notes' max='250',
        :autosize='{ minRows: 3, maxRows: 7 }'
      )
    // BUTTONS
    el-form-item
      el-button(type='primary' @click='onSubmit', :loading='submitting')
        | Save
      el-button(@click='onCancel')
        | Cancel
</template>


<script>
  import Drawer from '../../layout/Drawer.vue'
  import _ from 'underscore'
  import { filteredDropdown } from '@/mixins/filteredDropdown';

  const mixins = [filteredDropdown('vendors', (input) => ({ name }) => name.toLowerCase().includes(input.toLowerCase()))]

  const emptyProduct = {
    name: null,
    serves: 1,
    description: null,
    vendor_id: null,
    base_price_dollars: null,
    sale_price_dollars: null,
    purchase_price_dollars: null,
    notes: null
  }

  function buildFormRules ( componentInstance ) {
    return {
      name: [
        { max: 100, message: 'Name cannot exceed 100 characters', trigger: 'change' },
        { required: true, message: 'Please enter a name', trigger: 'change' }
      ],
      description: [
        { required: true, message: 'Please, fill the description', trigger: 'blur' }
      ],
      notes: [
        { required: true, message: 'Please, fill the notes', trigger: 'blur' }
      ],
      vendor_id: {
        type: 'integer', required: true, trigger: 'change',
        message: 'Please select a vendor' },
      base_price_dollars: {
        required: true,
        type: 'number',
        message: 'Please input a valid value in dollars',
        trigger: 'blur',
        transform: parseFloat },
      serves: {
        required: true, type: 'integer', message: 'Please input the number of serves',
        trigger: 'blur' },
    }
  }

  function getProduct ( ) {
    this.$simplecater.get('/products/' + this.productId).then((r)=> {
      let product = r.data.data
      product.base_price_dollars = product.base_price_cents/100
      this.product = product
    })
  }

  function data ( ) {
    return {
      rules: buildFormRules(this),
      dataForSubmit: null,
      loadingForm: true,
      submitting: false,
      vendors: null,
      filteredVendors: [],
      product: emptyProduct,
      quotingProduct: false,
      productId: '',
      dismissRoute: ''
    }
    // if ( this.id ) {
    //   return { product: null, ...baseData }
    // } else {
    //   return { product: emptyProduct, ...baseData }
    // }
  }

  function created ( ) {
    let promises = [ this.getVendors() ]
    this.productId = this.pid ? this.pid : this.id
    const pathName = this.$router.currentRoute.path.split('/')[2]
    this.dismissRoute = pathName === 'vendors' ? '/staff/vendors/' + this.id : '/staff/products'
    if ( this.productId ) promises.push( this.getProduct() )
    Promise.all(promises).then( ( ) => {
      this.loadingForm = false
    }).catch( ( err ) => {
      this.$message.error('Failed loading data')
    })
  }

  function getVendors() {
    return this.$simplecater.get('/vendors', {
      params: {
        page_size: 'all',
        deleted: false
      }
    }).then((r) => {
      this.vendors = _.sortBy(r.data.data, 'name')
    })
  }

  function onCancel ( ) {
    const route = this.$route.matched[1].path
    if (route !=='/staff/vendors/:id') {
      this.$router.push('/staff/products')
    } else {
       this.$router.push('/staff/vendors/' + this.$route.params.id)
    }    
  }

  // This the click event handles, only triggers submission if validations pass
  function onSubmit ( ) {
    this.$refs.productForm.validate((valid) => {
      if (valid && this.product.base_price_dollars >= 0) {
        this.submitForm()
      } else {
        this.$message.error('Some validations failed, please check your inputs')
        return false;
      }
    })
  }

  function buildSubmitData (){
    const body = Object.assign({},this.product)
    body.base_price_cents = Math.round(parseFloat(body.base_price_dollars) * 100)
    body.sale_price_cents = Math.round(parseFloat(body.sale_price_dollars) * 100)
    body.purchase_price_cents = Math.round(parseFloat(body.purchase_price_dollars) * 100)
    return body
  }

  // This the actual submission after validation
  function submitForm ( ) {
    const route = this.$route.matched[1].path
    this.submitting = true
    let promises = []
    // We copy the request, because setting contact or address id would produce
    // unwanted UI changes (forms disappearing, unexpected select values...)
    const body = this.buildSubmitData()

    let verb = 'post'
    let url = '/products'
    if ( this.productId ) {
      verb = 'put'
      url += '/' + this.productId
    }

    this.$simplecater[verb](url,body).then((r)=>{
      // SUBMIT SUCCEEDED
      this.$message("Product has been " + ((verb === 'post')? 'created' : 'saved'))
      if(route !=='/staff/vendors/:id') {
        this.$router.push("/staff/products")
      } else {
        this.$bus.$emit('products-updated')
        this.$router.push('/staff/vendors/' + this.$route.params.id)
      }
    }).catch(
      // SUBMIT FAILED
      this.$handleErrorFor('Creating / Editing product').bind(this)
    ).finally(()=>{
      this.submitting = false
    })
  }

  function quoteProduct () {
    this.product.base_price_dollars = this.product.base_price_dollars.replace(/,/, '.')
    let basePrice = this.product.base_price_dollars
    console.log("NEW PRICE", basePrice)
    const priceFloat = parseFloat(basePrice)
    if (this.product.vendor_id == null) return
    if (isNaN(priceFloat)) return
    const body = {
      base_price_cents: Math.round(priceFloat*100),
      vendor_id: this.product.vendor_id
    }
    this.quotingProduct = true
    this.$simplecater.post('/products/quote', body).then((r)=>{
      this.quotingProduct = false
      const quote = r.data.data
      this.product.sale_price_dollars = quote.sale_price_cents/100
      this.product.purchase_price_dollars = quote.purchase_price_cents/100
    }).catch(()=>{
      this.quotingProduct = false
      this.$message.error("Failed to quote the final price")
    })
  }

  const debouncedQuoter = _.debounce(quoteProduct, 400)

  const watch = {
    'product.base_price_dollars': debouncedQuoter,
    'product.vendor_id': debouncedQuoter
  }

  const computed = {}

  const components = { Drawer }

  const methods = {
    submitForm,
    onCancel,
    onSubmit,
    getProduct,
    getVendors,
    buildSubmitData
  }

  let props = ['id', 'pid']

  export default {mixins, props, data, created, components, methods, computed, watch}
</script>
