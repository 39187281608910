<style scoped lang="sass">
  .sub-header
    display: flex
    flex-direction: row
    justify-content: space-between
  .search
    width: 12.5rem
  .sub-header-left-part
    display: flex
    gap: .75rem   
</style>
<template>
<div class="main-container">
    <div class="view-content">
       <h2 class="page-title">Orders</h2>
      <div class="sub-header">
      <div class="sub-header-left-part">
        <toggle-switch
          :options="toggleOption"
          :disabled="false"
          @change="switchTab($event.value)"
        />
          <el-select  placeholder='Status' v-model="curentFilterStatus">
            <el-option class="user-select" v-for="status in filterStatusesPending" :value="status.value" :label="status.label" :id="status.id" />
          </el-select>
          <!-- <date-filter/> -->
      </div>
      <el-input class="search" v-model="sorting" prefix-icon="el-icon-search" placeholder="Search orders..."></el-input>
    </div>
         <!-- <el-card>
             <div slot="header"><i class="mat-icon">assignment_turned_in</i>Orders</div>
             <el-tabs v-model="activeIndex" type="card">
                 <el-row :gutter="10">
                     <el-col :span="9">
                         <el-input v-model="sorting" prefix-icon="el-icon-search" placeholder="Search"></el-input>
                  </el-col>
              </el-row>
                <el-tab-pane v-for="filter in filters" :label="$upcaseFirst(filter)" :name="filter" :key="filter">
                    <order-list :filter="filter" :sorting="sorting" v-if="filter == activeIndex"></order-list>
                </el-tab-pane>
             </el-tabs>
         </el-card> -->
     <div class="orders-contentc">
       <div v-for="filter in filters"  :name="filter" :key="filter">
         <order-list :filter="filter" :sorting="sorting" v-if="filter == activeIndex" :curentFilterStatus="curentFilterStatus"></order-list>
       </div>  
     </div>    
    </div>
</div>
</template>

<script>
  import DateFilter from './blocks/DateFilter.vue'
  import OrderCard from './blocks/OrderCard.vue'
  import OrderList from './blocks/OrderList.vue'
  import ToggleButton from './blocks/ToggleButton.vue'

  function data(){
    return {
      filters: ['open', 'closed'],
      sorting:'',
      activeIndex: '',
      filterStatusesPending: [
      {id: 1, label: 'All Statuses', value: 'ALL_STATUSES'}, 
      {id: 2, label: 'Submitted', value: 'SUBMITTED'}, 
      {id: 3, label: 'Received', value: 'RECEIVED'},
      {id: 4, label: 'Ordered', value: 'ORDERED'},
      {id: 5, label: 'Assigned', value: 'ASSIGNED'},
      ],
      curentFilterStatus: 'ALL_STATUSES',
      orders: null,
      activeIndex: 'open',
      loadingOrders: true,
      loadingContacts: true,
      toggleOption: {
      layout: {
        color: 'black',
        backgroundColor: 'white',
        selectedColor: 'white',
        selectedBackgroundColor: 'green',
        borderColor: 'black',
        fontFamily: 'Inter',
        fontWeight: 'normal',
        fontWeightSelected: 'bold',
        squareCorners: false,
        noBorder: true
        },
        size: {
        fontSize: .875,
        height: 2.125,
        padding: .4375,
        width: 11.25
        },
        config: {
        delay: 0,
        preSelected: 'open',
        disabled: false,
        items: [
            { name: 'Open', value: 'open', color: '#125F43', backgroundColor: '#E9F2EC' },
            { name: 'Closed', value: 'closed', color: '#125F43', backgroundColor: '#E9F2EC' }
        ]
      }
      }
    }
  }

  function switchTab (e) {
    this.curentFilterStatus = 'ALL_STATUSES'
    this.activeIndex = e
  }

  function getOrders(){
    this.loadingOrders = true
    return this.$simplecater.get('/orders').then((r)=> {
      this.orders = r.data.data
      this.loadingOrders = false
    })
  }

  // function goToAddressBuilder(){
  //   this.$message("Pending wiring")
  //   //this.$router.push("/user/orders/new")
  // }
  //
  //
  // function goToContactBuilder(){
  //   this.$message("Pending wiring")
  //   //this.$router.push("/user/orders/new")
  // }

  function created(){
    this.getOrders()
  }

  const watch = {
    '$route' (to, from) {
      console.log("Reloading data")
      this.getOrders()
    }
  }

  const components = { OrderList, ToggleButton, OrderCard, DateFilter }

  const methods = {
    getOrders,
    switchTab
  }

  export default { methods, components, data, created }

</script>
