<template>
  <div
    id="app"
    :class="userGroup === 'customer' || !userGroup ? 'app-background' : ''"
  >
    <div class="nprogress-container"></div>
    <router-view></router-view>
  </div>
</template>

<script>
import auth from "./auth.js";

export default {
  data() {
    return {
      userGroup: "customer",
    };
  },

  updated() {
    this.userGroup = auth?.getSession()?.group;
    if (!this.userGroup || this.userGroup === "customer") {
      document.body.style.backgroundColor = "#FAFAF5";
    } else {
      document.body.style.backgroundColor = "white";
    }
  },
};
</script>

<style lang="sass">
// @import './variables'

*
  box-sizing: border-box

html
  background: white

html, body, #app
  min-height: 100%
  padding: 0
  margin: 0
  font-size: 18px
  color: $colorText
  @media screen and (max-width: 1440px)
    font-size: 16px
    @media screen and (max-width: 1200px)
      font-size: 14px

html, body, #app, button, input[type="text"], input[type="email"], input[type="number"], input[type="date"], input[type="submit"], textarea
  font-family: 'Open Sans', Helvetica, sans-serif

.nprogress-container
  height: 3px
  // position: fixed
  // top: 0
  // left: 0
  // z-index: 550

#nprogress .bar
  height: 3px

a
  text-decoration: none

.el-card + .el-card
  margin-top: 1.5rem

.buttons-section
  padding-top: 1rem

.estimates-card__container
  max-height: 30rem
  overflow-y: auto

  &::-webkit-scrollbar
      width: 10px

  &::-webkit-scrollbar-track
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
      // border-radius: 10px
      // background-color: #bbb
      border-left: solid thin #eee

  &::-webkit-scrollbar-thumb
      border-radius: 10px
      background-color: #eee
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5)

.secondary
  opacity: 0.8

.hpad
  padding-left: 0.5rem
  padding-right: 0.5rem

ul.actions-list
  list-style-type: none
  padding: 0
  margin: 0
  li
    .el-button
      padding: 0

.brand-color
  color: $colorMenu

.v-modal
  opacity: 0.3

.app-background
   background-color: #FAFAF5
</style>
