<template>
    <button class="base-button" @click="$emit('onClick')" :class="confirmBtn ? 'confirm' : ''">
        <slot></slot>
    </button>
</template>

<script>
export default {
    name: "BaseButton",
    props: {
        confirmBtn: {
            type: Boolean,
            default: false
        }
    }
}
</script>

<style lang="sass">
.base-button
  width: 100%
  background: inherit
  border: 1px solid #125F43
  box-shadow: 0px 8px 40px -4px rgba(31, 31, 24, 0.03)
  border-radius: 12px
  height: 3rem
  cursor: pointer
  font-size: .875rem
  color: #125F43
  font-family: 'Inter', "Open Sans", Helvetica, sans-serif
  font-weight: 500

.confirm
  background: #EB5B13
  color: white

</style>