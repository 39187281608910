<template>
  <div>
    <header-bar/>
    <page-with-navigation>
        <staff-navigation/>
    </page-with-navigation>
  </div>
</template>

<script>
  import PageWithNavigation from './PageWithNavigation.vue'
  import StaffNavigation from './StaffNavigation.vue'
  import HeaderBar from './HeaderBar.vue'
  export default {
    components: { StaffNavigation, PageWithNavigation, HeaderBar }
  }
</script>
