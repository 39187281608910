<style lang="sass" scoped>
.date-filter
    &-select
        display: flex
        width: 12.5rem
        backgroud: white
        padding: .625rem .75rem
</style>
<template>
    <div class="date-filter">
        <div class="date-filter-select">
            {{curentFilter}}
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6L8 10L12 6" stroke="#4A4A45" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
        <div class="date-filter-container" v-if="showDropDown">

        </div>
    </div>
</template>
<script>

export default {
    data() {
        return {
            dateFilters: ['All Dates', 'Today', 'This Week', 'This Month'],
            curentFilter: 'All Dates',
            showDropDown: false
        }
    }
}
</script>
