export default {
    name: 'roleDetection',
    data () {
        return {
            role: ''
        }
    },
    methods: {
        getRole() {
            const userRole = this.$route.matched[0].path;
            this.role = userRole.slice(1);
            return this.role;
        }
    }
}
