<style scoped lang="sass">
.el-row
  display: flex
  flex-direction: rows
  .el-col-12
    & > div
      height: 100%
.settings-page-subtitle
  font-size: 24px 
.settings-page-container
  display: flex
  flex-wrap: wrap
  justify-content: space-between
  gap: 2.5rem
.settings-page-content
  flex: 0 0 60%
  flex-grow: 1 
.settings-page-tab-description
  padding-bottom: 1.25rem
  border-bottom: 1px solid #EBEBE4
  margin-bottom: 1.5rem     
</style>

<template>
<div class="main-container">
    <div class="view-content">
      <h2 class="page-title">Settings</h2>
        <div class="settings-page-container">
          <div class="settings-page-content">
              <div class="settings-page-subtitle">
                {{activeView}}
              </div>
              <div class="settings-page-tab-description subtitle-text-desc"> 
               {{activeView === 'Change Password' ? 'Here you can submit a password change request' : 'Update your address and personal details here'}}
              </div>
                <account-address v-if="activeView === 'Address'" :label-position="labelPosition" :label-width="labelWidth" :backend-account="account" @change="childChangedAccount"></account-address>
                <account-contact v-if="activeView === 'Contact'" :label-position="labelPosition" :label-width="labelWidth" :backend-account="account" @change="childChangedAccount"></account-contact>
                <account-preferences v-if="activeView === 'Request Preferences'" :label-position="labelPosition" :label-width="labelWidth"></account-preferences>
                <password-changer v-if="activeView === 'Change Password'" :label-position="labelPosition" :label-width="labelWidth"></password-changer>
          </div>
          <div class="setting-page-switcher">
            <user-setting-switcher @onActiveTabSet="activeView = $event"/>
          </div>
        </div>
    </div>
  </div>
  </div>
</div>
</template>

<script>

  import AccountPreferences from './blocks/AccountPreferences.vue'
  import PasswordChanger from './blocks/PasswordChanger.vue'
  import AccountAddress from './blocks/AccountAddress.vue'
  import AccountContact from './blocks/AccountContact.vue'
  import UserSettingSwitcher from './blocks/UserSettingSwitcher.vue'

  function data(){
    return {
      labelPosition: 'right',
      labelWidth: '9rem',
      account: null,
      activeView: 'Address'
    }
  }

  function created(){
    this.getAccount()
  }

  function getAccount(){
    this.$simplecater.get('/account').then((r)=>{
      this.account = r.data.data
    }).catch(()=>{
      this.$message.error("Failed to load the account information")
    })
  }

  function childChangedAccount(newVal){
    console.log("CHILD changed account to", newVal)
    this.account = newVal
  }

  const components = {
    AccountPreferences, PasswordChanger,
    AccountAddress, AccountContact, UserSettingSwitcher
  }

  const methods = {
    getAccount, created, childChangedAccount
  }
  const watch = {
    '$route' (to, from) {
      console.log("Reloading data")
      this.getAccount()
    }
  }

  export default { data, methods, watch, components, created }

</script>
