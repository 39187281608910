<style lang="sass" scoped>
    .dis 
        color: #C0C4CC
    .exist
        background: #FEF7F3
        border-radius: .75rem
        cursor: pointer
        height: 100%
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        gap: .25re
        max-width: 3.75rem
        width: 100%
        margin: auto
    .current-date
        background: #E9F2EC
        color: #2BB673
        border-radius: .75rem
        max-width: 3.75rem
        text-arling: center
        width: 100%
        margin: auto
    .one-calendar
        width: 100%
        height: 9.375rem
        border-collapse: collapse
        thead td
            border-top-color: transparent
            border-left-color: transparent
            border-right-color: transparent
            padding-bottom: 1.25rem
        thead
            tr:last-of-type td
                text-align: center
            tr:first-of-type td
                border-bottom-color: transparent           
    .arrows
        width: 2.5rem
        &.right
            text-align: right
            button
                margin-right: -1.5625rem
        &.left button
            margin-left: -1.5625rem
        &:last-of-type
            text-align: right
    .name-month
        text-align: center
    td
        width: 2.8125rem
        p
            margin: 0
            height: 3.125rem
            display: flex
            justify-content: center
            align-items: center

    .user-calendar-header
        display: flex
        flex-direction: row
        justify-content: space-between
        align-items: center

    .nav-arrows
        gap: .625rem
        display: flex
        cursor: pointer

    .calendar-title
        width: 100%
        margin-bottom: 35px

    .day-name
      font-weight: 600
      font-size: .75rem
      line-height: 150%
      text-align: center
      color: #999994      
    
    .point-container
      display: flex
      flex-derection: row 

    .point
      width: .375rem
      height: .375rem
      background: #EB5B13
      border-radius: 100%

    .event-counter
      font-weight: 700
      font-size: .625rem
      line-height: .75rem
      letter-spacing: -0.03em
      color: #EB5B13

    .evet-couter-points
      display: flex
      flex-derection: row
      gap: .25rem 

    .events-date
      font-weight: 500
      font-size: 1rem
      line-height: 1.5rem
      display: flex
      align-items: center
      letter-spacing: -0.03em
      color: #1F1F18    

    .view-all-request-btn
      height: 3rem
      background: #FEF7F3
      box-shadow: 0px 8px 40px -4px rgba(31, 31, 24, 0.03)
      border-radius: .75rem
      color: #EB5B13
      gap: .375rem
      margin-top: .5rem
      display: flex
      align-items: center
      justify-content: center
      cursor: pointer
      font-family: 'Inter'
      font-weight: 500
   
</style>

<template>
<div>
        <div class="user-calendar-header calendar-title">
            <div>
                {{formattedDate}}
            </div>
            <div class="user-calendar-header nav-arrows">
                <div @click="prevYear">‹‹</div>
                <div @click="prevMonth">‹</div>
                <div @click="nextMonth">›</div>
                <div @click="nextYear">››</div>
            </div>
        </div>
    <table class="one-calendar" v-loading="!datas">
        <thead>            
            <tr>
                <td class="day-name">Mon</td>
                <td class="day-name">Tue</td>
                <td class="day-name">Wed</td>
                <td class="day-name">Thu</td> 
                <td class="day-name">Fri</td>
                <td class="day-name">Sat</td>
                <td class="day-name">Sun</td>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(week, i) in formattedMonthes" :key="`${i}${currYear}${currMonth}`">
                <td v-for="day in week"
                    :class="day.disabled ? disDay : trueDay"
                    :key="day.id"
                >
                    <el-popover
                        v-if="!day.disabled && !!day.datas"
                        placement="top-start"
                        trigger="click"
                    >
                        <div>
                            <div class="events-date">{{formattedDate}}</div>
                            <OrderMini v-for="data in day.datas.slice(0, 3)" :key="data.id" :order='data' showDate/>
                            <router-link to="orders">
                            <div class="view-all-request-btn" v-if="day.datas.length > 3">
                               View All Orders
                               <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3.16699 8H13.8337M13.8337 8L9.83366 4M13.8337 8L9.83366 12" stroke="#EB5B13" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                               </svg>
                            </div>
                            </router-link>
                        </div>
                        <div slot="reference" class="exist">{{day.numOfMonth}} 
                            <div class="point-container">
                                <div class="evet-couter-points" v-if="day.datas.length <= 3">
                                    <div class="point"  v-for="data in day.datas"></div>
                                </div>
                                <div class="event-counter" v-else>{{day.datas.length}}</div>
                            </div> 
                        </div>
                    </el-popover>
                    <p v-else-if="checkCurrentDate(day.numOfMonth) && !day.disabled" :class="day.disabled && 'dis'" class="current-date">{{day.numOfMonth}}</p>
                    <p v-else :class="day.disabled && 'dis'">{{day.numOfMonth}}</p>
                </td>
            </tr>
        </tbody>
    </table>
 </div>   
</template>

<script>
import OrderMini from '../user/blocks/OrderMini.vue'
import moment from 'moment'
const openStatuses = ['SUBMITTED', 'RECEIVED', 'ORDERED', 'ASSIGNED']
export default {
    name: 'UserCalendar',
    components: { OrderMini },
    data(){
        return {
            currDate: new Date(),
            currMonth: 0,
            currYear: 0,
            allWeeks: [[], [], [], [], [], []],
            trueDay: 'day',
            disDay: 'day1',
            datas: null
        }
    },
    mounted(){
        this.currMonth = this.currDate.getMonth();
        this.currYear = this.currDate.getFullYear();
        this.showMonth();
        this.loadDatas()
    },
    computed:{
        formattedDate(){
            return new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short' }).format(new Date(this.currYear, this.currMonth))
        },
        formattedMonthes () {
            return this.allWeeks.map((week) => week.map((day) => {
                const thisDate = moment(`${this.currYear}-${this.withZero(this.currMonth+1)}-${this.withZero(day.numOfMonth)}`, "YYYY-MM-DD")
                const allDatasFromDay = (this.datas || []).filter(({ delivery_date }) => {
                    const dataDate = moment(delivery_date, "YYYY-MM-DD")
                    return dataDate.date() === thisDate.date() && dataDate.year() === thisDate.year() && dataDate.month() === thisDate.month()
                })
                return {
                    ...day,
                    ...(allDatasFromDay.length > 0 && { datas: allDatasFromDay })
                }
            }))
        }
    },
    methods: {
        checkCurrentDate(calendarDate) {
            const date = new Date();
            date.setMonth(date.getMonth());
            const shortCurrentMonth = date.toLocaleString('en-US', {month: 'short' });
            const currentYear = date.getFullYear()
            if(this.formattedDate.split(' ')[0] === shortCurrentMonth && +this.formattedDate.split(' ')[1] === currentYear) {
              return this.currDate.getDate() === calendarDate;
            };
        },

        withZero(num) {
            return num < 10 ? `0${num}` : `${num}`
        },
        showMonth(){
            this.allWeeks = [[], [], [], [], [], [], []];
            const shadowWeek = [[], [], [], [], [], [], []];
            const countDaysInMonth = new Date(this.currYear, this.currMonth+1, 0).getDate();
            const dayFirst = new Date(this.currYear, this.currMonth, 1).getDay() || 7;
            const arrDayInMonth = Array.from({ length: countDaysInMonth }, (_, i) => i+1);
            const lastMonthDays = new Date(this.currYear, this.currMonth, 0).getDate()+1;
            for(let i = 1; i<=dayFirst-1; i++){
                shadowWeek[0].push({
                    id: i-dayFirst, 
                    numOfMonth: lastMonthDays-(dayFirst-i), 
                    disabled: true
                });
            }
            for(let j = 1; j<=8-dayFirst; j++){
                shadowWeek[0].push({id: j, numOfMonth: j, disabled: false});
                arrDayInMonth.shift();
            }
            for(let j = 1, count; j<=21; j++){
                if(shadowWeek[1].length<7) count = 1;
                else if(shadowWeek[2].length<7) count = 2;
                else count = 3;
                shadowWeek[count].push({
                    id: arrDayInMonth[0], 
                    numOfMonth: arrDayInMonth[0], 
                    disabled: false
                });
                arrDayInMonth.shift();
            }        
            let nextDays = 1;            
            const atLEAST = arrDayInMonth.length;
            if(arrDayInMonth.length<=7){
                if(arrDayInMonth.length !== 0){
                    for(let x = 1; x<=atLEAST; x++){                        
                        shadowWeek[4].push({
                            id: arrDayInMonth[0], 
                            numOfMonth: arrDayInMonth[0], 
                            disabled: false
                        });
                        arrDayInMonth.shift();
                    }
                }
                while(shadowWeek[4].length !== 7){
                    shadowWeek[4].push({
                            id: countDaysInMonth+nextDays, 
                            numOfMonth: nextDays, 
                            disabled: true
                    });
                    nextDays++
                    arrDayInMonth.shift();
                }
                for(let i = 1; i<=7; i++){
                    shadowWeek[5].push({
                            id: countDaysInMonth+nextDays, 
                            numOfMonth: nextDays, 
                            disabled: true
                    });
                    nextDays++
                }
            } else {
                for(let i = 1; i<=atLEAST; i++){
                    if(shadowWeek[4].length<7){
                        shadowWeek[4].push({
                            id: arrDayInMonth[0], 
                            numOfMonth: arrDayInMonth[0],
                            disabled: false
                        });
                        arrDayInMonth.shift();
                    } else {
                        shadowWeek[5].push({
                            id: arrDayInMonth[0], 
                            numOfMonth: arrDayInMonth[0],
                            disabled: false
                        });
                        arrDayInMonth.shift();                        
                    }
                }
                while(shadowWeek[5].length !== 7){
                    shadowWeek[5].push({
                        id: countDaysInMonth+nextDays, 
                        numOfMonth: nextDays,
                        disabled: true
                    });
                    nextDays++;
                }
            }            
            this.allWeeks = [...shadowWeek]       
        },
        loadDatas() {
            this.showMonth();
            this.datas = null;
            this.$simplecater.get(`/orders/open-per-month?month=${this.currMonth+1}&year=${this.currYear}`)
                .then((r) => {
                    this.datas = r.data.data.filter(({ status }) => openStatuses.some((openStatus) => openStatus === status))
                })
        },
        nextMonth(){
            if(this.currMonth !== 11)this.currMonth += 1;
            else {
                this.currMonth = 0;
                this.currYear +=1;     
            }
            this.loadDatas()
        },
        prevMonth(){
            if(this.currMonth !== 0)this.currMonth -= 1;
            else {
                this.currMonth = 11;
                this.currYear -=1;     
            }            
            this.loadDatas()
        },
        nextYear() {
            this.currYear += 1;
            this.loadDatas()
        },
        prevYear() {
            this.currYear -= 1;
            this.loadDatas()
        }
    }
}
</script>