<template>
    <div>
        <div class="user-tabs">
            <div class="tab-view">
                <div class="page-sub-title">Estimates</div>
                <div class="tab" v-for="(tabData, tabIndex) in tabsData" :key="tabIndex">
                    <div class="tab-content" v-show="activeIndex === tabIndex">
                        <el-card>
                            <slot :name="tabIndex"></slot>
                        </el-card>             
                    </div>
                </div> 
            </div>
            <div class="tabs-controler-container" v-if="tabsData.length > 1">
                <div class="page-sub-title">View Options</div>
                <div class="tabs-controler" >
                    <div class="tab-btn" v-for="tab,index in tabsData" :key="index" :class="index === activeIndex ? 'active' : ''" @click="setActiveIndex(index)">
                        <div class="tab-number">{{index+1}}</div>
                        {{tab.name}}
                    </div>
                </div>
            </div>
        </div>
        <ServiceFee v-if="this.vendorFeePercentage > 0" :percent="this.vendorFeePercentage" />
    </div>
</template>

<style scoped lang="sass">
.user-tabs
    max-width: 100%
    display: flex
    flex-direction: row
    gap: 2.25rem
.tab-view
    flex: 1    
.arrow
    width: 2.625rem
    display: flex
    justify-content: center
    align-items: center
    cursor: pointer
    background-color: white
    box-shadow: 0 0 5px 10px white
.tabs-controler
    // display: flex
    // flex-direction: row
    width: 100%
    margin-bottom: 2rem
    max-width: 100%
    overflow: hidden
    border-radius: 1rem
    background: white
    height: fit-content
    &-iner
        width: 100%
        display: flex
        overflow: hidden
        transition: 2s all ease
    .tab-title
        display: flex
        gap: .5rem
        padding: 1rem .875rem
        background-color: white
        cursor: pointer
        width: 100%
        align-items: center
        justify-content: center
        min-width: fit-content
    .tab-title:first-child 
    //    border-radius: 1rem 0px 0px 1rem
    .tab-title:last-child
    //    border-radius: 0rem 1rem 1rem 0rem
    .active
       background: #E9F2EC    
       color: #2bb673
.tab-btn
    padding: 18px
    display: flex
    gap: 8px
    cursor: pointer
.tabs-controler-container
    flex: 0.4
.page-sub-title
    font-weight: 500
    font-size: 1rem
    letter-spacing: -0.03em
    color: #A2A29D
    margin-top: 32px
    margin-bottom: 16px   

</style>

<script>
import ServiceFee from './ServiceFee.vue'
import _ from 'underscore'

export default {
    data() {
        return {
            activeIndex: 0,
            currentScrollPosition: 0,
            holdLeft: false,
            holdRight: false,
            vendorFeePercentage: 0
        }
    },
    watch: {
      activeIndex() {
        this.changeFeePercentage()
      }
    },
    props: {
        tabsData: {
           require: true
        }
    },
    components: {
        ServiceFee

    },
    methods: {
        setActiveIndex(index) {
            this.activeIndex = index
        },

        scrollLeft() {
            if(this.currentScrollPosition > this.$refs.scrollBar.clientWidth)   this.currentScrollPosition = this.$refs.scrollBar.clientWidth
            this.currentScrollPosition > 0 ? this.currentScrollPosition-= 128 : this.currentScrollPosition = 0
            this.$refs.scrollBar.scroll(this.currentScrollPosition, 0)
        },

        scrollRight() {
                this.currentScrollPosition+= 128
                this.$refs.scrollBar.scroll(this.currentScrollPosition, 0)
        },

        changeFeePercentage() {
            this.vendorFeePercentage = this.tabsData[this.activeIndex]['vendor_fee_percentage']
        }
    },
    mounted() {
        this.changeFeePercentage();
    }
}
</script>
 