<template>
 <div class="request-tag" :class="toClassName(friendlyName)">
  {{ucFirst(friendlyName)}}
 </div>
</template>

<script>
import StausTag from "./StatusTag.vue"
  const mixins = [StausTag]
  const methods = {
    ucFirst(str) {
        const lowerCaseString = str.toLowerCase()
        return lowerCaseString[0].toUpperCase() + lowerCaseString.slice(1)
    },
    toClassName(str) {
        const lowerCaseString = str.toLowerCase()
        return lowerCaseString.replace(/ /g, '-').trim()
    }
  }
  export default { mixins, methods }
</script>

<style lang="sass">
.request-tag
  font-size: .875rem
  border-radius: .5rem
  padding: .25rem .375rem
  min-width: fit-content
  white-space: nowrap
  display: flex
  justify-content: center

.waiting-for-estimates
  background: #FEF9F3
  color: #D18111

.estimates-available
  color: #125F43
  background: #F4F9F5

.estimate-ordered, .pending-changes
  color: #125F43
  background: #F4F9F5

.cancelled
  background: #FEF3F2
  color: #D92D20

.closed
  color: #667085
  background: #F2F4F7

.submitted
  color: #D18111
  background: #FEF9F3

.ordered, .received, .assigned
  color: #125F43
  background: #F4F9F5

</style>
