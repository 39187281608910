export function filteredDropdown(dataName, filterMethod) {
  const dataNameWithUpperFirst = dataName.replace(/^./, dataName[0].toUpperCase())
  return {
    watch: {
      [`${dataName}`]: {
        handler(newAllOptions) {
          this[`filtered${dataNameWithUpperFirst}`] = [...newAllOptions]
        },
        immediate: true
      },
    },
    methods: {
      [`filtering${dataNameWithUpperFirst}`](input) {
        this[`filtered${dataNameWithUpperFirst}`] = this[`${dataName}`].filter((data) => input.split(' ').some((part) => filterMethod(part)(data)) )
      },
      [`on${dataNameWithUpperFirst}Blur`]() {
        this[`filtered${dataNameWithUpperFirst}`] = [...this[dataName]]
      }
    }
  }
}