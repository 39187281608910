<template lang='pug'>
el-card(v-loading='account == null')
  //- div(slot='header')
  //-   i.mat-icon person
  //-   | Contact
  contact-builder(
    :display-save='true', :account='account', @saveClick='onSave',
    :baseData='contactBase', v-if='backendAccount != null', :loading='loading', :hideCancel='true' :hideSalutation='false'
  )
</template>

<script>
  import Vue from 'vue'
  import ContactBuilder from './UserContactBuilder.vue'
  import _ from 'underscore'

  function data(){
    return {
      loading: false,
      account: Object.assign({}, this.backendAccount)
    }
  }

  function updateAccount(){
    this.loading = true
    this.$simplecater.put('/account/contact', this.account).then((r)=>{
      this.$message("The account has been updated")
      this.$emit('change', Object.assign({},this.account))
      this.loading = false
    }).catch(()=>{
      this.$message.error("Failed to save the update")
      this.loading = false
    })
  }

  function onSave(contact){
    console.log("SAVING ACCOUNT CONTACT", contact)
    const contactInfo = {
      contact_first_name: contact.first_name,
      contact_last_name: contact.last_name,
      contact_phone_number: contact.phone_number,
      contact_salutation: contact.salutation,
      contact_email: contact.email
    }
    this.account = Object.assign({},this.account, contactInfo)
    this.updateAccount()
  }

  const methods = {
    onSave, updateAccount
  }

  const props = {
    labelWidth: { default: Vue.$labelWidth },
    labelPosition: { default: Vue.$defaultlabelPosition },
    // displaySave: { default: false },
    backendAccount: { required: true }
  }

  const computed = {
    contactBase: function(){
      return {
        first_name: this.account.contact_first_name,
        last_name: this.account.contact_last_name,
        salutation: this.account.contact_salutation,
        email: this.account.contact_email,
        phone_number: this.account.contact_phone_number
      }
    }
  }

  const watch = {
    backendAccount: function(newVal){
      this.account = Object.assign({}, newVal)
    }
  }

  const components = { ContactBuilder }

  export default { props, data, methods, components, watch, computed }
</script>
