<template lang='pug'>
.main-container

  .view-content

    el-card
      div(slot='header')
        i.mat-icon toys
        | Products
        el-button(type="primary", @click="newProduct", style='float: right')
          i.mat-icon add_circle
          | Add product
      el-row(:gutter='10')
        el-col(:span='9')
          el-input(v-model='sorting' prefix-icon="el-icon-search" placeholder="Search" )
      el-table(:data='products', v-loading='products == null')
        template(slot='empty')
          | Retrieving
        el-table-column(prop='name', label='Name')
        el-table-column(prop='vendor_name', label='Vendor')
        el-table-column(prop='serves', label='Serves')
        el-table-column(label='Unit price')
          template(slot-scope='scope')
            | {{$formatCents(scope.row.sale_price_cents)}}
        el-table-column(label='Actions')
          template(slot-scope='scope')
            el-button(type='text', size='small', @click='editProduct(scope.row)') Edit
            el-button(type='text', size='small', @click='deleteProduct(scope.row, scope.$index)') Delete
  
      el-col()
        el-pagination( 
          v-if="pagination.pages > 1"
          @current-change="onPaginationChange"
          :page-size="pagination.per_page", 
          :pager-count="5", 
          layout="prev, pager, next, jumper", 
          :total="pagination.total") 
  router-view
</template>

<script>
  import Toolbar from '../layout/Toolbar.vue'
  import _ from 'underscore'

  function data ( ) {
    return {
      products: null,
      sorting: '',
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null,
      }
    }
  }

  function onPaginationChange(current_page){
    this.getProducts(current_page);
  }

  function newProduct ( ) {
    this.$router.push('/staff/products/new')
  }

  function getProducts (current_page) {
    this.products && (this.products = null)
    let reqParams = (current_page ?{params:{ page:current_page}}:{params:{}});
    if(this.sorting){
      this.$isNumber(this.sorting)?reqParams.params.id=this.sorting:reqParams.params.q=this.sorting
    }
    return this.$simplecater.get('/products',reqParams).then((r)=> {
      this.products = _.chain(r.data.data).value()
      this.pagination = r.data.pagination;
    })
  }

  function created ( ) {
    this.getProducts()
  }

  function editProduct ( x ) {
    console.log(x)
    this.$router.push('/staff/products/edit/' + x.id)
  }

  function deleteProduct ( x, idx ) {
    if ( confirm('Are you sure?') ) {
      return this.$simplecater.delete('/products/' + x.id).then( ( r )=> {
        let newProducts = _.map(this.products, _.id)
        newProducts.splice(idx, 1)
        this.products = newProducts
      }).catch( ( err ) => {
        this.$message.error('Failed to delete!')
      })
    }
  }

  const watch = {
    sorting: function(){
      this.getProducts();
    },
    $route: function(newRoute) {
      if (newRoute.path === '/staff/products') {
        this.getProducts(this.pagination.current);
      }
    }
  };

  export default {
    methods: { newProduct, onPaginationChange, getProducts, editProduct, deleteProduct },
    components: { Toolbar, },
    created,
    data,
    watch
  }
</script>

<style lang="sass" scoped>
.el-pagination
  margin: 20px 0
</style>