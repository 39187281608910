<style lang="sass">

.side-menu
  position: fixed
  top: $navbarHeight
  bottom: 0
  left: 0
  width: $sideMenuWidth
  max-height: 100vh
  overflow-y: auto
  background: $colorMenu
  .menu-section
    padding-top: 0.5rem
    padding-bottom: 0.2rem
    &:first-child
      padding-top: 1.5rem
    &:last-child
      padding-bottom: 1.5rem
    .section-name
      color: #E5DC84
      text-transform: uppercase
      padding: 0.8rem 2.3rem
      font-size: 0.7rem
      font-weight: 600
    .side-menu-item
      display: block
      background: transparent
      color: white
      padding: 0.9rem 1rem
      transition: background 0.1s ease-in-out, color 0.1s ease-in-out
      font-size: 0.85rem
      font-weight: 300
      color: #f4f4f4
      i
        margin-right: 0.5em
      &:visited
        background: transparent
      &:hover
        background: rgba(white,0.15)
        color: white
      &.router-link-active
        background: rgba(black, 0.2)
        font-weight: 400

.page-content
  padding-top: $navbarHeight
  padding-left: $sideMenuWidth
  .main-container
    width: 100%
    .view-content
      padding: 1rem

.user-sidebar-layout
  padding: 1.5rem
  background-color: #FAFAF5
  width: fit-content
  border-right: 1px solid #E8E8E4
  .menu-section
    .side-menu-item
      font-weight: 500
      font-size: 16px
      line-height: 24px
      letter-spacing: -0.03em
      font-family: 'Inter'
      color: #1F1F18
      border-radius: 16px
      display: flex
      gap: 12px
      span
        align-self: start
      &.router-link-active
        background: #FFFFFF
        box-shadow: 0px 4px 16px -4px rgba(31, 31, 24, 0.03)
      &:hover
        background: white
        color: #1F1F18  

.user-layout
  .page-content
    padding-top: 0
    padding-left: 280px  

</style>

<template>
<div :class="role + '-layout'">
  <div :class='"side-menu " + role + "-sidebar-layout"'>
    <slot>Navigation slot</slot>
  </div>
  <div class="page-content">
    <router-view :isList="isList" @changeList="changeList"></router-view>
  </div>
</div>
</template>

<script>
import auth from '@/auth';
import roleDetection from '@/mixins/roleDetection.js'

  export default {
    data() {
      const list = localStorage.getItem('list')
      let isList = true;
      if(auth.validCustomerSession() && !!list) {
        const listData = JSON.parse(list)
        isList = listData.isList
      } else if(auth.validCustomerSession()) {
        localStorage.setItem('list', JSON.stringify({ isList: true }))
      }
      return { isList }
    },

    mixins: [roleDetection],

    mounted() {
      this.getRole()
      console.log(this.role)
    },

    methods: {
      changeList() {
        localStorage.setItem('list', JSON.stringify({ isList: !this.isList }))
        this.isList = !this.isList
      }
    }
  }
</script>
