<style scoped lang="sass">

.toolbar
  display: flex
  width: calc(100% - #{$sideMenuWidth})
  flex-direction: rows
  align-items: center
  position: fixed
  top: $navbarHeight
  left: $sideMenuWidth
  right: 0
  background: #f6f6f6
  border-bottom: 1px solid rgba(black, 0.05)
  height: $toolbarHeight
  padding-left: 1rem
  padding-right: 1rem
  z-index: 450
  box-shadow: 0 0 6px 0 rgba(black, 0.1)
  .toolbar-left
    text-align: left
    .toolbar-title
      font-weight: 600
      font-size: 1.2rem
      color: #333
  .toolbar-right
    flex: 1
    text-align: right
</style>

<template lang="pug">
  .toolbar
    .toolbar-left
      .toolbar-title {{ title }}
    .toolbar-right
      slot
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
