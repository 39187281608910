<template lang="pug">
div
  //- el-menu(:router='true', :default-active='$route.fullPath')
  .menu-section(v-for='x in menuItems')
    .section-name {{ x.name }}
    router-link.side-menu-item(v-for='i in x.items' :to='i.to' :key='i.to')
      i.mat-icon {{i.icon}}
      span {{ i.name }}
</template>

<script>
export default {
  data ( ) {
    return {
      menuItems: [
        {
          name: '',
          items: [
            { to: '/staff/overview', name: 'Overview', icon: 'all_out' }
          ],
        },
        {
          name: 'Sales',
          items: [
            { to: '/staff/requests', name: 'Requests', icon: 'assignment' },
            { to: '/staff/orders', name: 'Orders', icon: 'assignment_turned_in' },
            { to: '/staff/invoices', name: 'Invoices', icon: 'receipt' },
            { to: '/staff/deliveries', name: 'Deliveries', icon: 'motorcycle' },
            { to: '/staff/customers', name: 'Customers', icon: 'person' },
          ],
        },
        {
          name: 'Catalog',
          items: [
            { to: '/staff/vendors', name: 'Vendors', icon: 'store' },
            { to: '/staff/products', name: 'Products', icon: 'toys' },
            { to: '/staff/cuisines', name: 'Cuisines', icon: 'kitchen' },
          ],
        },
      ]
    }
  }
}
</script>
