<style scoped lang="sass">
.empty-block
    display: flex
    justify-content: center
    align-items: center
    flex-direction: column
    width: 100%
    min-height: 504px
    padding-bottom: 2.625rem
    background: #F1F1EC
    padding: 56px 98px
    border-radius: 1rem
    gap: 2.5rem
    margin-top: 12px
.title
    color: #1F1F18
    font-size: 1rem
.top-title
    width: 100%
    text-align: center
    margin-bottom: 1rem
.empty-img
  height: 200px    
</style>
<template>
    <div class="empty-block">
      <div class="title top-title" v-if="topTitle">
        {{ title }}
      </div>
      <img src="../../assets/SC.icon.white.svg" alt="" class="empty-img" />
      <div class="title" v-if="!topTitle">
        {{ title }}
      </div>
    </div>
</template>
<script>
export default {
  props: {
    imagePath: {
      require: true,
    },
    width: {
      require: false,
    },
    title: {
      require: false,
    },
    topTitle: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getImgUrl() {
      let images = require.context("../../assets/", false, /\.png$/);
      return images("./" + this.imagePath + ".png");
    },
  },
};
</script>