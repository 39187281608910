<template>
    <button class="button-default" @click="$emit('onClick')">
        {{title}}
        <slot></slot>
    </button>
</template>

<style lang="sass" scoped>
.button-default
  height: 3rem
  max-height: 2.5rem
  background: #125F43
  gap: 8px
  display: flex
  align-items: center
  padding: .75rem .75rem .75rem 1rem
  color: white
  border-radius: 12px
  border: none
  font-weight: 500
  font-size: 1rem
  cursor: pointer
</style>

<script>
export default {
  props: {
    title: {
        type: String,
        require: true,
    }
  }
};
</script>
