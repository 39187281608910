<template lang='pug'>
div
  el-row(:gutter='$rowGutter')
    el-col(:span='6')
      el-card
        div(slot='header')
          i.mat-icon place
          | Delivery
        .delivery-contact(v-if='contact !== null')
          .name {{contact.salutation}} {{contact.first_name}} {{contact.last_name}}
          .phone
            i.mat-icon phone
            |  {{ modifiedPhoneNumber(contact.phone_number) }}
        .delivery-address(v-if='address !== null')
          div {{address.address_line_1}}
          div(v-if='!request.suite') {{address.address_line_2}}
          div {{address.city}}, {{address.state}} {{address.zip_code}}
        template(v-if='request!== null && request.suite') 
              div  
                | {{request.suite}} 

    el-col(:span='6')
      el-card
        div(slot='header')
          i.mat-icon event_note
          | Notes
        template(v-if='contact !== null')
          .no-notes(v-if='!request.notes') N/A
          blockquote.classic {{ request.notes }}

    el-col(:span='12')
      el-card
        div(slot='header')
          i.mat-icon playlist_add_check
          | Preferences
        template(v-if='contact !== null')
          preferences-summary(:preferences='request.preferences')

  el-row(:gutter='$rowGutter', style='margin-top: 2rem')
    el-col(:span='24')

      //- Request steps
      el-steps(:active="requestSteps[request.status] || 0", finish-status="success", align-center)
        el-step(title="Create Estimates")
        el-step(title="Review Estimates")
        el-step(title="Submit Order")
  el-row(:gutter='$rowGutter', style='padding-top: 1rem')

    el-col(:span='24')
      //- Order
      el-card(v-if="request.status === 'ESTIMATE_ORDERED' && request.order")
        .already-ordered This request was submitted on
          strong  {{ $formatDate(request.order.created_at) }}
          br
          el-button(type="primary", @click="viewOrder()") View order

      //- Estimates
      el-card(v-else)
        div(slot='header')
          i.mat-icon assignment
          span Estimates
        template(v-if='request.estimates != null')
          .secondary(v-if="request.status === 'WAITING_FOR_ESTIMATES'")
            div
              | We are still working on your customized estimates.
            div
              | Once they're available we will send a notification.
          el-tabs(
            v-if="request.status === 'ESTIMATES_AVAILABLE' && request.estimates.length > 0",
            v-model='activeIndex', ref='request.estimates', type='card'
          )

            el-tab-pane(
              :label='estimate.name', :name="index.toString()",
              v-for='estimate,index in request.estimates', :key='index')

              estimate-viewer(
                @change='onEstimateChange',
                :remote-id='estimate.id',
                :index='index',
                :request-id='request.id',
                :people='request.people',
                :request='request',
                :address='address',
                v-if='activeIndex == index.toString()'
              )
</template>



<script>

  import EstimateViewer from './EstimateViewer.vue'
  import PreferencesSummary from '../../staff/blocks/PreferencesSummary.vue'
  import Order from './Order.vue'
  import _ from 'underscore'
  import modifyPhoneNumber from '@/mixins/modifiedPhoneNumber'

  const mixins = [modifyPhoneNumber];
  
  function data(){
    return {
      activeIndex: null,
      contact: null,
      address: null,
      contactError: null,
      addressError: null,
      requestSteps: {
        WAITING_FOR_ESTIMATES: 0,
        ESTIMATES_AVAILABLE: 1,
        UPDATED_BY_CUSTOMER: 1,
        ESTIMATE_ORDERED: 3,
      },
    }
  }

  function loadContact(){
    const target = `/contacts/${this.request.contact_id}`
    // TODO catch
    return this.$simplecater.get(target).then((r)=>{
      this.contact = r.data.data
    }).catch(()=>{
      this.contactError = 'Temporarily unavailable'
    })
  }

  function loadAddress(){
    const target = `/addresses/${this.request.address_id}`
    // TODO catch
    return this.$simplecater.get(target).then((r)=>{
      this.address = r.data.data
    }).catch(()=>{
      this.addressError = 'Temporarily unavailable'
    })
  }

  function loadRequest(){
    const target = `/requests/${this.request.id}`
    // TODO catch
    return this.$simplecater.get(target).then((r)=>{
      let request = r.data.data
      request.estimates = _.sortBy(request.estimates, 'id')
      this.request = request
    }).catch(()=>{
      this.$message.error("Failed to load the request")
    })
  }

  function onEstimateChange(index, data){
    console.log("RECEIVING NEW ESTIMATE", index, data)
    let es = _.map(this.request.estimates, _.id)
    es.splice(index, 1, data)
    this.request = _.extend({}, this.request, {
      estimates: es
    })
  }

  function viewOrder ( ) {
    this.$router.push('/user/orders/' + this.request.order.id)
  }

  function created(){
    this.loadContact()
    this.loadAddress()
    // this.loadRequest()
  }

  // function publishEstimates(){
  //   const target = `/requests/${this.request.id}/publish_estimates`
  //   this.$simplecater.post(target).then((r)=>{
  //     this.$message("The estimates are now visible by the customer")
  //     this.$router.push('/staff/requests')
  //   })
  // }

  const watch = {
    '$route' (to, from) {
      console.log("MOVING!")
    }
  }

  const props = ['request']

  const methods = {
    created, loadContact, loadAddress, loadRequest, onEstimateChange, viewOrder
    // appendEstimate,
    // removeEstimate, , publishEstimates
  }

  const components = { EstimateViewer, PreferencesSummary, Order }

  const computed = {
    loadingAddress(){
      return this.address === null && this.addressError === null
    },
    loadingContact(){
      return this.contact === null && this.contactError === null
    }
  }

  export default { created, data, methods, props, components, computed, watch, mixins }
</script>

<style scoped lang="sass">
  .el-row
    display: flex
    flex-direction: rows
    [class^="el-col"]
      & > div
        height: 100%
  .secondary
    padding: 2rem 0
    color: #666
    text-align: center
  .delivery-contact
    .name
      font-weight: 600
  .delivery-address
    font-size: 0.9em
    margin-top: 0.5rem
  .no-notes
    font-style: italic
    color: #999
  .already-ordered
    color: #999
    font-size: 0.9em
    text-align: center
    padding: 2rem 0
    .el-button
      margin-top: 1rem
  .classic
    white-space: normal
</style>
