<style scoped lang="sass">
.logo-container
  background: #fff
  align-self: center
  height: 3.5rem
  margin-bottom: 2rem
  margin-bottom: 60px
  display: flex
  align-items: center
  justify-content: center
  img
    height: 500%
.reset-form-card
  width: 100%
  height: 100%
  border-radius: 24px
  display: flex
  justify-content: center
  padding-bottom: 40px
.reset-title
  margin-bottom: 12px 
  font-weight: 500
  font-size: 28px
.reset-form
  min-width: 400px
  max-width: 420px
.email-input
  margin-bottom: 16px
.el-form
  margin-top: 16px
.check-email-title
  margin-top: 50px
.back-btn
  font-weight: 500
  font-size: 14px
  line-height: 20px
  letter-spacing: -0.03em
  color: #125F43
  margin-top: 24px
  cursor: pointer
  
.reset-form
  display: flex
  flex-direction: column
  justify-content: center
  height: 100%
</style>

<template>
    <div class="el-card reset-form-card">
    <div class="login-form-wrapper">
        <div class="reset-form" v-if="!resetSended">
          <div class="logo-container"><img src="../../assets/logo-new-2.svg" /></div>
            <h2 class="reset-title">Reset password</h2>
            <div class="description-text">No worries, we'll send you reset instructions.</div>
            <el-form @keyup.enter.native="submit">
                <el-form-item label="Email">
                    <el-input class="email-input" v-model="email" :autofocus="true"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button class="full-width-button" type="primary" @click="submit">Reset</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="reset-form" v-else>
          <div class="logo-container"><img src="../../assets/logo.png" /></div>
           <h2 class="reset-title check-email-title">Check your email</h2>
           <div class="description-text">We just sent you an email to <nobr>{{email}}</nobr>
                with a link to reset your password
            </div>
            <div class="back-btn" @click="backToLogin">Back</div>
        </div>
    </div>
    </div>
</template>

<script>
import auth from '../../auth.js'

export default {
  data ( ) {
    return {
      email: '',
      resetSended: false
    }
  },
  methods: {
    submit() {
        auth.changePassword(this.email).then(()=>{
        this.resetSended = true
      }).catch((e)=>{
        console.log("Failed to reset password",e)
        this.$message.error("Failed to request the reset link")
      })
      },

    backToLogin() {
        this.$emit('backClick')
    }  
    }
}
</script>
