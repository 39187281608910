<style scoped lang="sass">
.page-title
    margin-bottom: 1.75rem 
.request-header
    display: flex
    justify-content: space-between
    align-items: flex-start
    &-req-info
      max-width: fit-content
      margin-top: 26px
    .req-number
      font-size: .875rem
      color: #A2A29D
      margin-left: 7px
      margin-bottom: 8px  
</style>
<template>
<div class="main-container">
<div class="view-content">
    <div class="request-header" v-if="order">
        <h2 class="page-title">{{order.request_name}}</h2>
        <div class="request-header-req-info">
            <div class="req-number">Order #{{order.id}}</div>
            <status-tag :status="order.status"></status-tag>
        </div>
    </div>
   <user-order v-if='order && request && requestAddress && contact' :order='order' :request='request' :request-address='requestAddress' :contact='contact' :contWidth='width'/>
</div>
</div>
</template>
<script>
import OrderDetail from './OrderDetail.vue'
import UserOrder from './blocks/UserOrder.vue'
import StatusTag from '@/common/UserStatusTag.vue'
export default {
    mixins: [OrderDetail],
    components: { UserOrder, StatusTag },
} 
</script>
