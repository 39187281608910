import { render, staticRenderFns } from "./DefaultButton.vue?vue&type=template&id=36b6461a&scoped=true&"
import script from "./DefaultButton.vue?vue&type=script&lang=js&"
export * from "./DefaultButton.vue?vue&type=script&lang=js&"
import style0 from "./DefaultButton.vue?vue&type=style&index=0&id=36b6461a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36b6461a",
  null
  
)

export default component.exports