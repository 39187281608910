<style scoped lang="sass">
  .products-edit-table
    width: 100%
    display: flex
    padding: 20px 10px
    gap: 40px
    border-bottom: 1px solid #EBEEF5

  .products-edit-table__item
    width: 16%  

  .products-edit-footer
    padding: 20px 10px
    display: flex
    justify-content: right

  .fixed
    position: fixed
    top: 0
    background: white
    z-index: 2

  .table-content
    margin-top: 50px  

  .close-btn
    position: fixed

  .products-header
    gap: 34px  

  .products-edit-footer
    position: fixed
    bottom: 0
    width: 100vw
    background: white
    z-index: 2
    border-top: 1px solid #EBEEF5
    padding-right: 45px
</style>

<template>
<div>
<el-dialog :visible.sync="show" :before-close="handleClose" width="90vw" fullscreen>
<div v-loading="resultProductList === null" v-if="resultProductList">
  <div class="products-edit-table fixed products-header">
    <div class="products-edit-table__item">Name</div>
    <div class="products-edit-table__item">Description</div>
    <div class="products-edit-table__item">Servers</div>
    <div class="products-edit-table__item">Unit price</div>
    <div class="products-edit-table__item">Delivery notes</div>
    <button type="button" aria-label="Close" class="el-dialog__headerbtn close-btn" @click="handleClose">
      <i class="el-dialog__close el-icon el-icon-close"></i>
    </button>
  </div>
  <div v-for="(product, prodIndex) in resultProductList" label="L" :name="`${product.id}`" :key="product.id">
  <el-form :model="product" :ref="`productForm${product.id}`">  
  <div class="products-edit-table table-content">
    <div class="products-edit-table__item">
        <el-form-item label="" prop="description">
          <el-input v-model="product.name" max="250"></el-input>
      </el-form-item>
    </div>
    <div class="products-edit-table__item">
      <el-form-item label="" prop="description">
          <el-input type="textarea" v-model="product.description" max="250" :autosize="{ minRows: 3, maxRows: 7 }"></el-input>
      </el-form-item>
    </div>
    <div class="products-edit-table__item">
      <el-form-item label="" prop="serves">
          <el-input-number v-model="product.serves" :min="1"></el-input-number>
      </el-form-item>
    </div>
    <div class="products-edit-table__item">
      <el-form-item label=" " prop="base_price_dollars">
          <el-input v-model="product.base_price_dollars" @input="updatePriceValue(prodIndex, product.base_price_dollars)">
            <template slot="prepend">$</template>
          </el-input>
      </el-form-item>
    </div>
    <div class="products-edit-table__item">
      <el-form-item label=" " prop="notes">
          <el-input type="textarea" v-model="product.notes" max="250" :autosize="{ minRows: 3, maxRows: 7 }"></el-input>
      </el-form-item> 
    </div>
  </div>
  </el-form>
  </div>
  <div class="products-edit-footer">
    <el-button type="primary" @click="submitForm">Save</el-button>
  </div>
</div>  
</el-dialog>   
</div>
</template>

<script>

  function data() {
    return {
      activeProduct: null,
      resultProductList: null,
      oldProductList: null
    }
  }

  function handleClose() {
    this.$emit('close')
  }

  function getData() {
    Promise.all(this.productList.map(({ id }) => this.$simplecater.get(`/products/${id}`))).then((r) => {
      this.resultProductList = r.map(({ data: { data }}) => ({ ...data, base_price_dollars: data.base_price_cents/100 }));
      this.oldProductList = r.map(({ data: { data }}) => ({ ...data, base_price_dollars: data.base_price_cents/100 }));
    })
  }

  function isEqualProducts(newProduct, oldProduct) {
    return Object.keys(newProduct).every((key) => newProduct[key] === oldProduct[key])
  }

  function updatePriceValue (prodIndex, val)  {
    const updatedVal = val.replace(/,/, '.')
    this.resultProductList[prodIndex].base_price_dollars = updatedVal
  }


  function submitForm() {
    let body = []
    for(let [index, newProduct] of this.resultProductList.entries()) {
      if(!this.isEqualProducts(newProduct, this.oldProductList[index])) {
        const { id, name, description, serves, notes, base_price_dollars } = newProduct
        body.push({
          id,
          name,
          description,
          serves,
          notes,
          base_price_cents: Math.ceil(base_price_dollars*100)
        })
      }
    }
    if(body.length > 0) {
      this.$simplecater.put(`/vendors/${this.vendorId}/products/bulk-update`, { products: body }).then(() => {
        this.$message.success(`Product${body.length > 1 ? 's' : ''} changed successfully`)
        this.$emit('close', { successfull: true })
      }).catch(() => (this.$message.error('Something gone wrong')))
    } else {
      this.$emit('close')
    }
  }

  const watch = {
    show(newV) {
      if(newV) 
        this.getData()
      else {
        this.resultProductList = null;
        this.oldProductList = null
      }
    },
    resultProductList(newV) {
      console.log(newV)
      newV && (this.activeProduct = `${newV[0].id}`)
    }
  }

  const methods = {
    handleClose, 
    getData,
    submitForm,
    isEqualProducts,
    updatePriceValue
  }

  const props = {
    show: { type: Boolean },
    productList: { required: true },
    vendorId: { type: Number }
  }

  export default { data, props, methods, watch }
</script>