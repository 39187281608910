<style scoped lang="sass">
.drawer-title
  color: black
  font-size: 1.2rem
  font-weight: 500
  border: none
  margin-bottom: 0
.drawer-description
  color: #70706B
  font-size:  .75rem
  margin-bottom: 1.5rem  
</style>
<template>
<drawer :dismissRoute="'/user/address_book/'">
    <div class="drawer-title">New Contact</div>
    <div class="drawer-description">Our friendly team would love to hear from you!</div>
    <contact-builder :displaySave="true" @saveClick="createContact" :loading="loading"></contact-builder>
    </div>
</drawer>
</template>


<script>
import ContactBuilder from './blocks/UserContactBuilder.vue'
import ContactsNew from './ContactsNew.vue'

export default { 
    components: { ContactBuilder },
    mixins: [ContactsNew]
 }
</script>
