<template>
    <div class="user-estimates">
        <div v-if="requestStatus === 'WAITING_FOR_ESTIMATES' || requestStatus === 'PENDING_CHANGES'">
            <div class="page-sub-title">Estimates</div>
            <el-card>
                <div class="estimate-in-progress">
                    <svg width="127" height="126" viewBox="0 0 127 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="63.5" cy="63" r="63" fill="#F4F9F5"/>
                        <path d="M67 61.25H56.5M60 68.25H56.5M70.5 54.25H56.5M77.5 60.375V53.9C77.5 50.9597 77.5 49.4896 76.9278 48.3665C76.4244 47.3787 75.6213 46.5756 74.6335 46.0722C73.5104 45.5 72.0403 45.5 69.1 45.5H57.9C54.9597 45.5 53.4896 45.5 52.3665 46.0722C51.3787 46.5756 50.5756 47.3787 50.0722 48.3665C49.5 49.4896 49.5 50.9597 49.5 53.9V72.1C49.5 75.0403 49.5 76.5104 50.0722 77.6334C50.5756 78.6213 51.3787 79.4244 52.3665 79.9278C53.4896 80.5 54.9597 80.5 57.9 80.5H62.625M81 80.5L78.375 77.875M80.125 73.5C80.125 76.8827 77.3827 79.625 74 79.625C70.6173 79.625 67.875 76.8827 67.875 73.5C67.875 70.1173 70.6173 67.375 74 67.375C77.3827 67.375 80.125 70.1173 80.125 73.5Z" stroke="#125F43" stroke-width="3.33" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div class="estimate-in-progress-title">
                        Estimates in Progress
                    </div>
                    <div class="estimate-in-progress-description">
                        We are still working on your customized estimates. Once they're available we will send a notification.
                    </div>
                </div>
            </el-card>
        </div>
        <div v-else-if="requestStatus === 'ESTIMATES_AVAILABLE'">
            <slot></slot>
        </div>
        <div v-else>
           <div class="page-sub-title">Estimates</div>
           <el-card>
                <div class="estimate-in-progress">
                    <svg width="127" height="126" viewBox="0 0 127 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="63.5" cy="63" r="63" fill="#F4F9F5"/>
                        <path d="M67 61.25H56.5M60 68.25H56.5M70.5 54.25H56.5M77.5 60.375V53.9C77.5 50.9597 77.5 49.4896 76.9278 48.3665C76.4244 47.3787 75.6213 46.5756 74.6335 46.0722C73.5104 45.5 72.0403 45.5 69.1 45.5H57.9C54.9597 45.5 53.4896 45.5 52.3665 46.0722C51.3787 46.5756 50.5756 47.3787 50.0722 48.3665C49.5 49.4896 49.5 50.9597 49.5 53.9V72.1C49.5 75.0403 49.5 76.5104 50.0722 77.6334C50.5756 78.6213 51.3787 79.4244 52.3665 79.9278C53.4896 80.5 54.9597 80.5 57.9 80.5H62.625M81 80.5L78.375 77.875M80.125 73.5C80.125 76.8827 77.3827 79.625 74 79.625C70.6173 79.625 67.875 76.8827 67.875 73.5C67.875 70.1173 70.6173 67.375 74 67.375C77.3827 67.375 80.125 70.1173 80.125 73.5Z" stroke="#125F43" stroke-width="3.33" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    <div class="estimate-in-progress-title">
                        This request was submitted  {{ $formatDate(createAt) === "Invalid date" ? "earlier" : "on " + $formatDate(createAt) }}
                    </div>
                    <user-default-button :borderColor="'#125F43'" btnColor="#125F43" @onClick="$emit('onPushOrderClick')">View order</user-default-button>
                </div>
            </el-card> 
        </div>
    </div>
</template>

<style scoped lang="sass">
.user-estimates
    height: 100%
.estimate-in-progress
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
    height: 100%
    min-height: 27.25rem
    &-title
        font-size: 1.5rem
        margin: 2.25rem 0 1rem 0
    &-description
        font-size: .875rem
        color: #70706B
        max-width: 27.5rem
        text-align: center
.page-sub-title
    font-weight: 500
    font-size: 1rem
    letter-spacing: -0.03em
    color: #A2A29D
    margin-top: 32px
    margin-bottom: 16px        
</style>

<script>
import UserDefaultButton from './UserDefaultButton.vue'
  export default {
  components: { UserDefaultButton },
    props: {
      requestStatus: {
        require: true
      },
      createAt: {
        require: true
      }
    },

   }
</script>
 