<style scoped lang="sass">
.user-reset-password
  display: flex
  flex-direction: column
  align-items: center
  .lock-icon
    height: 6rem
    width: 6rem
    border-radius: 100%
    display: flex
    align-items: center
    justify-content: center
    background: #F5F2D0
  .reset-desc
    text-align: center
    margin-top: 1.5rem
    margin-bottom: 2rem
  .reset-link
    cursor: pointer
    display: flex
    gap: .625rem
    justify-content: center
    align-items: center
    background: #F7FDF9
    color: #125F43
    height: 3rem
    width: 100%
    border-radius: .75rem
</style>

<template>
<el-card>
    <div class="user-reset-password">
      <div class="lock-icon">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.667 16.6667V13.3333C11.667 8.73096 15.398 5 20.0003 5C23.4175 5 26.3543 7.05683 27.6403 10M20.0003 24.1667V27.5M14.667 35H25.3337C28.1339 35 29.5341 35 30.6036 34.455C31.5444 33.9757 32.3093 33.2108 32.7887 32.27C33.3337 31.2004 33.3337 29.8003 33.3337 27V24.6667C33.3337 21.8664 33.3337 20.4663 32.7887 19.3967C32.3093 18.4559 31.5444 17.691 30.6036 17.2116C29.5341 16.6667 28.1339 16.6667 25.3337 16.6667H14.667C11.8667 16.6667 10.4666 16.6667 9.39704 17.2116C8.45623 17.691 7.69133 18.4559 7.21196 19.3967C6.66699 20.4663 6.66699 21.8664 6.66699 24.6667V27C6.66699 29.8003 6.66699 31.2004 7.21196 32.27C7.69133 33.2108 8.45623 33.9757 9.39704 34.455C10.4666 35 11.8667 35 14.667 35Z" stroke="#CFA531" stroke-width="3.33" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      <div class="desc-text reset-desc">
        If you want to change your login password click below to receive reset link to your account's e-mail address. Go to your inbox your inbox and visit the link to set a new password.
      </div>
      <div class="reset-link" @click="onPasswordReset">
        Send to my e-mail a password reset link
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.16699 8H13.8337M13.8337 8L9.83366 4M13.8337 8L9.83366 12" stroke="#125F43" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </div>
      </div>
</el-card>
</template>

<script>
  import Vue from 'vue'
  import auth from '../../auth.js'

  function onPasswordReset(){
    this.loading = true
    auth.changePassword().then(()=>{
      this.loading = false
      this.$message("Reset link sent. Please check your inbox")
    }).catch((e)=>{
      console.log("Failed to reset password",e)
      this.loading = false
      this.$message.error("Failed to request the reset link")
    })
  }

  const methods = {
    onPasswordReset
  }

  const props = {
    labelWidth: { default: Vue.$labelWidth },
    size: { default: Vue.$defaultFormSize },
    labelPosition: { default: Vue.$defaultlabelPosition },
    displaySave: {default: false}
  }

  function data(){
    return {
      loading: false
    }
  }

  export default { props, data, methods }
</script>
