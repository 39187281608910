import { render, staticRenderFns } from "./UserContactCard.vue?vue&type=template&id=90554b30&scoped=true&"
import script from "./UserContactCard.vue?vue&type=script&lang=js&"
export * from "./UserContactCard.vue?vue&type=script&lang=js&"
import style0 from "./UserContactCard.vue?vue&type=style&index=0&id=90554b30&scoped=true&lang=sass&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90554b30",
  null
  
)

export default component.exports