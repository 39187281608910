<style scoped lang="sass">
.preference-list
  display: flex
  flex-flow: wrap
  .preference-item
    flex: 0 0 50%
</style>
<template lang='pug'>
.preference-list
  .preference-item(v-for='x in statuses', :key='x.status', :span='12', v-show='preferences[x.status]')
      i.mat-icon chevron_right
      |  {{ x.name }}
</template>

<script>

  function data(){
    return {
      statuses: [
        { status: 'drinks', name: 'Drinks' },
        { status: 'desserts', name: 'Desserts' },
        { status: 'no_red_meat', name: 'No red meat' },
        { status: 'no_pork_products', name: 'No pork products' },
        { status: 'no_onions', name: 'Light / No onions' },
        { status: 'half_pans', name: 'Half pans' },
        { status: 'kosher', name: 'Kosher options' },
        { status: 'light_food', name: 'Light food' },
        { status: 'set_up', name: 'Set up' },
        { status: 'utensils', name: 'Utensils' },
        { status: 'lunch_box', name: 'Lunch box / IND' },
        { status: 'hot_food', name: 'Hot food' },
        { status: 'include_salad', name: 'Include salad' },
        { status: 'vegetarian_options', name: 'Vegetarian options' },
        { status: 'vegan_options', name: 'Vegan options' },
        { status: 'no_dairy', name: 'No dairy' },
        { status: 'gluten_free_needed', name: 'Gluten free needed' },
        { status: 'no_seafood', name: 'No fish/seafood' },
        { status: 'not_spicy', name: 'Not spicy' },
        { status: 'staff_needed', name: 'Staff needed' },
        { status: 'healthy', name: 'Healthy options' },
      ]
    }
  }

  function created(){
  }

  const computed = {
  }

  const watch = {
  }

  const props = {
    preferences: { required: true}
  }

  const components = { }

  const methods = {
  }

  export default { props, methods, components, data, created, computed }
</script>
