<template>
  <div class="tip-block">
    <div
      v-for="(tip, index) in tipsCollection"
      class="tip-btn"
      :class="{ active: index === currentTipIndex }"
      @click="selectTip(index)"
    >
      {{ tip }}
      <div v-show="index !== 3">
        %
      </div>
    </div>
    <pop-up
      :showPopUp="showOtherPopUp"
      @closePopUp="showOtherPopUp = false"
      title="Driver tip"
    >
      <div>
        <template>
          <el-row :gutter="$rowGutter">
            <el-col class="count count-range" :span="24">
              <div class="overview__daterange user-daterange">
                <numeric-input
                  :width="'500px'"
                  @updateValue="tip = $event"
                  :initialValue="tip"
                  :decimal="true"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4 10.6667C4 12.1395 5.19391 13.3334 6.66667 13.3334H9.33333C10.8061 13.3334 12 12.1395 12 10.6667C12 9.19395 10.8061 8.00004 9.33333 8.00004H6.66667C5.19391 8.00004 4 6.80613 4 5.33337C4 3.86061 5.19391 2.66671 6.66667 2.66671H9.33333C10.8061 2.66671 12 3.86061 12 5.33337M8 1.33337V14.6667"
                      stroke="#4C4C46"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </numeric-input>
                <div class="action-btn-container">
                  <user-default-button
                    :textColor="'#1F1F18'"
                    :borderColor="'#F4F4EF'"
                    btnColor="white"
                    :withBorder="true"
                    @onClick="showOtherPopUp = false"
                  >
                    Cancel
                  </user-default-button>
                  <el-button type="primary" @click="selectCustomTip">
                    Save
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12"
                        stroke="white"
                        stroke-width="1.33333"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </el-button>
                </div>
              </div>
            </el-col>
          </el-row>
        </template>
      </div>
    </pop-up>
  </div>
</template>

<style scoped>
.tip-block {
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: flex-end;
}

.tip-btn {
  height: 32px;
  padding: 6px, 20px, 6px, 20px;
  border-radius: 40px;
  border: solid 1px #ebebe4;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 68px;
  cursor: pointer;
}

.active {
  border: solid 1px #23813e;
  color: #23813e;
  background-color: #eff4ec;
}

.action-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 32px;
}

.user-daterange .el-button {
  margin-top: 0px;
}
</style>

<script>
import PopUp from "@/common/PopUp.vue";
import NumericInput from "@/user/blocks/NumericInput.vue";
import UserDefaultButton from "./UserDefaultButton.vue";

export default {
  data() {
    return {
      currentTipIndex: null,
      tipsCollection: [10, 15, 20, "Other"],
      showOtherPopUp: false,
      tip: 0
    };
  },

  props: {
    total: {
      require: true
    },
    initialValue: {
      require: true,
      default: 0
    }
  },

  watch: {
    total: function () {
      const tipsPercent = this.tipsCollection[this.currentTipIndex];
      const tipsCent =
          typeof ((this.total / 100) * tipsPercent) === "number"
            ? (this.total / 100) * tipsPercent
            : 0;
      this.$emit("tips-add", tipsCent);      
     }
  },

  methods: {
    selectTip(index) {
      if (index === this.currentTipIndex) {
        this.tip = 0
        this.$emit("tips-add", this.tip * 100);
        this.currentTipIndex = null
        return
      }
      if (index === 3) {
        this.showOtherPopUp = true;
      } else {
        const tipsPercent = this.tipsCollection[index];
        const tipsCent =
          typeof ((this.total / 100) * tipsPercent) === "number"
            ? (this.total / 100) * tipsPercent
            : 0;
        this.$emit("tips-add", tipsCent);
      }
      if (!this.showOtherPopUp) this.currentTipIndex = index;
    },
    selectCustomTip() {
      this.$emit("tips-add", this.tip * 100);
      this.currentTipIndex = 3
      this.showOtherPopUp = false;
    },
    calculatePercentage(initialTips, total) {
    if (total === 0) {
      return
    }
    return (initialTips / total) * 100;
    }
  },

  mounted() {
    if (this.initialValue && this.initialValue !== 0) {
      this.tip = this.initialValue;
      this.$emit("tips-add", this.tip);
      const initialPercentage = this.calculatePercentage(this.initialValue, this.total).toFixed()
      const initialTipIndex = this.tipsCollection.indexOf(+initialPercentage)
      this.currentTipIndex = initialTipIndex === -1 ? 3 : initialTipIndex
    }
  },

  components: { PopUp, NumericInput, UserDefaultButton }
};
</script>
