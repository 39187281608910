<template>
<div style="height: 100%;">
    <div class="sub-header-print"></div>
    <letter-head :no-padding="true" :no-border="true">
      <div class="printable-title top" v-if="model &amp;&amp; request">
      <div class="printable-title-text semi-bold">
        Purchase Order #{{ model.id }}
      </div>
      </div>
    </letter-head>
    <page-content v-if="model != null &amp;&amp; request != null">
      <div class="main-info">
        <div :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`" style="margin-right: 20px; width: 100%; padding: 22px;">
        <div class="request-info">
          <div class="main-info--col">
            <div class="main-info--details">
              <svg :width="`${isPrintingForm ? '12' : '24'}`" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 10H3M16 2V6M8 2V6M7.8 22H16.2C17.8802 22 18.7202 22 19.362 21.673C19.9265 21.3854 20.3854 20.9265 20.673 20.362C21 19.7202 21 18.8802 21 17.2V8.8C21 7.11984 21 6.27976 20.673 5.63803C20.3854 5.07354 19.9265 4.6146 19.362 4.32698C18.7202 4 17.8802 4 16.2 4H7.8C6.11984 4 5.27976 4 4.63803 4.32698C4.07354 4.6146 3.6146 5.07354 3.32698 5.63803C3 6.27976 3 7.11984 3 8.8V17.2C3 18.8802 3 19.7202 3.32698 20.362C3.6146 20.9265 4.07354 21.3854 4.63803 21.673C5.27976 22 6.11984 22 7.8 22Z" stroke="#808285" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="gray" :style="`fontSize: ${isPrintingForm ? '10px' : '16px'}`">
                Order Date:
              </div> 
              <div class="bold-info" :style="`fontSize: ${isPrintingForm ? '10px' : '16px'}`">
                {{ $formatDate(model.delivery_date) }}
              </div>
            </div>
            <div class="main-info--details">
              <svg :width="`${isPrintingForm ? '12' : '24'}`" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 21C20 19.6044 20 18.9067 19.8278 18.3389C19.44 17.0605 18.4395 16.06 17.1611 15.6722C16.5933 15.5 15.8956 15.5 14.5 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z" stroke="#808285" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
              <div class="gray" :style="`fontSize: ${isPrintingForm ? '10px' : '16px'}`">
                # of People:
              </div>
              <div v-if="request" :style="`fontSize: ${isPrintingForm ? '10px' : '16px'}`">
              {{ request.people }}
              </div>
            </div>
          </div>
          <div class="main-info--col center-column">
            <div class="ready-time main-info--details">
              <div :style="`fontSize: ${isPrintingForm ? '8px' : '12px'}`">
                Ready At {{ $formatTime(model.food_ready_time) }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`" style="width: 100%; padding: 22px;" :style="`width: ${isPrintingForm ? '80%' : '100%'}`">
        <div class="main-info--col">
            <div class="main-info--details">
            <div :style="`fontSize: ${isPrintingForm ? '10px' : '16px'}`" class="bold-info">
              {{ request.order.vendor_name }}
            </div>
            </div>
            <div class="main-info--details gray pick-up-column">
              <div :style="`fontSize: ${isPrintingForm ? '10px' : '16px'}`">
                {{ pickupAddress.address_line_1 }} {{ pickupAddress.address_line_2 }} {{ pickupAddress.city }}, {{ pickupAddress.state }} {{ pickupAddress.zip_code }}
              </div>
            </div>
          </div>
      </div>
      </div>
      <br>
      <div>
      <div class="printable-title-text orders-title center semi-bold" style="margin-bottom: 10px;" :style="`fontSize: ${isPrintingForm ? '12px' : '20px'};`">
        Important Instructions
      </div>
      <div style="margin-top: 1.25rem; margin-bottom: 48px;" class="notes" :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`">
        <div v-if="model.purchase_order_notes && model.purchase_order_notes.length > 0 && model.purchase_order_notes[0].trim() !== ''">
          <div :style="`fontSize: ${isPrintingForm ? '10px' : '16px'};`" class="purchase" v-for="purchase in model.purchase_order_notes">
            <svg width="18" height="18" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            {{ purchase }}
          </div>
        </div>
        <em v-else>(None)</em>
      </div>
      <div class="printable-title-text orders-title semi-bold" style="margin-bottom: 16px; margin-top: 16px; page-break-inside: avoid;" :style="`fontSize: ${isPrintingForm ? '12px' : '20px'};`">
        Client Orders
      </div>
        <el-table :header-cell-style="{ background: '#F0F6F4', color: '#115F43' }" :data="productItems" style="width: 100%; border-radius: 18px 18px 0 0; padding: 20px; border: 1px solid #F2F2F2" :style="`fontSize: ${isPrintingForm ? '10px' : '16px'};`">
            <el-table-column prop="quantity" label="QTY" :width="isPrintingForm ? '80' : '85'" style="background: ;"><template slot-scope="scope">
                    <div class="printable-checkbox"></div><span>{{ scope.row.quantity }}</span>
                </template></el-table-column>
            <el-table-column prop="name" label="Name" :width="isPrintingForm ? 200 : mainWidth"><template slot-scope="scope">
                    <div class="base-price"><span>{{ scope.row.name }}</span><span class="value">({{ $formatCents(scope.row.base_price_cents) }})</span></div>
                </template></el-table-column>
            <el-table-column prop="item_price" label="Item Price" :width="isPrintingForm ? 160 : 105"><template slot-scope="scope"><span>{{ $formatCents(scope.row.base_price_cents * scope.row.quantity) }}</span></template></el-table-column>
            <el-table-column prop="description" label="Product Notes" :width="isPrintingForm ? 'auto' : mainWidth"><template slot-scope="scope"><span>
                        <div class="description" :style="{maxWidth: isPrintingForm ? '30%' : '80%', overflowWrap: 'anywhere'}">{{ scope.row.product_notes }}</div>
                    </span></template></el-table-column>
      </el-table>
    <TableSummary :model="this.model" :vendor="this.vendor" style="border-radius: 0 0 18px 18px; page-break-inside: avoid;"></TableSummary>
    </div>
    <div style="page-break-inside: avoid;">
      <br>
      <br>
      <div class="printable-title-text orders-title semi-bold" style="margin-bottom: 10px; page-break-inside: avoid;" :style="`fontSize: ${isPrintingForm ? '12px' : '20px'};`">
        Please Note
      </div>
      <div style="margin-top: 1.25rem;" class="notes" :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`" :style="`fontSize: ${isPrintingForm ? '10px' : '16px'};`">
        <div class="pls-note-item">
          <svg width="18" height="18" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          <div>
            Have order <strong>ready</strong> on time
          </div>
        </div>
        <div class="pls-note-item">
        <svg width="18" height="18" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path></svg>
        <div>
          Make sure <strong>contents &amp quantities</strong> are correct
        </div>
        </div>
        <div class="pls-note-item">
          <svg width="18" height="18" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          <div>
          <strong>Do not charge tax</strong> &amp Apply SimpleCater Discount
          </div>
        </div>
        <div class="pls-note-item">
          <svg width="18" height="18" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M22 11.5857V12.5057C21.9988 14.6621 21.3005 16.7604 20.0093 18.4875C18.7182 20.2147 16.9033 21.4782 14.8354 22.0896C12.7674 22.701 10.5573 22.6276 8.53447 21.8803C6.51168 21.133 4.78465 19.7518 3.61096 17.9428C2.43727 16.1338 1.87979 13.9938 2.02168 11.842C2.16356 9.69029 2.99721 7.64205 4.39828 6.0028C5.79935 4.36354 7.69279 3.22111 9.79619 2.74587C11.8996 2.27063 14.1003 2.48806 16.07 3.36572M22 4.5L12 14.51L9 11.51" stroke="#23813E" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"></path></svg>
          <div>
            Provide Utensils, Plates &amp Servingware (if requested)
          </div>
        </div>
      </div>
    </div>
    <br>
    <div :style="`marginTop: ${isPrintingForm ? '10px' : '20px'}; page-break-inside: avoid;`" class="check-footer" :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`">
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <div class="check-footer-contact-us">
            Contact Us
          </div>
          <div class="check-footer-info" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
            for any issues, questions or concerns
          </div>
        </div>
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1737 11.8043C12.1017 13.7372 13.3667 15.5487 14.9689 17.1508C16.571 18.7529 18.3825 20.018 20.3153 20.946C20.4816 21.0258 20.5647 21.0657 20.6699 21.0963C21.0437 21.2053 21.5027 21.127 21.8193 20.9004C21.9083 20.8366 21.9846 20.7604 22.137 20.6079C22.6031 20.1418 22.8362 19.9087 23.0706 19.7564C23.9544 19.1817 25.0938 19.1817 25.9777 19.7564C26.2121 19.9087 26.4451 20.1418 26.9113 20.608L27.1711 20.8678C27.8797 21.5764 28.234 21.9307 28.4265 22.3112C28.8092 23.0679 28.8092 23.9616 28.4265 24.7184C28.234 25.0989 27.8797 25.4532 27.1711 26.1617L26.9609 26.3719C26.2548 27.0781 25.9017 27.4312 25.4216 27.7008C24.889 28.0001 24.0616 28.2152 23.4507 28.2134C22.9001 28.2118 22.5238 28.1049 21.7712 27.8913C17.7267 26.7434 13.9102 24.5774 10.7262 21.3934C7.54224 18.2095 5.37627 14.393 4.22831 10.3485C4.0147 9.59586 3.9079 9.21956 3.90626 8.66897C3.90444 8.05801 4.11959 7.23068 4.41882 6.69801C4.68848 6.21797 5.04157 5.86489 5.74773 5.15872L5.95791 4.94854C6.6665 4.23995 7.02079 3.88566 7.4013 3.6932C8.15805 3.31044 9.05174 3.31044 9.80848 3.6932C10.189 3.88566 10.5433 4.23995 11.2519 4.94854L11.5117 5.20837C11.9778 5.67451 12.2109 5.90758 12.3633 6.14195C12.938 7.0258 12.938 8.16525 12.3633 9.0491C12.2109 9.28347 11.9778 9.51654 11.5117 9.98268C11.3593 10.1351 11.2831 10.2113 11.2193 10.3004C10.9926 10.617 10.9143 11.076 11.0233 11.4498C11.054 11.555 11.0939 11.6381 11.1737 11.8043Z" stroke="#E5DC84" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="check-footer-info">
            800 310-0650
          </div>
        </div>
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.1665 9.33337L14.0531 16.954C14.9346 17.5711 15.3754 17.8796 15.8549 17.9991C16.2784 18.1047 16.7213 18.1047 17.1448 17.9991C17.6243 17.8796 18.065 17.5711 18.9466 16.954L29.8332 9.33337M9.5665 26.6667H23.4332C25.6734 26.6667 26.7935 26.6667 27.6491 26.2307C28.4018 25.8472 29.0137 25.2353 29.3972 24.4827C29.8332 23.627 29.8332 22.5069 29.8332 20.2667V11.7334C29.8332 9.49316 29.8332 8.37306 29.3972 7.51741C29.0137 6.76476 28.4018 6.15284 27.6491 5.76935C26.7935 5.33337 25.6734 5.33337 23.4332 5.33337H9.5665C7.32629 5.33337 6.20619 5.33337 5.35054 5.76935C4.59789 6.15284 3.98597 6.76476 3.60248 7.51741C3.1665 8.37306 3.1665 9.49316 3.1665 11.7334V20.2667C3.1665 22.5069 3.1665 23.627 3.60248 24.4827C3.98597 25.2353 4.59789 25.8472 5.35054 26.2307C6.20619 26.6667 7.32629 26.6667 9.5665 26.6667Z" stroke="#E5DC84" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="check-footer-info">
          info@simplecater.com
          </div>
        </div>
      </div>
    </page-content>
</div>
</template>

<script>
  import mainWidth from '@/mixins/mainWidth'
  import isPrintingForm from '@/mixins/mainWidth'
  import LetterHead from './LetterHead.vue'
  import PageContent from './PageContent.vue'
  import UserPreferences from "@/user/blocks/UserPreferences.vue";
  import TableSummary from './TableSummary.vue'

  const mixins = [ mainWidth, isPrintingForm ]

  function data(){
    return {
      model: null,
      request: null,
      pickupAddress: null,
      deliveryAddress: null,
      vendor: null,
    }
  }

  function created(){
    this.getData()
  }

  function getData(){
    this.$simplecater.get(`/orders/${this.id}`).then((r)=>{
      this.model = r.data.data
      this.model.purchase_order_notes = this.model.purchase_order_notes.split('\n'),
      this.$simplecater.get(`/requests/${this.model.request_id}`).then((r)=>{
          this.request = r.data.data
        }).catch(()=>{
          this.$message.error("Error loading the request information")
        })
      this.$simplecater.get(`/vendors/${this.model.vendor_id}`).then((r)=>{
          this.vendor = r.data.data
        }).catch(()=>{
          this.$message.error("Error loading the vendor information")
        })
      if ( this.model.pickup_address_id ) {
        this.$simplecater.get(`/addresses/${this.model.pickup_address_id}`).then((r)=>{
            this.pickupAddress = r.data.data
          }).catch(()=>{
            this.$message.error("Error loading the address information")
          })
      }
      if ( this.model.request_address_id ) {
        this.$simplecater.get(`/addresses/${this.model.request_address_id}`).then((r)=>{
            this.deliveryAddress = r.data.data
          }).catch(()=>{
            this.$message.error("Error loading the address information")
          })
      }
    }).catch(()=>{
      this.$message.error("Error loading the data")
    })
  }

  function itemsSummary ( x ) {
    const total = this.$formatCents(_.reduce(this.model.items, ( acc, i ) => {
      console.log((i.purchase_price_cents * i.quantity))
      return acc + (i.purchase_price_cents * i.quantity)
    }, 0))
    const itemsCount = _.chain(this.model.items)
      .filter(( x ) => x.type == "PRODUCT")
      .reduce(( memo, i ) => (memo + i.quantity), 0)
      .value()
    let vendorDiscount = ( this.vendor != null )? this.vendor.global_discount_percentage : ''
    return [`${itemsCount} items`,'TOTAL INCLUDING AGREED DISCOUNT (' + vendorDiscount + '%): ' + total]
  }

  const methods = { getData, itemsSummary }

  const components = { LetterHead, PageContent, UserPreferences, TableSummary }

  const props = {
    id: { required: true }
  }

  const computed = {
    productItems ( ) {
      return ( this.model )? _.filter(this.model.items, ( x ) => (x.type === 'PRODUCT')) : []
    },
  }

  export default { data, mixins, components, created, methods, props, computed }
</script>

<style lang="sass">
  .center
    display: flex
    justify-content: center
  .pls-note-item
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 8px
    margin-bottom: 8px
  .purchase
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 8px
    margin-bottom: 6px
  .insctuctions
    margin-bottom: 32px
  .center-column
    display: flex
    align-items: center
    justify-content: center
  .small-text
    font-size: 12px
  .ready-time
    color: white
    background-color: #EB5B13
    padding: .375rem .625rem
    border-radius: 12px
  .gray
    color: #70706B
  .main-info
    display: flex
    justify-content: space-between
    &--col
      display: flex
      flex-direction: column
      gap: 10px
    &--details
      display: flex
      flex-direction: row
      gap: .75rem
      align-items: center
      font-size: 14px
      font-weight: 500

  .el-table__footer-wrapper
    .cell
      word-break: normal
      word-wrap: normal
      overflow-wrap: normal

  .base-price
    word-break: break-all
    word-wrap: normal
    overflow-wrap: normal
    .value
      font-style: italic
      font-size: 0.6rem


  .printable-checkbox
    display: inline-block
    width: 3.7mm
    height: 3.7mm
    margin-right: 1.5mm
    border: 1px solid #333
    vertical-align: unset
  .printable-bottom
    .food-ready
      font-size: 1.5rem
      font-weight: bold
    .subtitle
      font-size: 1.2rem

  .description
    word-break: break-word

  ul
    li
      font-size: 0.9rem
  .pick-up-column
    display: flex
    flex-direction: column
    gap: 2px
    align-items: flex-start
  .check-footer
    font-size: 1.25rem
    display: flex
    align-items: center
    flex-direction: column
    justify-content: center
    gap: 0px
    page-break-inside: avoid !important
    page-break-before: auto !important
    background: linear-gradient(to right, #176043, #D9D480) !important
    &-item
      display: flex
      align-items: center
      gap: 8px
      margin-bottom: 1.125rem
      justify-content: center
    &-contact-us
      color: white
      font-weight: 600
    &-info
      color: white   

  .semi-bold
    font-weight: 500

  .request-info
    display: flex
    justify-content: space-between

  .sub-header-print
    width: 100%
    height: 8px
    background: #115F43    

</style>
