<template lang='pug'>
div
  letter-head(v-if='order', :no-padding='true')
    .printable-title.top
      .title(v-if='order.invoice_id') Invoice \#{{ order.invoice_id }}
      .order-title Order \#{{ order.id }}
      .po-title(v-if='order.customer_po') PO# {{ order.customer_po }}
      .po-title(v-else-if='request.customer_po') PO# {{ request.customer_po }}
  .printable-top-columns(v-if='order')
    el-row(:gutter='$rowGutter')
      el-col(:span='12')
        .top-column
          .top-title DELIVERY ADDRESS
          strong {{ order.customer_account_name }}
          div(v-if='address')
            | {{ address.address_line_1 }}
          div(v-if='request!== null && request.suite')
            | {{request.suite}} 
          div(v-else)
            | {{ address.address_line_2 }}
          div {{ address.city }}, {{ address.state }} {{ address.zip_code }}  
      el-col(:span='12')
        .top-column
          .top-title DELIVERY DETAILS
          div
            strong {{ order.request_name }}
          .standout {{ 'Del Date: '}}
            span.standout-accent {{ $formatDate(order.delivery_date) }}
          .standout {{ 'Del Time: ' }}
            span.standout-accent {{ $formatTime(order.delivery_time) }}
          .standout(v-if='request') {{ 'Serves: '}}
            span.standout-accent {{ request.people }} People
    br


    .products-from(v-if='order', style='font-size: 0.8rem')
      | Products from&nbsp;
      strong.cl-primary {{ order.vendor_name }}

  page-content(v-loading='order === null ')
    el-table(
      :data='itemsWithoutTaxTips'
    )
      el-table-column(prop='name', label='Name', :width="isPrintingForm ? 312 : mainWidth")
        template(slot-scope='scope')
          .product-name {{ scope.row.name }}
          .description {{ scope.row.product_description }}
          .description(v-if='scope.row.serves') serves {{ scope.row.serves }}
      el-table-column(prop='quantity', label='Quantity', width='100')
        template(slot-scope='scope', v-if='scope.row.type != "TAX" && scope.row.type != "DELIVERY"')
          | {{scope.row.quantity}}
      el-table-column(prop='sale_price_cents', label='Unit price', width='120')
        template(slot-scope='scope', v-if='scope.row.type != "TAX" && scope.row.type != "DELIVERY"')
          | {{$formatCents(scope.row.sale_price_cents) }}
      el-table-column(prop='total_cents', label='Total Price', width='140')
        template(slot-scope='scope', v-if='scope.row.type != "TAX"')
          | {{$formatCents(scope.row.total_cents) }}
    el-row(:gutter='$rowGutter', style='margin-top: 1rem;')
      el-col(:span='12')
        div
        .numeric-summary(v-if='invoce')
          .label Status:
          .value {{ invoceStatus}}
        .numeric-summary(v-if='invoce')
          .label Paid by
          .value {{ paidBy }}
        .numeric-summary(v-if='invoce')
          .label Amount paid:
          .value {{ $formatCents(invoce.total_cents - invoce.balance_cents) }}
        .numeric-summary(v-if='invoce')
          .label Balance:
          .value {{ $formatCents(invoce.balance_cents) }}  
      el-col(:span='12', v-if='totalCents && subtotalCents')
        .numeric-summary(:style="isPrintingForm ? 'margin-left: 68px' : ''" style="margin-top: 0.3rem")
          .label Subtotal
          .value {{ $formatCents(subtotalCents) }}
        .numeric-summary(:style="isPrintingForm ? 'margin-left: 68px' : ''")
          .label Tax
          .value {{ $formatCents(tax.sale_price_cents) }}
        .numeric-summary(v-if='tips', :style="isPrintingForm ? 'margin-left: 68px' : ''")
          .label Driver tip
          .value {{ $formatCents(tips) }}
        .numeric-summary(:style="isPrintingForm ? 'margin-left: 68px' : ''")
          .label
            big Total
          .value
            big {{$formatCents(totalCents)}}
</template>

<script>
import mainWidth from '@/mixins/mainWidth'
import isPrintingForm from '@/mixins/mainWidth'
import LetterHead from './LetterHead.vue'
import PageContent from './PageContent.vue'
import _ from 'underscore'

function data() {
  return {
    address: null,
    request: null,
    estimate: null,
    order: null,
    invoce: null
  }
}

function mounted() {
  this.getData()
}

function getData() {
  const fullOrder = `/orders/${this.id}`

  this.$simplecater.get(fullOrder).then((r) => {
    this.order = r.data.data

    if (this.order.invoice_id) {
      this.$simplecater.get(`/invoices/${this.order.invoice_id}`).then((r) => {
        this.$mergeWithTips(r.data.data.tips, this.order)
        this.invoce = r.data.data
      }).catch(() => {
        this.$message.error("Error loading the address information")
      })
    }

    this.$simplecater.get(`/addresses/${this.order.request_address_id}`).then((r) => {
      this.address = r.data.data
    }).catch(() => {
      this.$message.error("Error loading the address information")
    })

    // Request
    this.$simplecater.get(`/requests/${this.order.request_id}`).then((r) => {
      this.request = r.data.data
    }).catch(() => {
      this.$message.error("Error loading the request information")
    })
  }).catch(() => {
    this.$message.error("Error loading the order information")
  })
}

const mixins = [mainWidth, isPrintingForm]

const methods = { getData };

const components = { LetterHead, PageContent }

const props = {
  id: { required: true }
}

const computed = {
  invoceStatus() {
    const staus = this.invoce?.status;
    switch (staus) {
      case 'PAID':
        return 'Paid'
      case 'PENDING_PAYMENT':
        return 'Pending payment'
      case 'CANCELLED':
        return 'Cancelled'
      case 'CLOSED':
        return 'Closed'
      case 'REFUNDED':
        return 'Refunded'
    }
  },

  paidBy() {
    const paidType = this.invoce?.payment_source.split('_')[0];
    //const card = this.cards?.filter((c) => c.id === this.fullInvoice.payment_source)
    switch (paidType) {
      case 'OFFLINE':
        return 'House account'
      case 'card':
        return `Card`
      default: ''
    }
  },

  totalCents() {
    return (this.order) ? this.subtotalCents  + (this.tax ? this.tax.sale_price_cents : 0) + (this.tips ? this.tips : 0) : 0
  },

  itemsTotalCents() {
    return (this.order && this.order.items) ? _.reduce(this.order.items, (acc, i) => (acc + i.total_cents), 0) : 0
  },

  itemsWithoutTaxTips() {
    return (this.order && this.order.items) ? _.filter(this.order.items, (x) => (x.type != "TAX" && x.type != "TIP")) : []
  },

  tax() {
    return (this.order && this.order.items) ? _.find(this.order.items, (x) => (x.type === "TAX")) : null
  },

  tips() {
    return (this.order.tips && this.order.tips > 0) ? this.order.tips : null
  },

  subtotalCents() {
    return this.order ? _.reduce(this.order.items, (memo, i) => {
      return (i.type != "TAX" && i.type != "TIP") ? memo + (i.sale_price_cents * i.quantity) : memo
    }, 0) : 0
  },
}

export default { data, mixins, components, mounted, methods, props, computed }
</script>

<style scoped lang="sass">

  .standout
    font-weight: bold
    color: #444
    margin-left: 0

  .description
    font-style: italic
    font-size: 12px
    word-break: break-word
    color: #888

  .not-final
    font-size: 20px
    font-weight: bold
    color: #909399

  .printable-title.top
    margin-top: 0px

  .standout-accent
    color: #dd711f

  .serves
    color: #444

  .po-title
    font-size: 16px
    font-weight: bold
    color: #dd711f

  .order-title
    font-size: 16px
    font-weight: bold
    color: #444

  .numeric-summary-printing
    margin-left: 46px

</style>
