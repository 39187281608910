<template lang='pug'>
el-form(
  :label-width='labelWidth', :label-position='labelPosition'
  :model='contact', :rules='rules', ref='contactForm'
)
  el-form-item(label='Salutation', prop='salutation')
    el-select(v-model='contact.salutation' placeholder='Select one')
      el-option(value='Ms.', label='Ms.' :class="forUser ? 'user-select' : ''")
      el-option(value='Mr.', label='Mr.' :class="forUser ? 'user-select' : ''")
      el-option(value='Dr.', label='Dr.' :class="forUser ? 'user-select' : ''")
  el-form-item(label='First name', prop='first_name')
    el-input(v-model='contact.first_name')
  el-form-item(label='Last name', prop='last_name')
    el-input(v-model='contact.last_name')
  el-form-item(label='Email', prop='email')
    el-input(v-model='contact.email')
  el-form-item(label='Phone number', prop='phone_number')
    el-input(v-model='contact.phone_number')
      template(slot='prepend')
        | +1
  el-form-item(v-if='displaySave')
    el-button(
      type="primary",icon="el-icon-add",
      @click="onSave", :loading='loading'
    ) Save
</template>



<script>

  import Vue from 'vue'

  function data(){
    return {
      contact: Object.assign({},this.baseData),
      rules: {
        salutation: {required: true, message: 'Please select an option', trigger: 'change'},
        first_name: {required: true, message: 'Please input a name', trigger: 'blur'},
        last_name: {required: true, message: 'Please input a last name', trigger: 'blur'},
        email: { type: 'email', required: true, message: 'Please input a valid email', trigger: 'blur, change'},
        phone_number: {required: true, pattern: /\d{10}/, message: 'Must be a 10 digits phone (numbers only).', trigger: 'blur'},
      }
    }
  }

  function validate(callback){
    this.$refs.contactForm.validate((valid)=>{
      if (valid){
        callback()
      }else{
        callback(new Error('Please verify the contact form'))
      }
    })
  }

  function create(){
    return new Promise((resolve, reject)=>{
      this.$refs.contactForm.validate((valid)=>{
        if (valid){
          const payload = Object.assign({},this.contact)
          const apiUrl = this.customerId ? `/customers/${this.customerId}/contacts` : '/contacts';
          this.$simplecater.post(apiUrl, payload).then((r)=>{
            // TODO handle errors, redirect on unathorized
            console.log("CONTACT", r.data.data.id)
            resolve(r.data.data)
          })
        }else{
          this.$message.error('The contact cannot be created because it contains errors')
          reject('The contact cannot be created because it contains errors')
        }
      })
    })
  }

  function onSave(){
    this.$refs.contactForm.validate((valid)=>{
      if (valid){
        const payload = Object.assign({},this.contact)
        this.$emit('saveClick', payload)
      } else {
        this.$message.error('The contact cannot be created because it contains errors')
      }
    })
  }



  const props = {
    labelWidth: { default: Vue.$labelWidth },
    labelPosition: { default: Vue.$defaultlabelPosition },
    displaySave: { default: false},
    loading: { default: false},
    customerId: {required: false},
    forUser: {type: Boolean, default: false},
    baseData: {
      default: function(){
        return {
          first_name: '',
          last_name: '',
          email: '',
          salutation: null,
          phone_number: ''
        }
      }
    }
  }

  const components = {  }

  const methods = { create, validate, onSave }

  export default { data, methods, props }
</script>
