<template>
    <div class="step-number">
        {{step}}
    </div>
</template>

<style lang="sass" scoped>
.step-number
  width: 2.5rem
  height: 2.5rem
  background: #F4F9F5
  border-radius: 100%
  color: #125F43
  display: flex
  align-items: center
  justify-content: center

</style>

<script>
export default {
  props: {
    step: {
        type: Number | String,
        require: true,
        default: 0
    }
  }
};
</script>
