<template lang='pug'>
div
  el-row(:gutter='$rowGutter')

    el-col(:span='24', style='margin: 2rem 0')
      //- Order steps
      el-steps(:active="orderSteps[order.status] || 0", finish-status="success", align-center)
        el-step(title="Submit Order")
        el-step(title="Accepted by SimpleCater")
        el-step(title="Notified Restaurant")
        el-step(title="Assigned for Delivery")


    el-col(:span='24')
      el-card
        div(slot='header')
          el-col(:span='12')
            i.mat-icon playlist_add_check
            | Items

            .po-number(v-if='order.customer_po')
              | PO# {{order.customer_po}}

          el-col(class='print-version' :span='12')
            el-button(v-if='true' type='text', @click='$openInBlank(`/print/orders/${order.id}`)') Printable version

        .products-from Products from: <strong>{{order.vendor_name}}</strong>
          br
          small Price per person: <strong>{{ $formatCents(pricePerPerson) }}</strong>
        el-table(:data='itemsWithoutTaxTip')
          el-table-column(prop='name', label='Name', :width='width')
            template(slot-scope='scope')
              div {{scope.row.name}}
              .secondary(v-if='scope.row.type == "PRODUCT"') {{ scope.row.product_description }}
              .secondary(v-if='scope.row.serves') serves {{scope.row.serves}}
          el-table-column(prop='quantity', label='Quantity', width='160')
            template(slot-scope='scope', v-if='scope.row.type != "TAX" && scope.row.type != "DELIVERY"')
              | {{ scope.row.quantity }}
          el-table-column(prop='sale_price_cents', label='Unit price', width='160')
            template(slot-scope='scope', v-if='scope.row.type != "TAX" && scope.row.type != "DELIVERY"')
              | {{$formatCents(scope.row.sale_price_cents)}}
          el-table-column(prop='total_cents', label='Price', width='160')
            template(slot-scope='scope')
              | {{$formatCents(scope.row.total_cents) }}
        el-row(:gutter='$rowGutter')
          el-col(:span='13') &nbsp;
          el-col(:span='9', v-if='totalCents && subtotalCents', style='text-align: right;')
            .numeric-summary
              .label Subtotal
              .value {{ $formatCents(subtotalCents) }}
            .numeric-summary
              .label Tax
              .value {{ $formatCents(tax.sale_price_cents) }}
            .numeric-summary(v-if='tips')
              .label Driver Tip
              .value {{ $formatCents(tips) }}
            .numeric-summary
              .label
                big Total
              .value
                big {{$formatCents(totalCents)}}

    el-col(:span='24', style='margin-top: 1.5rem')
      el-card
        div(slot='header')
          i.mat-icon playlist_add_check
          | Misc
        template(v-if='order !== null')
          el-row(:gutter='$rowGutter')
            el-col(:span='12')
              h4.cl-menu Address
              strong {{ requestAddress.address_line_1 }}
              div(v-if='requestAddress.address_line_2 && !request.suite') {{ requestAddress.address_line_2 }}
              div(v-if='request.suite') {{ request.suite }}
              div {{ requestAddress.city }}, {{ requestAddress.state }} {{ requestAddress.zip_code }}
              br
              br
            el-col(:span='12')
              h4.cl-menu Contact
              strong {{ contact.salutation }} {{ contact.first_name }} {{ contact.last_name }}
              div {{ modifiedPhoneNumber(contact.phone_number) }}
              br
              br
          el-row(:gutter='$rowGutter')
            el-col(:span='12')
              h4.cl-menu Request Notes
              blockquote.classic(v-if='order.request_notes && order.request_notes.trim() != ""') {{order.request_notes}}
              em(v-else) N/A
            el-col(:span='12')
              h4.cl-menu Notes on accept
              blockquote.classic(v-if='order.accept_notes && order.accept_notes.trim() != ""') {{order.accept_notes}}
              em(v-else) N/A
          
          //- el-row(:gutter='$rowGutter')
            el-col(:span='12')
              div(v-if='deliveryAddress')
                div
                  strong {{ fullDelivery.vendor_name }}
                i.mat-icon.cl-primary place
                span.address-1  {{ deliveryAddress.address_line_1 }}
                .address-2 {{ deliveryAddress.address_line_2 }}
                .address-secondary {{ deliveryAddress.city }}, {{ deliveryAddress.state }} {{ deliveryAddress.zip_code }}
            el-col(:span='12')
              h3.cl-menu Delivery
              div(v-if='pickupAddress')
                div
                  strong {{ fullDelivery.customer_account_name }}
                i.mat-icon.cl-primary place
                span.address-1  {{ pickupAddress.address_line_1 }}
                .address-2 {{ pickupAddress.address_line_2 }}
                .address-secondary {{ pickupAddress.city }}, {{ pickupAddress.state }} {{ pickupAddress.zip_code }}
</template>



<script>
import modifyPhoneNumber from '@/mixins/modifiedPhoneNumber'
import _ from 'underscore'

const mixins = [modifyPhoneNumber];

function data() {
  return {
    orderSteps: {
      SUBMITTED: 0,
      RECEIVED: 1,
      ORDERED: 2,
      CONFIRMED: 2,
      ASSIGNED: 3,
    },
    // request: null
  }
}

function created() { }

const props = {
  order: {
    required: true, type: Object
  },
  request: {
    required: true, type: Object
  },
  requestAddress: {
    required: true, type: Object
  },
  contact: {
    required: true, type: Object
  },
  contWidth: {
    required: true, type: Number || String
  }
}

const methods = {
  created,
}

const components = {}

const computed = {
  totalCents() {
    return this.subtotalCents + (this.tax ? this.tax.total_cents : 0) + (this.tips ? this.tips : 0)
  },

  pricePerPerson() {
    return this.totalCents / this.request.people
  },

  itemsWithoutTaxTip() {
    return (this.order) ? _.filter(this.order.items, (x) => (x.type != "TAX" && x.type != "TIP")) : null
  },

  tax() {
    return (this.order) ? _.find(this.order.items, (x) => (x.type === "TAX")) : null
  },

  tips() {
    return (this.order.tips) ? this.order.tips : null
  },

  subtotalCents() {
    return this.order ? _.reduce(this.order.items, (memo, i) => {
      return (i.type != "TAX" && i.type != "TIP") ? memo + (i.sale_price_cents * i.quantity) : memo
    }, 0) : 0
  },

  width() {
    return this.contWidth ? this.contWidth - 540 : '';
  }
}

export default { created, data, methods, props, components, computed, mixins }
</script>

<style scoped lang="sass">
  .numeric-summary
    .label
      max-width: 10rem
    .value
      width: 4rem
  h4
    margin-top: 0
  .secondary
    font-size: 12px
    font-style: italic
    white-space: pre-line
    word-break: break-word

  .po-number
    display: inline-block
    padding-left: 20px
    font-size: 14px
    color: #dd711f

  .print-version
    text-align: right

</style>


