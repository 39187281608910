<template lang='pug'>
div(ref='estimatesContainer')
  el-row(:gutter='$rowGutter')

    el-col(:span='12')
      el-card
        //- div(slot='header')
        //-   i.mat-icon playlist_add_check
        //-   | User indications
        template(v-if='contact !== null')

          h3 Request preferences
          div(v-loading='fullRequest == null')
            template(v-if='fullRequest !== null')
              preferences-summary(:preferences='fullRequest.preferences')

          h3 Customer notes
          | {{requestSummary.notes || 'N/A'}}

      el-card
        //- div(slot='header')
        //-   i.mat-icon playlist_add_check
        //-   | Misc
        template(v-if='customer !== null')

          h3 Secret staff notes
          | {{customer.staff_notes || 'N/A'}}


    el-col(:span='12')
      el-card
        //- div(slot='header')
        //-   i.mat-icon playlist_add_check
        //-   | Misc.
        template(v-if='contact !== null')

          h3 Customer
          div(v-loading='customer == null')
            template(v-if='customer !== null')
              div {{customer.account_name}}
              div {{customer.city}}, {{customer.state}} {{customer.zip_code}}

              div(v-if='customer.tax_exempt') Tax exempt
              //- div(v-else)
              //-   | Tax rate {{(request.tax_rate*100).toFixed(1)}}% &nbsp;
              //-   el-tag(type='info', size='mini') {{customer.county}}


              //- div Staff notes: {{customer.staff_notes}}

          h3 Address
          div(v-loading='loadingAddress')
            template(v-if='address !== null')
              div {{address.name}}
              div {{address.address_line_1}}
              div(v-if='!fullRequest.suite') {{address.address_line_2}}
            template(v-if='fullRequest!== null && fullRequest.suite')
              div 
                | {{fullRequest.suite}}
            template(v-if='addressError !== null')
              div {{addressError}}
            div {{address.city}}, {{address.state}} {{address.zip_code}}

          h3 Contact
          div(v-loading='loadingContact')
            template(v-if='contact !== null')
              div {{contact.salutation}} {{contact.first_name}} {{contact.last_name}}
              div {{modifiedPhoneNumber(contact.phone_number)}}
            template(v-if='contactError !== null')
              div {{contactError}}

  el-row(:gutter='$rowGutter', :style='{marginTop: "2rem", maxWidth: "100%", marginRight: 0, marginLeft: 0}')
      el-card.estimates-card__container
        div(slot='header')
          span Estimates
          div.request__buttons-cont
            el-button(v-if='showSubmitButton', type='primary', @click='onAccept') Submit
            el-button(v-if='estimates && estimates.length === 0',type='primary', @click='appendEstimate()') Create first estimate
            span.assignee
              el-button(v-if="!assignee", type="primary", @click="$emit('estimatePrePublished')") Assign
              span(v-else)
                | Assignee: 
                el-button(type="text", @click="$emit('estimatePrePublished')") {{assignee}}
                span.clear_button(@click="clearAssignee()")
                  i.mat-icon clear
            el-button(v-if='estimates && estimates.length > 0 && blockNewEstimate', type='primary', @click='publishEstimates()', :disabled='hasLocalEstimates') Publish estimates

        el-tabs(
          v-model='activeIndex', ref='estimates', type='card',
          editable, @tab-add='appendEstimate', @tab-remove='removeEstimate',
          v-if='estimates != null && !safariRerender', style='max-width: 83vw'
        )
          //- :label='"Estimate #" + (index+1)',
          el-tab-pane(
            :label='getEstimateName(estimate)', :name="index.toString()",
            v-for='estimate, index in estimates', :key='index')

            estimate-builder(
              @change='onEstimateChange', :remote-id='estimate.id',
              v-bind:cuisines='cuisines',  :index='index',
              :total-budget-cents='totalBudgetCents',
              :request-id='fullRequest.id', :tax-rate='customerTaxRate'
              v-if='activeIndex == index.toString()'
              @tips-update="updateTips($event)"
              :fullOrder='estimate'
            )
           
  accept-estimate-dialog(
    v-if='displayAcceptDialog'
    :estimate='currentEstimate'
    :visible.sync='displayAcceptDialog'
    :customerId='customerId'
    :request='this.fullRequest'
    :address='this.address'
    @updateRequests="updateRequests"
    :tipsCent="tips"
  )
</template>



<script>

  import EstimateBuilder from './EstimateBuilder'
  import PreferencesSummary from './PreferencesSummary'
  import AcceptEstimateDialog from '../../user/blocks/AcceptEstimateDialog'
  import _ from 'underscore'
  import modifyPhoneNumber from '@/mixins/modifiedPhoneNumber'

  const mixins = [modifyPhoneNumber]

  function data(){
    return {
      assignee: '',
      activeIndex: null,
      fullRequest: null,
      customer: null,
      contact: null,
      address: null,
      cuisines: null,
      estimates: [],
      contactError: null,
      addressError: null,
      displayAcceptDialog: false,
      safariRerender: false,
      divWidth: 0,
      tips: 0
    }
  }

  function onAccept(){
    this.displayAcceptDialog = true;
  }

  function updateTips(tips) {
    this.tips = tips
  }

  function loadContact(){
    const target = `/contacts/${this.requestSummary.contact_id}`
    // TODO catch
    return this.$simplecater.get(target).then((r)=>{
      this.contact = r.data.data
    }).catch(()=>{
      this.contactError = 'Temporarily unavailable'
    })
  }

  function loadAddress(){
    const target = `/addresses/${this.requestSummary.address_id}`
    // TODO catch
    return this.$simplecater.get(target).then((r)=>{
      this.address = r.data.data
    }).catch(()=>{
      this.addressError = 'Temporarily unavailable'
    })
  }

  function loadCustomer(){
    const target = `/customers/${this.requestSummary.customer_id}`
    // TODO catch
    return this.$simplecater.get(target).then((r)=>{
      this.customer = r.data.data
    }).catch(()=>{
      this.$message.error("Failed to load the customer information")
    })
  }

  function loadRequest(keepEstimates = false){
    const target = `/requests/${this.requestSummary.id}`
    // TODO catch
    return this.$simplecater.get(target).then((r)=>{
      console.log("FULL REQUEST LOADED")
      this.fullRequest = r.data.data
      this.assignee = r.data.data.assignee ?? ''
      this.cuisines = _.sortBy(this.fullRequest.cuisines, ( x ) => x.name.toLowerCase())
      if(!keepEstimates) {
        this.estimates = _.sortBy(this.fullRequest.estimates, 'id')
      }
    }).catch(()=>{
      this.$message.error("Failed to load the request")
    })
  }


  function removeEstimate(index){
    const removeItem = () => {
      let newArray = _.map(this.estimates, _.id)
      newArray.splice(index, 1)
      this.estimates = newArray
      this.$message("The estimate was deleted")
      if (this.estimates.length > 0) this.activeIndex = String(0)
    }
    if(this.estimates[index].id){
      const target = `/estimates/${this.estimates[index].id}`
      // Delete the remove estimate
      this.$simplecater.delete(target).then(removeItem)
    }else{
      removeItem()
    }
  }

  function appendEstimate(){
    const emptyEstimate = {
      customer_notes: '',
      name: `Menu ${this.estimates.length + 1}`,
      staff_notes: '',
      status: 'DRAFT'
    }
    const es = _.map(this.estimates)
    const newLength = es.push(emptyEstimate)
    this.estimates = es
    this.activeIndex = String(newLength - 1)
    //this.refreshEstimatesView()
  }

  function refreshEstimatesView () {
    document.body.style.display = 'none'
    setTimeout(()=> {
      document.body.style.display = 'block'
    }, 0)
  }

  function onEstimateChange(index, data){
    console.log("RECEIVING NEW ESTIMATE", index, data)
    this.estimates.splice(index, 1, data)
  }

  function clearAssignee() {
    const formData = new FormData()
    formData.append('assignee', '')
    this.assignee = ''
    this.$simplecater.post(`/requests/${this.fullRequest.id}/assign`, formData)
      .then(() => (this.$message.success('Assignee was cleared')))
      .catch(() => (this.$message.error('Error while clearing assignee')))
      .finally(() =>  {
        this.loadRequest(true) 
        this.$emit('clearAssignee', this.requestSummary.id)
      })
  }

  function created(){
    this.loadContact()
    this.loadAddress()
    this.loadRequest()
    this.loadCustomer()
  }

  function publishEstimates(){
    const target = `/requests/${this.requestSummary.id}/publish_estimates`
    if(!this.assignee){
      this.$confirm("Your assignee is empty. Would you like to create it?", "Assignee", {
        confirmButtonText: 'Create',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$emit('estimatePrePublished', true)
      }).catch(() => {
        this.$simplecater.post(target).then((r)=>{
          this.$message("The estimates are now visible by the customer")
          this.$bus.$emit('estimates-published')
        })
      })
      return;
    }
    this.$simplecater.post(target).then((r)=>{
      this.$message("The estimates are now visible by the customer")
      this.$bus.$emit('estimates-published')
    })
  }

  function getEstimateName ( estimate ) {
    return ( estimate.status )? estimate.name + " [" + estimate.status + "]" : estimate.name
  }

  function attachResizeObserver() {
      this.resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
          this.divWidth = entry.contentRect.width;
        }
      });
      this.resizeObserver.observe(this.$refs.estimatesContainer);
    }

  const watch = {
    'requestSummary.assignee'(newAssignee) {

      this.assignee = newAssignee
    },
    '$route' (to, from) {
      // console.log("MOVING!")
    },

    divWidth(newVal, oldVal) {
      const ua = navigator.userAgent
      if ((/iPad|iPhone|iPod/.test(ua)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
          this.refreshEstimatesView();
      }
    }
  }

  function mounted() {
  this.$nextTick(() => {
    this.attachResizeObserver();
  })
  }

  function beforeDestroy() {
      if (this.resizeObserver && this.$refs.estimatesContainer) {
      this.resizeObserver.unobserve(this.$refs.estimatesContainer);
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  const props = {
    requestSummary: {
      required: true, type: Object
    },
    updateRequests: { required: false }
  }

  const methods = {
    created, loadContact, loadAddress, loadRequest, appendEstimate,
    removeEstimate, onEstimateChange, publishEstimates, loadCustomer,
    getEstimateName, onAccept, clearAssignee, refreshEstimatesView, attachResizeObserver, updateTips
  }

  const components = { EstimateBuilder, PreferencesSummary, AcceptEstimateDialog }

  const computed = {
    loadingAddress(){
      return this.address === null && this.addressError === null
    },
    loadingContact(){
      return this.contact === null && this.contactError === null
    },
    totalBudgetCents(){
      return this.fullRequest.budget_cents * this.fullRequest.people
    },
    customerTaxRate(){
      if (this.customer == null) return 0
      if(this.customer.tax_exempt) return 0
      return this.fullRequest.tax_rate
    },
    hasLocalEstimates ( ) {
      return !!_.find(this.estimates, ( e ) => (e.id === undefined || e.id === null) )
    },
    blockNewEstimate() {
      return !(this.fullRequest && (this.fullRequest.status === 'CANCELLED' || this.fullRequest.status === 'ESTIMATE_ORDERED')) && this.estimates.every((e) => !!e.id);
    },
    customerId() {
      return this.customer && this.customer.id || null;
    },
    currentEstimate() {
      return this.estimates && this.estimates.length ? this.estimates[this.activeIndex] : '';
    },
    showSubmitButton() {
      return this.fullRequest
        && this.fullRequest.status === 'ESTIMATES_AVAILABLE'
        && this.customerId && this.estimates.length
        && this.activeIndex && this.estimates[this.activeIndex]
        && this.estimates[this.activeIndex].status
        && this.estimates[this.activeIndex].status !== 'DRAFT';
    }
  }

  export default { created, data, methods, props, components, computed, watch, mixins, mounted, beforeDestroy }
</script>

<style lang="sass">
  .assignee
    margin: 0 15px
  .clear_button
    margin-right: 15px
    i
      cursor: pointer
  .assignee-input
    margin-left: 20px
    width: 200px
  .request__assignee
    margin-bottom: 20px
    display: flex
    justify-content: flex-end
    align-items: center
  .request__buttons-cont
    float: right
</style>
