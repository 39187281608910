<style scoped lang="sass">
.user-city-zip
    display: flex
    gap: 1rem
.user-select-option
    border-radius: .625rem
.footer-section
    display: flex
    gap: 1rem
    justify-content: flex-end
    margin-top: 10vh         
</style>
<template>
    <el-form :label-width="labelWidth" :label-position="labelPosition" :model="address" :rules="rules" ref="addressForm">
    <el-form-item label="Name" prop="Address nickname" v-if="hasName">
        <el-input v-model="address.name" placeholder="Address name"></el-input>
    </el-form-item>
    <el-form-item label="Address" prop="address_line_1">
        <google-autocomplete ref="address" id="map" placeholder="Please type your address" :enable-geolocation="true" v-on:placechanged="onAddressChange" country="us" :label-width="labelWidth" :label-position="labelPosition" :base-text="address.address_line_1"></google-autocomplete>
    </el-form-item>
    <el-form-item label="Extra info" prop="address_line_2">
        <el-input v-model="address.address_line_2" placeholder="Apartment, suite, etc."></el-input>
    </el-form-item>
    <div class="user-city-zip">
        <el-form-item style="width: 100%" label="City" prop="city">
        <el-input v-model="address.city"></el-input>
        </el-form-item>
        <el-form-item style="width: 100%" label="Zip code" prop="zip_code">
        <el-select v-model="address.zip_code" filterable="filterable" :filter-method="filteringZipCodes" @blur="onZipCodesBlur" placeholder="Select one">
            <el-option class="user-select-option" v-for="i in filteredZipCodes" :value="i" :label="i" :key="i" ></el-option>
        </el-select>
    </el-form-item>
    </div>
    <el-form-item label="State" prop="state">
        <el-select v-model="address.state" placeholder="Select one">
            <el-option class="user-select-option" v-for="i in states" :value="i.abbr" :label="i.name" :key="i.abbr" ></el-option>
        </el-select>
    </el-form-item>
    <el-form-item v-if="hasNotes" label="Notes" prop="notes">
        <el-input type="textarea" v-model="address.notes" :autosize="{ minRows: 10, maxRows: 30}"></el-input><br />
    </el-form-item>
        <div class="footer-section">
        <cancel-button v-if="!hideCancel" class="cancel-btn" @onClick="$router.back()" title="Cancel"></cancel-button>
        <default-button title="Save Changes" @onClick="onSave">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </default-button>
        </div>
    </el-form>
</template>



<script>
import AddressBuilder from './AddressBuilder.vue'
import DefaultButton from '@/layout/Blocks/DefaultButton.vue'
import CancelButton from '@/layout/Blocks/CancelButton.vue'

export default {
    mixins: [AddressBuilder],
    components: { DefaultButton, CancelButton },
    props: {
        hideCancel: {
            require: false,
            default: false
        }
    }
}
</script>
