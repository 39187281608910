<template lang='pug'>
drawer( dismissRoute="" @close="cancelDialog")
  .drawer-title
    i.mat-icon edit
    | Edit delivery
  el-form(
    v-loading='loading'
    :label-width='$labelWidth'
    :model='editDelivery'
    :rules='rules'
    ref='form'
    class='edit-deliveries__form')

    el-form-item(label='Delivery type'
                 prop='type')
      el-select(v-model='editDelivery.type'
                placeholder='Select an option')
        el-option(v-for='item in typeOptions'
                  :key='item.id'
                  :label='item.label'
                  :value='item.value')

    el-form-item(label='Delivery date'
                 prop='date')
      el-date-picker(v-model='editDelivery.date'
                     format="yyyy-MM-dd"
                     value-format="yyyy-MM-dd")

    el-form-item(label='Delivery time'
                 prop='deliveryTime')
      el-time-picker(v-model='editDelivery.deliveryTime'
                     placeholder='Select a time'
                     format='hh:mm A',
                     value-format='HH:mm')

    el-form-item(label='Delivery address'
                 prop='deliveryAddressId')
      el-select(v-model='editDelivery.deliveryAddressId'
                placeholder='Select an option')
        el-option(v-for='item in customerAddresses'
                  :key='item.id'
                  :label='item.name || item.address_line_1'
                  :value='item.id')
          span
            | {{item.name || item.address_line_1}}
            el-tooltip(:content='fullAddress(item)' placement='left')
              | &nbsp;
              i.mat-icon.secondary info

    el-form-item(label='Pickup Address'
                 prop='pickupAddressId')
      el-select(v-model='editDelivery.pickupAddressId'
                placeholder='Select an option')
        el-option(v-for='item in pickupAddresses'
                  :key='item.id'
                  :label='item.name || item.address_line_1'
                  :value='item.id')

    el-form-item(label='Pickup time'
                 prop='pickupTime')
      el-time-picker(v-model='editDelivery.pickupTime'
                     placeholder='Select a time'
                     format='hh:mm A',
                     value-format='HH:mm')

    el-form-item(v-if='displayNotes'
                 prop='notes'
                 :label='notesLabel')
      el-input(v-model='editDelivery.notes'
               placeholder='Add notes')

    .dialog-footer
      .buttons-section
        el-button(type='primary'
                  :loading='submitting'
                  @click='onSubmit')
          | Save
        el-button(@click='cancelDialog')
          | Cancel
</template>

<script>
  import Drawer from '../../layout/Drawer.vue';

  export default {
    props: {
      delivery: { required: true }
    },
    data() {
      return {
        loading: false,
        submitting: false,
        editDelivery: {
          date: '',
          deliveryAddressId: '',
          notes: '',
          type: '',
          pickupAddressId: '',
          deliveryTime: '',
          pickupTime: ''
        },
        rules: {
          type: [{
            required: true, message: 'Please select type', trigger: 'blur'
          }],
          date: [{
            required: true, message: 'Please select date', trigger: 'blur'
          }],
          deliveryAddressId: [{
            required: true, message: 'Please select delivery address', trigger: 'blur'
          }],
          pickupAddressId: [{
            required: true, message: 'Please select pickup address', trigger: 'blur'
          }],
          pickupTime: [{
            required: true, message: 'Please select pickup time', trigger: 'blur'
          }],
          deliveryTime: [{
            required: true, message: 'Please select delivery time', trigger: 'blur'
          }]
        },
        pickupAddresses: [],
        customerAddresses: [],
        typeOptions: [
          {label: 'SimpleCater driver', value: 'SIMPLECATER_DRIVER', id: 1},
          {label: 'Customer pickup', value: 'CUSTOMER_PICKUP', id: 2}
        ]
      }
    },
    components: { Drawer },
    computed: {
      displayNotes() { return this.editDelivery.type === 'SIMPLECATER_DRIVER'; },
      notesLabel() { return 'Driver\'s name'; }
    },
    methods: {
      cancelDialog() {
        this.$emit('closeModal');
      },
      fullAddress({ zip_code, state, city, address_line_1, address_line_2 }) {
        return `${address_line_1}${address_line_2 && `, ${address_line_2}`}, ${city}, ${state}, ${zip_code}`
      },
      onSubmit() {
        this.$refs.form.validate((valid) => {
          if (valid) {
            this.sendDelivery();
          } else {
            this.$message.error('Some validations failed, please check your inputs');
          }
        })
      },
      sendDelivery() {
        const params = {
          delivery_address_id: this.editDelivery.deliveryAddressId,
          pickup_address_id: this.editDelivery.pickupAddressId,
          type: this.editDelivery.type,
          delivery_date: this.editDelivery.date,
          pickup_time: this.editDelivery.pickupTime,
          delivery_time: this.editDelivery.deliveryTime,
          notes: this.editDelivery.notes
        };
        this.submitting = true;
        this.$simplecater.put(`/deliveries/${this.delivery.id}`, params).then(() => {
          this.$emit('updateDeliveries');
          this.$message('Delivery updated');
          this.cancelDialog();
        }).catch(()=>{
          this.$message.error("Update delivery failed")
        })
      }
    },
    beforeMount() {
      if (this.delivery) {
        const { date,
                delivery_address_id: deliveryAddressId,
                notes,
                type,
                pickup_address_id: pickupAddressId,
                pickup_time: pickupTime,
                delivery_time: deliveryTime
              } = this.delivery;
        this.editDelivery = { date, deliveryAddressId, notes, type, pickupAddressId, pickupTime, deliveryTime }
      }
    },
    mounted() {
      this.loading = true;
      const pickupAddresses = this.$simplecater.get(`/vendors/${this.delivery.vendor_id}/addresses`);
      const customerAddresses = this.$simplecater.get(`/customers/${this.delivery.customer_id}/addresses`);
      Promise.all([pickupAddresses, customerAddresses]).then((r) => {
        this.pickupAddresses = r[0].data.data;
        this.customerAddresses = r[1].data.data;
        this.loading = false;
      })
    }
  }
</script>

<style lang='sass'>
  .edit-deliveries__form
    .el-select,
    .el-input
      width: 100%
    .dialog-footer
      text-align: right
</style>
