<template lang='pug'>
.main-container

  .view-content

    el-card
      div(slot='header')
        i.mat-icon kitchen
        | Cuisines
        el-button(type="primary", @click="newCuisinePrompt", style='float: right')
          i.mat-icon add_circle
          | Add cuisine

      el-table(:data='cuisines', v-loading='cuisines == null')
        el-table-column(prop='name', label='Name')
        el-table-column(label='Actions')
          template(slot-scope='scope')
            el-button(type='text', size='small', @click='deleteCuisine(scope.row, scope.$index)') Delete
  router-view
</template>

<script>
  import Toolbar from '../layout/Toolbar.vue'
  import _ from 'underscore'

  function data ( ) {
    return {
      cuisines: null
    }
  }

  function created(){
    this.getCuisines()
  }

  function newCuisinePrompt ( ) {
    this.$prompt('Please input the cuisine name', 'Add cuisine', {
      confirmButtonText: 'Create cuisine',
      cancelButtonText: 'Cancel',
    }).then(value => {
      this.createCuisine(value.value)
    }).catch(()=>{

    })
  }

  function getCuisines ( ) {
    return this.$simplecater.get('/cuisines').then((r)=> {
      this.cuisines = _.sortBy(r.data.data, 'name')
    })
  }

  function createCuisine(name){
    const body = {name}
    return this.$simplecater.post('/cuisines/', body).then((r)=>{
      this.$message("The cuisine was created")
      this.getCuisines()
    }).catch(()=>{
      this.$message.error("Failed to create the cuisine")
    })
  }

  function deleteCuisine ( x, idx ) {
    if ( confirm('Are you sure?') ) {
      return this.$simplecater.delete('/cuisines/' + x.id).then( ( r )=> {
        let newCuisines = _.map(this.cuisines, _.id)
        newCuisines.splice(idx, 1)
        this.cuisines = newCuisines
        this.$message("The cuisine was deleted")
      }).catch( ( err ) => {
        this.$message.error('This cuisine cannot be deleted')
      })
    }
  }

  export default {
    methods: { newCuisinePrompt, getCuisines, deleteCuisine, createCuisine },
    components: { Toolbar, },
    created,
    data,
  }
</script>
