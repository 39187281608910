<template>
    <button class="defaut-btn" :disabled="disable" :style="{backgroundColor: btnColor, color: textColor, border: withBorder ? '2px solid ' + borderColor : ''}" @click="$emit('onClick')">
        <slot></slot>
    </button>
</template>

<style scoped lang="sass">
.defaut-btn
    border-radius: 0.75rem
    padding: 0.75rem 1rem
    border: none
    font-size: 1rem
    cursor: pointer
    max-height: 3rem
    min-width: fit-content
</style>

<script>
  export default {
    props: {
        btnColor: {
            Type: String,
            default: '#EB5B13'
        },
        textColor: {
            Type: String,
            default: 'white'
        },
        borderColor: {
            Type: String,
            default: '#EB5B13'
        },
        withBorder: {
            Type: Boolean,
            default: false
        },
        disable: {
            Type: Boolean,
            default: false
        }
    },

   }
</script>
 