<style scoped lang="sass">
.vendor-name
  display: inline-block
  font-weight: 600
  margin: 0 0.5rem
.secondary
  // display: inline-block
  color: #888
  font-size: 0.8em
  margin-left: 1.7rem
  .v-divider
    display: inline-block
    width: 1px
    background: #ccc
    height: 1.5em
    margin: 0 0.5rem
    vertical-align: middle

.select-all 
  position: absolute
  left: 14px
  top: 54px  
  display: flex
  &__label
    z-index: 2
    text-overflow: ellipsis
    white-space: normal
    word-break: break-all
    line-height: 23px
    font-size: 14px
    font-family: "Open Sans", Helvetica, sans-serif
    font-weight: 700
    color: #909399
    margin-left: 6px
    margin-top: -1px
</style>

<template lang='pug'>
.main-container
  
  .view-content
    el-card(v-loading='vendor === null', class='el-card__with-bg')
      div(slot='header')
        i.mat-icon store
        router-link(to='/staff/vendors') Vendors
        |  &gt;
        .vendor-name(v-if='vendor') {{ vendor.name }}
        //- .secondary(v-if='vendor') Code:
        //-   strong  {{ vendor.code }}
          //- i.mat-icon person
          //- | {{ vendor.people }}
          //- .v-divider
          //- span {{$formatCents(vendor.budget_cents)}}/person
          //- .v-divider
          //- em  \#{{ vendor.id }}
      div
        el-card(v-loading='vendor === null')
          div(slot='header')
            i.mat-icon place
            router-link(to='/staff/vendors') Addresses
            el-button(type="primary", @click="newAddress", style='float: right')
              i.mat-icon add_circle
              | Add address
          vendor-addresses(:vendor='vendor', :isInactiveVendorAddresses="deleted", :addresses='addresses', @vendorStatusChanged="fetchData")
        el-card
          div(slot='header')
            i.mat-icon toys
            | Products
            el-button(type="primary", @click="newProduct", style='float: right')
              i.mat-icon add_circle
              | Add product
            el-button(type='primary', @click="toogleBulk", style='float: right; margin-right: 30px', :disabled="selectedProducts.length < 2") Edit Multiple
          el-row(:gutter='10')
            el-col(:span='9')
              el-input(v-model='sortingProducts' prefix-icon="el-icon-search" placeholder="Search" )
              div(class='select-all')
               el-checkbox(v-model='selectedAll')
               span(class='select-all__label') Select All
          el-table(:data='products', v-loading='products == null')
            template(slot='empty')
              | No data
            
            el-table-column(label='')
              template(slot-scope='scope')
                el-checkbox(v-model='scope.row.selected')
            el-table-column(prop='name', label='Name')
            el-table-column(prop='serves', label='Serves')
            el-table-column(label='Unit price')
              template(slot-scope='scope')
                | {{$formatCents(scope.row.sale_price_cents)}}
            el-table-column(label='Actions')
              template(slot-scope='scope')
                el-button(type='text', size='small', @click='editProduct(scope.row.id)') Edit
                el-button(type='text', size='small', @click='deleteProduct(scope.row, scope.$index)') Delete
      
          el-col()
            el-pagination( 
              v-if="pagination.pages > 1"
              @current-change="onPaginationChange"
              :page-size="pagination.per_page", 
              :pager-count="5", 
              layout="prev, pager, next, jumper", 
              :total="pagination.total") 
          vendor-products-bulk-edit(:productList="selectedProducts", :show="showBulk", @close="toogleBulk", :vendorId="vendor ? vendor.id : null")
  router-view
</template>

<script>

import VendorAddresses from './blocks/VendorAddresses.vue'
import VendorProductsBulkEdit from './blocks/VendorProductsBulkEdit.vue'

function data ( )  {
  return {
    vendor: null,
    products: null,
    showBulk: false,
    sortingProducts: '',
    deleted: false,
    addresses: null,
    loading: true,
    selectedAll: false,
    pagination: {
      per_page: null,
      current: null,
      pages: null,
      total: null,
    }
  }
}

function fetchData ( ) {
  this.vendor && (this.vendor = null)
  this.addresses && (this.addresses = null)
  this.$simplecater.get('/vendors/' + this.$route.params.id).then((r)=>{
      this.vendor = r.data.data
    }).catch(()=>{
      this.$message.error("Failed to load the vendor")
    })
  this.$simplecater.get('/vendors/' + this.$route.params.id + '/addresses', { params: { deleted: this.deleted }}).then((r)=>{
      this.addresses = r.data.data
    }).catch(()=>{
      this.$message.error("Failed to load the vendor addresses")
    })
  this.getVendorProducts()
}

function editProduct (id) {
  this.$router.push(`/staff/vendors/${this.$route.params.id}/edit-product/${id}`)
}

  function deleteProduct ( x, idx ) {
    if ( confirm('Are you sure?') ) {
      return this.$simplecater.delete('/products/' + x.id).then( ( r )=> {
        let newProducts = _.map(this.products, _.id)
        newProducts.splice(idx, 1)
        this.products = newProducts
      }).catch( ( err ) => {
        this.$message.error('Failed to delete!')
      })
    }
  }

function getVendorProducts() {
  this.selectedAll = false
  this.products && (this.products = null)
  this.$simplecater.get(`/vendors/${this.$route.params.id}/products`, { params : { ...(this.sortingProducts && { q: this.sortingProducts })}}).then((r) => {
      this.products = r.data.data.map((data) => ({ ...data, selected: false }))
    }).catch(()=>{
      this.$message.error("Failed to load the vendor products")
    })
}

function created ( ) {
  this.fetchData()
  this.$bus.$on('estimate-accepted', this.fetchData)
  this.$bus.$on('address-saved', this.fetchData)
  this.$bus.$on('products-updated', this.fetchData)
}

function newAddress ( ) {
  this.$router.push('/staff/vendors/' + this.$route.params.id + '/addresses/new')
}

function newProduct ( ) {
  this.$router.push('/staff/vendors/' + this.$route.params.id + '/new-product')
}

function selectAllProducts(val) {
  this.products?.forEach(product => {
    product.selected = val
  })
}

function toogleBulk(action) {
  this.showBulk = !this.showBulk
  action?.successfull && this.getVendorProducts()
}

const methods = {
  fetchData,
  newAddress,
  newProduct,
  toogleBulk,
  getVendorProducts,
  editProduct,
  deleteProduct,
  selectAllProducts
}

const watch = {
  deleted() {
    this.fetchData()
  },
  sortingProducts (){
    this.getVendorProducts()
  },
  selectedAll(value) {
    this.selectAllProducts(value)
  }
}

const components = { VendorAddresses, VendorProductsBulkEdit }

const computed = {
  selectedProducts() {
    return (this.products || []).reduce((acc, { selected, id, name }) => selected ? [...acc, { id, name }] : acc, [])
  }
}

// TODO - Re-fetch on route change/update
export default { data, watch, methods, created, computed, components }
</script>

<style lang="sass" scoped>
.el-checkbox
  margin-left: 20px
</style>
