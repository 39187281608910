<template>
<div>
    <el-row>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.drinks" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.drinks : false" title="Drinks"/>
        </el-col>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.desserts" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.desserts : false" title="Desserts"/>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.no_red_meat"  :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.no_red_meat : false" title="No red meat"/>
        </el-col>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.no_pork_products"  :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.no_pork_products : false" title="No pork products"/>
        </el-col>
    </el-row>
    <el-row v-if="!isRequest">
        <el-col :span="12">
            <custome-checkbox v-model="preferences.no_onions"  :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.no_onions : false" title="Light / No onions"/>
        </el-col>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.half_pans"  :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.half_pans : false" title="Half pans"/>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.kosher" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.kosher : false" title="Kosher options"/>
        </el-col>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.light_food" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.light_food : false" title="Light food"/>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.set_up" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.set_up : false" title="Set up"/>
        </el-col>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.utensils" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.utensils : false" title="Utensils"/>
        </el-col>
    </el-row><template v-if="isRequest">
        <el-row>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.lunch_box" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.lunch_box : false" title="Lunch box / IND"/>
            </el-col>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.hot_food" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.hot_food : false" title="Hot food"/>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.include_salad" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.include_salad : false" title="Include salad"/>
            </el-col>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.vegetarian_options" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.vegetarian_options : false" title="Vegetarian options"/>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.vegan_options" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.vegan_options : false" title="Vegan options"/>
            </el-col>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.no_dairy" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.no_dairy : false" title="No dairy"/>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.gluten_free_needed" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.gluten_free_needed : false" title="Gluten free needed"/>
            </el-col>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.no_seafood" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.no_seafood : false" title="No fish/seafood"/>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.not_spicy" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.not_spicy : false" title="Not spicy"/>
            </el-col>
            <el-col :span="12">
                <custome-checkbox v-model="preferences.staff_needed" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.staff_needed : false" title="Staff needed"/>
            </el-col>
        </el-row>
    </template>
    <el-row>
        <el-col :span="12">
            <custome-checkbox v-model="preferences.healthy" :squareShapes="squareShapes" :initialValue="!newRequest ? preferences.healthy : false" title="Healthy options"/>
        </el-col>
    </el-row>
</div>
</template>



<script>
import CustomeCheckbox from '@/user/blocks/CustomeCheckbox.vue';

  const defaultPreferences = {
    drinks: false,
    desserts: false,
    no_red_meat: false,
    no_pork_products: false,
    healthy: false,
    half_pans: false,
    kosher: false,
    light_food: false,
    set_up: false,
    utensils: false,
    lunch_box: false,
    hot_food: false,
    include_salad: false,
    vegetarian_options: false,
    vegan_options: false,
    no_dairy: false,
    gluten_free_needed: false,
    no_seafood: false,
    not_spicy: false,
    staff_needed: false,
    no_onions: false
  }

  function data(){
    const freshClone = Object.assign({}, defaultPreferences, this.initialValue)
    return { preferences: freshClone }
  }

  const props ={
    initialValue: {
      required: true
    },
    isRequest: {
      default: false
    },
    newRequest: {
        type: Boolean
    },
    squareShapes: {
        require: false,
        default: false
    }
  }

  const components = { CustomeCheckbox }

  const watch = {
    initialValue: function (newValue){
      console.log("***** INITIAL VAL CHANGED", newValue)
      const freshClone = Object.assign({}, defaultPreferences, this.initialValue)
      this.preferences = freshClone
    },
  }

  export default { props, data, watch, components }
</script>
