
<template>
<drawer :dismissRoute="dismissRoute">
  <div class="drawer-title"><i class="mat-icon">add_circle</i>{{requestTitle}}</div>
  <div class="request-builder-container">
    <div v-if="isMultiRequest && this.multiRequestData.length > 1" class="multi-requets-tabs">
          <div v-for="(req, index) in multiRequestData" :class="{ activeTabTitle: req.isActive }">
             <div @click="selectTab(index)" class="multi-event-tab">
              <!-- <custome-checkbox class="preference" :title="req.title" :disable="true"/> -->
              {{ req.title }}
              <div v-if="index !== 0" class="cancel-req" @click="cancelMultiRequest(index)">
                <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11 1L1 11M1 1L11 11" stroke="#EB5B13" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
             </div>
          </div>
    </div>
    <div v-if="!isMultiRequest">
    <div>
      <el-autocomplete class="new-request__search" v-if="isStaff &amp;&amp; !customerId &amp;&amp; !$route.params.id" v-model="currentCustomer" :fetch-suggestions="searchCustomer" prefix-icon="el-icon-search" placeholder="Please, input customer name" @select="handleSearch"></el-autocomplete>
      <el-form v-else="v-else" :label-width="$labelWidth" label-position="right" ref="requestForm" v-loading="loadingForm" :model="request" :rules="rules">
        <el-form-item label="Event name" prop="name">
            <el-input v-model="request.name" placeholder="Annual board meeting"></el-input>
        </el-form-item><!-- PEOPLE-->
        <el-form-item label="Number of people" prop="people">
            <el-input-number v-model="request.people" :min="1"></el-input-number>
        </el-form-item><!-- BUDGET-->
        <el-form-item label="Budget per person" prop="budget">
            <el-input v-model="request.budget"><template slot="prepend">$</template></el-input>
        </el-form-item><!-- CUISINES-->
        <el-form-item label="Preferred cuisines" prop="cuisine_ids">
            <el-select class="full-width-select" v-model="request.cuisine_ids" multiple="" placeholder="Select up to 3" :multipleLimit="3">
                <el-option v-for="item in cuisines" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item><!-- ADDRESS-->
        <el-form-item label="Delivery address" prop="address_id">
            <el-col :span="17">
                <el-select v-model="request.address_id" filterable="filterable" placeholder="Select an option">
                    <el-option v-for="item in addresses" :key="item.id" :value="item.id" :label="item.name || item.address_line_1"><span>{{item.name || item.address_line_1}}</span>
                        <el-tooltip :content="fullAddress(item)" placement="left">&nbsp;<i class="mat-icon secondary">info</i></el-tooltip>
                    </el-option>
                    <el-option class="select-special-action" label="Create new address" value="new"></el-option>
                </el-select>
            </el-col>
            <el-col :span="7">
                <el-form-item v-show="request.address_id &amp;&amp; !creatingAddress" prop="suite">
                    <el-input v-model="request.suite" placeholder="suite">{}</el-input>
                </el-form-item>
            </el-col>
        </el-form-item>
        <section-separator v-show="creatingAddress" label="New address information">
            <address-builder ref="addressForm" has-name="has-name" label-position="right" :label-width="$labelWidth" :customerId="request.customer_id"></address-builder>
        </section-separator><!-- DATE-->
        <el-form-item label="Delivery date" prop="delivery_date">
            <el-date-picker :value="request.delivery_date" placeholder="Select a date" :pickerOptions="datePickerOptions" value-format="yyyy-MM-dd" @input="changeDeliveryDate"></el-date-picker>
        </el-form-item><!-- TIME-->
        <el-form-item label="Delivery time" prop="delivery_time">
            <el-time-picker type="date" placeholder="Select a time" v-model="request.delivery_time" :picker-options="timePickerOptions" format="hh:mm A" value-format="HH:mm"></el-time-picker>
        </el-form-item><!-- CONTACT-->
        <el-form-item label="Contact for this request" prop="contact_id">
            <el-select v-model="request.contact_id" placeholder="Select an option">
                <el-option v-for="item in contacts" :key="item.id" :label="item.first_name + &quot; &quot; + item.last_name" :value="item.id"></el-option>
                <el-option class="select-special-action" label="Create new contact" value="new"></el-option>
            </el-select>
        </el-form-item>
        <section-separator v-show="creatingContact" label="New contact information">
            <contact-builder ref="contactForm" :customerId="request.customer_id" label-position="right" :label-width="$labelWidth"></contact-builder>
        </section-separator><!-- NOTES-->
        <el-form-item label="Custom customer PO" prop="customerPO">
            <el-input v-model="order.customer_po"></el-input>
        </el-form-item>
        <el-form-item label="Anything we should know?" prop="notes">
            <el-input type="textarea" v-model="request.notes" max="250" :autosize="{ minRows: 2, maxRows: 7 }"></el-input>
        </el-form-item>
        <el-form-item v-if="order.pickup_address_id" label="Pickup Address" prop="pickupAddressId">
            <el-select v-model="order.pickup_address_id" placeholder="Select an option">
                <el-option v-for="item in pickupAddresses" :key="item.id" :label="item.name || item.address_line_1" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="order.food_ready_time" label="Food ready time" prop="foodReadyTime">
            <el-time-picker v-model="order.food_ready_time" placeholder="Select a time" format="hh:mm A" value-format="HH:mm"></el-time-picker>
        </el-form-item>
        <el-form-item v-if="order.vendor_order_notes" prop="vendorNotes" label="Staff notes on order">
            <el-input v-model="order.vendor_order_notes" placeholder="Add notes"></el-input>
        </el-form-item>
        <el-form-item v-if="order.purchase_order_notes" prop="purchaseNotes" label="Notes to vendor">
            <el-input v-model="order.purchase_order_notes" placeholder="Add notes"></el-input>
        </el-form-item>
        <el-collapse>
            <el-collapse-item name="preferences"><template slot="title">
                    <el-form-item label="Preferences"><span class="collapse-item__tip">Click to expand</span></el-form-item>
                </template>
                <el-form-item prop="preferences">
                    <preferences-builder :initialValue="preferences" ref="preferencesForm" :is-request="true"></preferences-builder>
                </el-form-item>
            </el-collapse-item>
        </el-collapse><!-- BUTTONS-->
        <div class="request-footer">
            <el-button type="button" @click="addMultiRequest()">
              Next request
            </el-button>
            <div>
              <el-button type="primary" @click="onSubmit" :loading="submitting">Save</el-button>
              <el-button @click="onCancel">Cancel</el-button>
            </div>
        </div>
    </el-form>
    </div>
    </div>
    <div v-else>
      <div v-for="(event, index) in multiRequestData">
        <div v-show="event.isActive">
        <el-form  :label-width="$labelWidth" label-position="right" :ref="'requestForm' + index" v-loading="loadingForm" :model="event.data" :rules="rules">
          <el-form-item label="Event name" prop="name">
            <el-input v-model="event.data.name" placeholder="Annual board meeting" @input="event.title = $event"></el-input>
        </el-form-item><!-- PEOPLE-->
        <el-form-item label="Number of people" prop="people">
            <el-input-number v-model="event.data.people" :min="1"></el-input-number>
        </el-form-item><!-- BUDGET-->
        <el-form-item label="Budget per person" prop="budget">
            <el-input v-model="event.data.budget"><template slot="prepend">$</template></el-input>
        </el-form-item><!-- CUISINES-->
        <el-form-item label="Preferred cuisines" prop="cuisine_ids">
            <el-select class="full-width-select" v-model="event.data.cuisine_ids" multiple="" placeholder="Select up to 3" :multipleLimit="3">
                <el-option v-for="item in cuisines" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
        </el-form-item><!-- ADDRESS-->
        <el-form-item label="Delivery address" prop="address_id">
            <el-col :span="17">
                <el-select v-model="event.data.address_id" filterable="filterable" placeholder="Select an option">
                    <el-option v-for="item in addresses" :key="item.id" :value="item.id" :label="item.name || item.address_line_1"><span>{{item.name || item.address_line_1}}</span>
                        <el-tooltip :content="fullAddress(item)" placement="left">&nbsp;<i class="mat-icon secondary">info</i></el-tooltip>
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="7">
                <el-form-item v-show="event.data.address_id &amp;&amp; !creatingAddress" prop="suite">
                    <el-input v-model="event.data.suite" placeholder="suite">{}</el-input>
                </el-form-item>
            </el-col>
        </el-form-item>
        <section-separator v-show="creatingAddress" label="New address information">
            <address-builder ref="addressForm" has-name="has-name" label-position="right" :label-width="$labelWidth" :customerId="event.data.customer_id"></address-builder>
        </section-separator><!-- DATE-->
        <el-form-item label="Delivery date" prop="delivery_date">
            <el-date-picker :value="event.data.delivery_date" v-model="event.data.delivery_date" placeholder="Select a date" :pickerOptions="datePickerOptions" value-format="yyyy-MM-dd" @input="changeDeliveryDate"></el-date-picker>
        </el-form-item><!-- TIME-->
        <el-form-item label="Delivery time" prop="delivery_time">
            <el-time-picker type="date" placeholder="Select a time" v-model="event.data.delivery_time" :picker-options="timePickerOptions" format="hh:mm A" value-format="HH:mm"></el-time-picker>
        </el-form-item><!-- CONTACT-->
        <el-form-item label="Contact for this request" prop="contact_id">
            <el-select v-model="event.data.contact_id" placeholder="Select an option">
                <el-option v-for="item in contacts" :key="item.id" :label="item.first_name + &quot; &quot; + item.last_name" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <section-separator v-show="creatingContact" label="New contact information">
            <contact-builder ref="contactForm" :customerId="event.data.customer_id" label-position="right" :label-width="$labelWidth"></contact-builder>
        </section-separator><!-- NOTES-->
        <el-form-item label="Custom customer PO" prop="customerPO">
            <el-input v-model="event.data.customer_po"></el-input>
        </el-form-item>
        <el-form-item label="Anything we should know?" prop="notes">
            <el-input type="textarea" v-model="event.data.notes" max="250" :autosize="{ minRows: 2, maxRows: 7 }"></el-input>
        </el-form-item>
        <el-form-item v-if="order.pickup_address_id" label="Pickup Address" prop="pickupAddressId">
            <el-select v-model="order.pickup_address_id" placeholder="Select an option">
                <el-option v-for="item in pickupAddresses" :key="item.id" :label="item.name || item.address_line_1" :value="item.id"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item v-if="order.food_ready_time" label="Food ready time" prop="foodReadyTime">
            <el-time-picker v-model="order.food_ready_time" placeholder="Select a time" format="hh:mm A" value-format="HH:mm"></el-time-picker>
        </el-form-item>
        <el-form-item v-if="order.vendor_order_notes" prop="vendorNotes" label="Staff notes on order">
            <el-input v-model="order.vendor_order_notes" placeholder="Add notes"></el-input>
        </el-form-item>
        <el-form-item v-if="order.purchase_order_notes" prop="purchaseNotes" label="Notes to vendor">
            <el-input v-model="order.purchase_order_notes" placeholder="Add notes"></el-input>
        </el-form-item>
        <el-collapse>
            <el-collapse-item name="preferences"><template slot="title">
                    <el-form-item label="Preferences"><span class="collapse-item__tip">Click to expand</span></el-form-item>
                </template>
                <el-form-item prop="preferences">
                    <preferences-builder :initialValue="preferences" :ref="'preferencesForm' + index" :is-request="true"></preferences-builder>
                </el-form-item>
            </el-collapse-item>
        </el-collapse><!-- BUTTONS-->
          <div class="request-footer">
            <div class="multirequest-subbottom">
            <el-button type="button" @click="addMultiRequest()">
              Next request
            </el-button>
          </div>
          <div>
            <el-button type="primary" @click="submitMultiRerquest(index)" :loading="submitting">Save</el-button>
            <el-button @click="onCancel">Cancel</el-button>
          </div>
          </div>
        </el-form>
        </div>
      </div>
    </div>
  </div>
</drawer>
</template>

<style scoped lang="sass">
.multi-event-tab
  display: flex
  justify-content: space-between
  align-items: center

.cancel-req
  margin-top: .3125rem
  cursor: pointer
.collapse-item__tip
  font-style: italic
  opacity: 0.8
  display: inline-block
  float: right
  margin-right: 1rem
  font-weight: 600
  color: $colorPrimary
  &:hover
    opacity: 1

.drawer .el-collapse-item__header, .drawer .el-collapse-item__wrap
  background-color: #FAFAFA

.request-builder-container
  display: flex
  flex-direction: row

.multi-requets-tabs
  min-width: 17.75rem
  margin-right: 2rem
  padding-right: 2rem
  border-right: 1px solid #EBEBE4
  display: flex
  flex-direction: column
  gap: .625rem

.multirequest-subbottom
  display: flex

.request-footer
  display: flex
  flex-direction: row
  justify-content: space-between   
  margin-top: 2rem   
</style>


<script>
import ContactBuilder from "../../user/blocks/ContactBuilder.vue";
import AddressBuilder from "../../common/AddressBuilder.vue";
import PreferencesBuilder from "../../user/blocks/PreferencesBuilder.vue";
import Vue from "vue";
import { filteredDropdown } from "@/mixins/filteredDropdown";
import _ from "underscore";

const mixins = [
    filteredDropdown('cuisines', (input) => ({ name }) => name.toLowerCase().includes(input.toLowerCase())),
    filteredDropdown('contacts', (input) => ({ first_name, last_name }) =>  `${first_name} ${last_name}`.toLowerCase().includes(input.toLowerCase())),
    filteredDropdown('addresses', (input) => ({ name, address_line_1 }) => (name || address_line_1).toLowerCase().includes(input.toLowerCase()))
  ]

function emptyRequest() {
  return {
    notes: "",
    cuisine_ids: [],
    people: null,
    budget: null,
    contact_id: null,
    customer_id: null,
    address_id: null,
    delivery_date: null,
    delivery_time: null,
    suite: null
  };
}

function fullAddress({ zip_code, state, city, address_line_1, address_line_2 }) {
  return `${address_line_1}${address_line_2 && `, ${address_line_2}`}, ${city}, ${state}, ${zip_code}`
}

async function getAddressInfo(addressId = this.request.address_id) {
  if (addressId && addressId !== 'new') {
    const adress_request = await this.$simplecater.get(`/addresses/${addressId}`)
    const address_info = adress_request.data.data
    this.address_info = address_info
    this.request.suite = address_info.address_line_2
  }
}

function loadRequest() {
  const editId = this.$route.params.id;
  this.requestTitle = editId ? "Edit" : "New Request";
  if(this.$route.matched[2].path === '/user/requests/:id/edit-req') {
    this.dismissRoute = `/user/requests/${editId}`;
  } else {
    this.dismissRoute = this.$route.matched[1].path;
  }
  if (editId) {
    this.$simplecater
      .get("/requests/" + editId)
      .then(async r => {
        const data = r.data.data;
        await this.getAddressInfo(data.address_id)
        this.request = {
          address_id: data.address_id,
          budget: data.budget_cents / 100,
          contact_id: data.contact_id,
          customer_id: data.customer_id,
          cuisine_ids: data.cuisines.map(e => e.id).filter(e => e !== 0),
          delivery_date: data.delivery_date,
          delivery_time: data.delivery_time,
          notes: data.notes,
          people: data.people,
          name: data.name,
          suite: data.suite || this.address_info?.address_line_2
        };
        this.preferences = data.preferences;
        this.order.customer_po = data.customer_po;

        if (this.isStaff) {
          this.getAddresses(this.request.customer_id);
          this.getContacts(this.request.customer_id);
        }
      })
      .catch(() => this.$message.error("Failed to load order's request"));
  }
}

function loadOrder() {
  return this.$simplecater.get(`/orders/${this.orderId}`).then(r => {
      this.order = r.data.data;
      this.order.pickup_address_id && this.getPickupAddresses()
    })
    .catch(() => {
      this.$message.error("Failed to load the order");
    });
}

function sendOrder() {
  const params = {};
  this.order.pickup_address_id
    ? (params.pickup_address_id = this.order.pickup_address_id)
    : null;
  this.order.delivery_date
    ? (params.delivery_date = this.order.delivery_date)
    : null;
  this.order.vendor_order_notes
    ? (params.vendor_order_notes = this.order.vendor_order_notes)
    : null;
  this.order.purchase_order_notes
    ? (params.purchase_order_notes = this.order.purchase_order_notes)
    : null;
  this.order.food_ready_time
    ? (params.food_ready_time = this.order.food_ready_time)
    : null;
  this.order.customer_po ? (params.customer_po = this.order.customer_po) : "";
  return this.$simplecater.put(`/orders/${this.order.id}`, params);
}

function changeDeliveryDate(value) {
  this.order.delivery_date = value;
  this.request.delivery_date = value;
}

function buildFormRules(componentInstance) {
  return {
    name: {
      required: true,
      message: "Please enter a name for your request",
      trigger: "blur"
    },
    notes: {
      max: 250,
      message: "Notes cannot exceed 250 characters",
      trigger: "change"
    },
    cuisine_ids: {
      type: "array",
      required: true,
      trigger: "change",
      message: "Please select at least one cuisine"
    },
    people: {
      required: true,
      type: "integer",
      message: "Please input the number of people",
      trigger: "blur"
    },
    budget: {
      required: true,
      type: "number",
      message: "Please input a valid budget per person in dollars",
      trigger: "blur",
      transform: parseFloat
    },
    contact_id: [
      { validator: validateContact.bind(componentInstance), trigger: "change" },
      { required: true, trigger: "change" }
    ],
    address_id: [
      { validator: validateAddress.bind(componentInstance), trigger: "change" },
      { required: true, trigger: "change" }
    ],
    delivery_date: {
      required: true,
      message: "Please pick a date",
      trigger: "change"
    },
    delivery_time: {
      required: true,
      message: "Please pick a time",
      trigger: "change",
      pattern: /\d{2}:\d{2}/
    }
  };
}

function data() {
  return {
    isStaff: this.$route.matched[0].path === "/staff",
    isMultiRequest: false,
    multiRequestData: [],
    requestTitle: "",
    dismissRoute: "",
    rules: buildFormRules(this),
    skipNestedFormsValidation: true,
    loadingForm: true,
    displayContactForm: false,
    displayAddressForm: false,
    cuisines: [],
    contacts: [],
    addresses: [],
    pickupAddresses: [],
    order: {
      customer_po: ''
    },
    dataForSubmit: null,
    submitting: false,
    request: emptyRequest(),
    preferences: {},
    isContactValid: false,
    datePickerOptions: {
      disabledDate(time) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);
        return time < yesterday;
      }
    },
    timePickerOptions: {
      selectableRange: "07:00:00 - 19:30:00"
    },
    currentCustomer: '',
    customerId: null,
    customers: [],
    timeout:  null,
    address_info: null,
    chengedSuite: false,
    initialSuiteInfo: true
  };
}

function created() {
  if (!this.isStaff) {
    this.getUserData();
  } else if (this.$route.params.id) {
    this.getStaffData()
  }
  this.loadRequest()
  // TODO add catch
}

function getUserData() {
  Promise.all([
    this.getContacts(),
    this.getAddresses(),
    this.getPreferences(),
    this.getCuisines(),
  ]).then(() => {
    console.log("RESOLVED!");
    this.loadingForm = false;
  });
}

function handleSearch(item) {
  this.customerId = item.link;
  this.request.customer_id = item.link;
  this.getStaffData();
}

function searchCustomer(queryString, cb) {
    if (queryString) {
      const request = `/customers?account_name=${queryString}`;
      this.getCustomers(request).then(() => {
        clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          cb(this.customers);
        }, 500);
      })
    }
  }

function getCustomers(filterName) {
  return this.$simplecater.get(filterName).then((r)=>{
    this.customers = r.data.data.map(item => ({value: item.account_name, link: item.id}))
  });
}

function getStaffData() {
  Promise.all([
    this.getCuisines(),
    ...(this.orderId ? [this.loadOrder()] : []),
    ...(this.customerId ? [this.getContacts(this.customerId), this.getAddresses(this.customerId)] : [])
  ]).then(() => {
    this.loadingForm = false;
  })
}

function getCuisines() {
  return this.$simplecater.get("/cuisines").then(r => {
    this.cuisines = _.sortBy(r.data.data, x => x.name.toLowerCase());
  });
}

function getAddresses(id) {
  const apiUrl = id ?
  `${this.isStaff ? `/customers/${id}` : '' }/addresses${!this.isStaff ? `/${id}` : ''}`
  : "/addresses";
  return this.$simplecater.get(apiUrl).then(r => {
    if (this.isStaff) {
      this.addresses = r.data.data;
    } else {
      this.addresses = _.sortBy(r.data.data, "address_line_1");
    }
  });
}

function getPickupAddresses() {
  this.$simplecater
    .get(`/vendors/${this.order.vendor_id}/addresses`)
    .then(r => {
      this.pickupAddresses = r.data.data;
    });
}

function getPreferences() {
  this.$simplecater.get("/preferences").then(r => {
    this.preferences = r.data.data;
    this.request.budget = this.preferences.budget_cents / 100;
  });
}

function getContacts(id) {
  const apiUrl = id ? `/customers/${id}/contacts` : "/contacts";
  return this.$simplecater.get(apiUrl).then(r => {
    this.contacts = _.sortBy(r.data.data, x => x.first_name.toLowerCase());
  });
}

function onCancel() {
  this.$router.push(this.dismissRoute);
}

function validateContact(rule, value, callback) {
  if (value === null) return callback(new Error("Please select an option"));
  if (value === "new") {
    if (this.skipNestedFormsValidation) return callback();
    this.$refs.contactForm.validate(callback);
  } else {
    if (Number.isInteger(value)) {
      callback();
    } else {
      callback(new Error("Please give a valid id"));
    }
  }
}

function validateAddress(rule, value, callback) {
  if (value === null) return callback(new Error("Please select an option"));
  if (value === "new") {
    if (this.skipNestedFormsValidation) return callback();
    this.$refs.addressForm.validate(callback);
  } else {
    if (Number.isInteger(value)) {
      callback();
    } else {
      callback(new Error("Please give a valid id"));
    }
  }
}

function createContact() {
  console.log("Creating contact");
  return this.$refs.contactForm.create().then(newContact => {
    if(this.dataForSubmit) this.dataForSubmit.contact_id = newContact.id;
    this.getContacts(this.request.customer_id)
    this.request.contact_id = newContact.id;
  });
}

function createAddress() {
  console.log("Creating address");
  return this.$refs.addressForm.create().then(newAddress => {
    if(this.dataForSubmit) this.dataForSubmit.address_id = newAddress.id;
    this.getAddresses(this.request.customer_id)
    this.request.suite = newAddress.address_line_2 ?? request.suite;
    this.request.address_id = newAddress.id;
  });
}

// This the click event handles, only triggers submission if validations pass
function onSubmit() {
  this.skipNestedFormsValidation = false;
  this.$refs.requestForm.validate(valid => {
    if (valid) {
      this.submitForm(this.request.customer_id);
    } else {
      this.$message.error("Some validations failed, please check your inputs");
      return false;
    }
  });
}

function submitMultiRerquest (index) {
  const currentForm = this.$refs['requestForm' + (this.multiRequestData.length - 1)]
  const refsList = this.$refs
    currentForm[0].validate(valid => {
      if(valid) {
        const pureData = this.multiRequestData.map((item, i) => {
          if(i === 0) {
            const budget_cents = Math.round(item.data.budget * 100);
            return {...item.data, budget_cents, preferences: this.initialPreferences}
          } else {
            const preferences = refsList['preferencesForm' + i][0].preferences
            const budget_cents = Math.round(item.data.budget * 100);
            return {...item.data, budget_cents, preferences}
          }
        })
      
        this.$simplecater.post(`/customers/${this.customerId}/requests/multiple`, {requests: pureData})
        .then(r => {
        // SUBMIT SUCCEEDED
          this.$bus.$emit("request-created");
          this.$router.push(this.dismissRoute);
        })
        .catch(()=>{
          this.$message.error("It looks like you have an incompleted request.")       
          //this.$handleErrorFor("Creating request").bind(this)
        })
        .finally(() => {
          this.submitting = false;
        });
      } else {
        this.multiRequestData.forEach(req => req.isActive = false)
        this.multiRequestData[this.multiRequestData.length - 1].isActive = true
        this.$message.error("It looks like you have an incompleted request.")
        return false
      }
    })
}

function addMultiRequest(index) {
  if (!this.isMultiRequest) {
  this.skipNestedFormsValidation = false;
  this.$refs.requestForm.validate(valid => {
    if (valid) {
        let promises = [];
        if (this.creatingContact) promises.push(this.createContact());
        if (this.creatingAddress) promises.push(this.createAddress());
        Promise.all(promises).then((res) => {
          const requestData = this.$refs.requestForm.model
          const preferences = this.$refs.preferencesForm?.preferences
          this.initialPreferences = preferences
          this.multiRequestData.push({title: requestData.name, data: {...requestData, preferences, customer_po: this.order.customer_po}, isActive: false})
          this.multiRequestData.push({title: 'New request', data: {...requestData, delivery_date: null, delivery_time: null, name: null, preferences, customer_po: this.order.customer_po}, isActive: true})
          this.isMultiRequest = true
          document.querySelector('.drawer__content').scrollTop = 0
        });      
    } else {
      this.$message.error("Some validations failed, please check your inputs")
      return false
    }
  });
  } else {
    const currentForm = this.$refs['requestForm' + (this.multiRequestData.length - 1)]
    currentForm[0].validate(valid => {
      if(valid) {
      const preferences = this.$refs['preferencesForm' + index]?.preferences
      this.multiRequestData.push({title: 'New request', data: {...this.multiRequestData[0].data, delivery_date: null, delivery_time: null, name: '', preferences}, isActive: true})
      this.multiRequestData = this.multiRequestData.map(req => {
        return {...req, isActive: false}
      })
      this.multiRequestData[this.multiRequestData.length - 1].isActive = true
      document.querySelector('.drawer__content').scrollTop = 0
    } else {
      this.$message.error("Some validations failed, please check your inputs");
      this.multiRequestData = this.multiRequestData.map(req => {
        return {...req, isActive: false}
      })
      this.multiRequestData[this.multiRequestData.length - 1].isActive = true
      return false;
    }
    })
  }
}

// This the actual submission after validation
function submitForm() {
  this.submitting = true;
  let promises = [];
  // We copy the request, because setting contact or address id would produce
  // unwanted UI changes (forms disappearing, unexpected select values...)
  this.dataForSubmit = Object.assign({}, this.request);
  if(!this.chengedSuite) {
    this.dataForSubmit.suite = null;
  }
  if (this.order.customer_po)
    this.dataForSubmit.customer_po = this.order.customer_po;
  this.dataForSubmit.preferences = Object.assign(
    {},
    this.$refs.preferencesForm.preferences
  );
  this.dataForSubmit.budget_cents = Math.round(this.request.budget * 100);
  this.dataForSubmit.delivery_date = this.dataForSubmit.delivery_date;
  // Do we have to create a contact?
  if (this.creatingContact) promises.push(this.createContact());
  // Do we have to create an address?
  if (this.creatingAddress) promises.push(this.createAddress());
  if (this.orderId) promises.push(this.sendOrder());
  if (!this.isStaff) delete this.dataForSubmit.customer_id
  Promise.all(promises).then(() => {
    const id = this.$route.params.id;
    this.dataForSubmit.address_id = this.request.address_id
    this.dataForSubmit.contact_id = this.request.contact_id
    const getRequestType = () => {
      if (id) {
        return this.$simplecater.put(`/requests/${id}`, this.dataForSubmit);
      } else if(this.isStaff) {
        return this.$simplecater.post(`/customers/${this.customerId}/requests`, this.dataForSubmit);
      } else {
        return this.$simplecater.post('/requests/', this.dataForSubmit);
      }
    };
    getRequestType()
      .then(r => {
        // SUBMIT SUCCEEDED
        const message =
          this.isStaff || id ? "Data updated" : "Your request has been sent";
        this.$bus.$emit("request-created");
        this.$message(message);
        // RESET FIELDS
        this.request = emptyRequest();
        Vue.nextTick(() => {
          this.$router.push(this.dismissRoute);
        });
      })
      .catch(
        // SUBMIT FAILED
        this.$handleErrorFor("Creating request").bind(this)
      )
      .finally(() => {
        this.submitting = false;
      });
  });
}

function updatePreferences(newValue) {
  console.log("NEW PREFS!", newValue);
}

const computed = {
  creatingAddress: function() {
    return this.request.address_id == "new";
  },
  creatingContact: function() {
    return this.request.contact_id == "new";
  },
  orderId() {
    return this.$route.query.orderId ? this.$route.query.orderId : "";
  },
  suite() {
    return this.request.suite;
  },
  addressId() {
    return this.request.address_id;
  }  
};

function selectTab(index) {
  this.multiRequestData.forEach((tab, tabIndex) => {
  tab.isActive = tabIndex === index;
  });
}

function cancelMultiRequest (index) {
  setTimeout(()=>{
    this.multiRequestData.forEach(req => req.isActive = false)
    this.multiRequestData[index - 1].isActive = true
    this.multiRequestData.splice(index, 1)
  }, 0)
}

const components = { ContactBuilder, AddressBuilder, PreferencesBuilder };

const methods = {
  handleSearch,
  searchCustomer,
  getCustomers,
  getContacts,
  getAddresses,
  getCuisines,
  getPreferences,
  submitForm,
  getPickupAddresses,
  getAddressInfo,
  onCancel,
  onSubmit,
  createContact,
  validateContact,
  validateAddress,
  sendOrder,
  createAddress,
  updatePreferences,
  loadRequest,
  getUserData,
  getStaffData,
  loadOrder,
  changeDeliveryDate,
  fullAddress,
  submitMultiRerquest,
  addMultiRequest,
  selectTab,
  cancelMultiRequest
};

let watch = {
  suite: function ( newVal ) {
    if (newVal !== this.address_info?.address_line_2) {
      this.chengedSuite = true
    }
    if(!newVal) {
     this.chengedSuite = false
    }
  },
  addressId: async function ( val ) {
    if(!this.initialSuiteInfo && val || this.requestTitle === "New Request") await this.getAddressInfo()
    this.initialSuiteInfo = false;
  }
}

export default { mixins, data, created, components, methods, computed, computed, watch };
</script>

<style lang="sass" scoped>
.new-request__search-cont
  padding: 0 0 30px

.new-request__search
  width: 100%
</style>
