<style scoped lang="sass">
$bgImage: '../assets/11.png'

.login-container
  font-family: 'Inter'
  display: flex
  width: 100%
  height: 100vh
  align-items: start
  .login-left, .login-right
    height: 100%
  .login-left
    position: relative
    width: 60%
    .login-form-wrapper
      max-width: 420px
      display: flex
      flex-direction: column
      width: 100%
      height: 100%
      justify-content: space-around
      //align-items: center
      .sign-in-title
        font-weight: 500
        font-size: 1.75rem
        line-height: 100%
    .left-logo
      align-self: center
      height: 3.5rem
      //margin-bottom: 40px
      align-items: center
      display: flex
      justify-content: center
      img
        height: 120%
  .login-right
    width: 40%
    display: flex
    align-items: center
    position: relative
    overflow: hidden
    &.login-1
      background: #{$colorMenu} url($bgImage)
      background-size: cover
      .login-right-content
        border-radius: 24px
        position: relative
        width: 100%
        height: 100%
        text-align: center
        background: rgba($colorMenu, 0.75)
        display: flex
        align-items: center
        width: 100%
        text-align: center
        .width-wrapper
          width: 100%
        .logo-box
          width: 60%
          max-width: 400px
          display: inline-block
          background: transparent
          padding: 3rem 2rem
          overflow: hidden
          position: relative
          border-radius: 8px
          box-shadow: 0 0 10px 2px rgba(black, 0.1)
          .inner-content
            position: relative
            &:before
              content: ''
              position: absolute
              width: 100%
              left: 0
              height: 0px
              top: 50%
              background: white
              // box-shadow: 0 0 100px 110px rgba(white, 0.9)
            img
              position: relative
              max-width: 100%
            .slogan
              position: relative
              font-size: 1.1rem
              padding-top: 0.5rem
              font-weight: 400
              color: darken($colorMenu, 15%)
          .login-right-cover
            position: absolute
            top: 0
            bottom: 0
            right: 0
            left: 0
            filter: blur(8px)
            transform: scale(1.3)
            background: #{$colorMenu} url($bgImage)
            background-size: cover
            // opacity: 0.95
            &:before
              background: rgba(white, 0.84)
              content: ''
              position: absolute
              top: 0
              bottom: 0
              right: 0
              left: 0

    &.login-2
      background-color: #fff
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      .login-right-content
        position: relative
        width: 100%
        height: 100%
        text-align: center
        width: 100%
        .width-wrapper
          position: absolute
          width: 100%
          bottom: -5px
        .logo-box
          width: 100%
          display: inline-block
          background: rgba($colorMenu, 0.93)
          padding: 2rem 2rem
          overflow: hidden
          position: relative
          box-shadow: 0 0 10px 5px rgba(black, 0.2)
          .inner-content
            position: relative
            text-align: left
            .slogan
              position: relative
              font-size: 1.2rem
              font-weight: 300
              color: rgba(white, 0.95)

.footer-logo
  width: 130px
  align-self: center

@media screen and (max-height: 560px) 
  .footer-logo
    display: none

  .login-container
    .login-left
      .left-logo
        img  
          height: 80%
  .login-container 
    .login-left 
      .login-form-wrapper 
        .sign-in-title
          line-height: 0
.login-img
  max-width: 100%             
</style>

<template>
  <div class="login-container login-content" v-if="!resetPassView">
    <div class="login-left">
      <div class="el-card login-form-card">
        <div class="login-form-wrapper">
          <div class="left-logo" v-if="option === 2">
            <img src="../assets/logo-new-4.svg" />
          </div>
          <div>
            <h2 class="sign-in-title">Sign In</h2>
            <login-form @resetPasswordClick="resetPassView = true"></login-form>
          </div>
          <img src="../assets/logo-new-4.svg" class="footer-logo" />
        </div>
      </div>
    </div>
    <div class="login-right" :class="'login-' + option">
      <img class="login-img" src="../assets/11.png" alt="">
      <div class="login-right-content" v-if="option === 1">
        <div class="width-wrapper">
          <div class="logo-box">
            <div class="login-right-cover"></div>
            <div class="inner-content">
              <img src="../assets/logo-new-2.svg" />
              <div v-html="'../assets/logo-new-2.svg'"></div>
              <div class="slogan">Catering, tailor made.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="login-right-content" v-if="option === 2"></div>
    </div>
    <router-view></router-view>
  </div>
  <div class="login-container login-content" v-else>
    <reset-password @backClick="resetPassView = false"> </reset-password>
  </div>
</template>

<script>
import LoginForm from "./blocks/LoginForm.vue";
import ResetPassword from "./blocks/ResetPassword.vue";
export default {
  components: { LoginForm, ResetPassword },
  data() {
    return {
      option: 2,
      resetPassView: false,
    };
  },
};
</script>
