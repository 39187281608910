import VueRouter from 'vue-router'
import auth from './auth.js'
// Staff related components
import StaffLayout from './layout/StaffLayout.vue'
import StaffOverview from './staff/Overview.vue'
import StaffRequests from './staff/Requests.vue'
import StaffOrders from './staff/Orders.vue'
import StaffInvoices from './staff/Invoices.vue'
import StaffCustomers from './staff/Customers.vue'
import StaffVendors from './staff/Vendors.vue'
import StaffVendorDetail from './staff/VendorDetail.vue'
import StaffProducts from './staff/Products.vue'
import StaffPayments from './staff/Payments.vue'
import StaffDeliveries from './staff/Deliveries.vue'
import StaffCuisines from './staff/Cuisines.vue'
import StaffVendorNewAddress from './staff/VendorNewAddress.vue'
import StaffVendorEditAddress from './staff/VendorEditAddress.vue'
// User related components
import UserLayout from './layout/UserLayout.vue'
import UserOverview from './user/Overview.vue'
import UserRequests from './user/Requests.vue'
import UserRequestDetail from './user/UserRequestDetails.vue'
import UserBilling from './user/Billing.vue'
import UserAddressBook from './user/AddressBook.vue'
import UserContactsNew from './user/UserContactsNew.vue'
import UserAddressesInsert from './user/AddressesInsert.vue'
import UserSettings from './user/Settings.vue'
import UserOrders from './user/Orders.vue'
import UserOrderDetail from './user/UserOrderDetail.vue'
import RequestBuilder from './layout/Blocks/RequestBuilder.vue'
import UserRequestBuilder from './layout/Blocks/UserRequestBuilder.vue'
import MakePayment from './staff/blocks/MakePayment'
import MakeTips from './staff/blocks/MakeTips'
import VendorBuilder from './staff/blocks/VendorBuilder.vue'
import ProductBuilder from './staff/blocks/ProductBuilder.vue'
// Anonymous or shared
import Login from './anonymous/Login.vue'
// Print related
import PrintLayout from './layout/PrintLayout.vue'
import PurchaseOrderPDF from './print/PurchaseOrderPDF.vue'
import InvoicePDF from './print/InvoicePDF.vue'
import EstimatePDF from './print/EstimatePDF.vue'
import OrderUserPDF from './print/OrderUserPDF.vue'
import OrderChecklistPDF from './print/OrderChecklistPDF.vue'

function requireStaffSession (to, from, next) {
  // TODO pass error information to route
  if(auth.validStaffSession()){
    next()
  }else{
    console.error("INVALID STAFF SESSION")
    next({
      path: '/'
    })
  }
}

function requireUserSession (to, from, next) {
  // TODO pass error information to route
  if(auth.validCustomerSession()){
    next()
  }else{
    console.error("INVALID CUSTOMER SESSION")
    next({
      path: '/'
    })
  }
}

function requireAnySession (to, from, next) {
  // TODO pass error information to route
  if( auth.validStaffSession() || auth.validCustomerSession()){
    next()
  }else{
    console.error("INVALID SESSION")
    next({
      path: '/'
    })
  }
}

const staffRoutes = [
  {path: 'overview', component: StaffOverview},
  {path: 'orders', component: StaffOrders, children: [
      { path: ':id/edit', component: RequestBuilder, props: true },
  ]},
  {path: 'invoices', component: StaffInvoices, children: [
    { path: 'payment', component: MakePayment },
    { path: 'tips', component: MakeTips }
  ]},
  {path: 'customers', component: StaffCustomers},
  {path: 'deliveries', component: StaffDeliveries},
  {path: 'cuisines', component: StaffCuisines},
  {
    path: 'requests', component: StaffRequests, children: [
      { path: 'new', component: RequestBuilder },
      { path: ':id/edit', component: RequestBuilder, props: true },
    ]
  },
  {
    path: 'vendors', component: StaffVendors,
    children: [
      { path: 'new', component: VendorBuilder },
      { path: 'edit/:id', component: VendorBuilder, props: true },
    ],
  },
  { path: 'vendors/:id', component: StaffVendorDetail,
    children: [
      { path: 'addresses/new', component: StaffVendorNewAddress, props: true },
      { path: 'addresses/:aid/edit', component: StaffVendorEditAddress, props: true },
      { path: 'new-product', component: ProductBuilder },
      { path: 'edit-product/:pid', component: ProductBuilder, props: true }
    ],
  },
  {
    path: 'products', component: StaffProducts,
    children: [
      { path: 'new', component: ProductBuilder },
      { path: 'edit/:id', component: ProductBuilder, props: true },
    ],
  },
  {
    path: 'payments/:id', component: StaffPayments, props: true,
  }
  //{path: 'foo', component: Foo, children: [{path: ':id/edit', component: FooDrawer}]}
]

const userRoutes = [
  {path: 'overview', component: UserOverview},
  {
    path: 'requests', component: UserRequests, children: [
      { path: 'new', component: UserRequestBuilder },
      { path: ':id/edit', component: UserRequestBuilder, props: true },
    ]
  },
  { 
    path: 'requests/:id', component: UserRequestDetail, children: [
      { path: 'edit-req', component: UserRequestBuilder, props: true }
    ]
  },
  {path: 'billing', component: UserBilling},
  {path: 'address_book', component: UserAddressBook, children: [
    { path: 'new_contact', component: UserContactsNew },
    { path: 'insert_address', component: UserAddressesInsert, children: [
        { path: 'new', component: UserAddressesInsert },
        { path: ':id/edit', component: UserAddressesInsert, props: true }
      ]
    }
  ]},
  {path: 'settings', component: UserSettings},
  {path: 'orders', component: UserOrders},
  { path: 'orders/:id', component: UserOrderDetail },
]

const printRoutes = [
  { path: 'orders/:id/po', component: PurchaseOrderPDF, props: true, beforeEnter: requireStaffSession },
  { path: 'orders/:id/checklist', component: OrderChecklistPDF, props: true, beforeEnter: requireStaffSession },
  { path: 'invoices/:id', component: InvoicePDF, props: true, beforeEnter: requireAnySession },
  { path: 'estimate/:id', component: EstimatePDF, props: true, beforeEnter: requireAnySession },
  { path: 'orders/:id', component: OrderUserPDF, props: true, beforeEnter: requireAnySession }
]

const routes = [
  // TODO make a session requirement regardless of the group/role
  { path: '/print', component: PrintLayout, children: printRoutes },
  { path: '/staff', component: StaffLayout, children: staffRoutes, beforeEnter: requireStaffSession },
  { path: '/user', component: UserLayout, children: userRoutes, beforeEnter: requireUserSession },
  { path: '/', component: Login}
]

export default new VueRouter({
  routes // short for `routes: routes`
})
