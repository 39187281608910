<template>
<div v-show="showPopUp">
<div class="popup">
	<div class="popup_bg" @click="$emit('closePopUp')"></div>
	<div class="popup_box">
		<div class="popup_content" :style="{width: width, background: backgroundColor}">
      <div class="title-section">
        <div :class="centerTitle ? 'center-title' : ''">{{title}}</div>
			  <a class="close_popup" @click="$emit('closePopUp')">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18M6 6L18 18" stroke="#1F1F18" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>
      </div>
      <div class="pop-up-content">
        <slot></slot>
      </div>
		</div>
	</div>
</div>
</div>
</template>

<script>
export default {
  name: 'PopUp',
  data() {
    return {

    }
  },
  props: {
    showPopUp: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: 'auto'
    },
    centerTitle: {
      require: false
    },
    backgroundColor: {
      require: false
    }
  }
}
</script>

<style scoped lang="sass">
.popup 
  align-items: flex-start
  bottom: 0
  display: flex
  justify-content: center
  left: 0
  position: fixed
  right: 0
  top: 0
  z-index: 10
  overflow: auto

.popup .popup_bg 
  background: #262626
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  z-index: 1
  opacity: 0.6

.popup .popup_box 
  display: flex
  flex-direction: column
  justify-content: center
  min-height: 100%
  padding: 30px

.popup .popup_content 
  background: #fff
  -webkit-border-radius: 10px
  -moz-border-radius: 10px
  -o-border-radius: 10px
  -ms-border-radius: 10px
  border-radius: 10px
  position: relative
  z-index: 2
  min-width: 38rem
  padding: 48px 64px

.title-section
  display: flex
  flex-direction: row
  justify-content: space-between
  margin-bottom: 2.5rem
  font-family: 'Inter', "Open Sans", Helvetica, sans-serif
  font-style: normal
  font-weight: 500
  font-size: 1.5rem
  line-height: 100%

.close_popup
  cursor: pointer

.pop-up-content
  height: 100%
  align-items: center
  justify-content: center

.center-title
  margin: auto        
</style>
