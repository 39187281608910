<template lang='pug'>
div(class="print-container")
 #container(:class="isPrintingForm ? 'printing-layout' : 'default-layout'")
  router-view
</template>

<style lang='sass'>

@page
  size: US-Letter
  margin: 0 0 35mm 0
  background: #ffffff
  font-size: 16px

body
  font-size: 16px
  font-family: "myriad-pro-1", "myriad-pro-2", sans-serif
  margin: o auto
  min-height: 100vh

*
  box-sizing: border-box

// Reference: https://stackoverflow.com/questions/320357/safe-width-in-pixel-for-printing-web-pages
#container
  margin: 0 auto
  //padding-bottom: 10mm
  width: 1440px
  height: 100%

table.el-table__header, table.el-table__body
  max-width: 100% !important

.printing-layout
  max-width: 98% 

.default-layout
  max-width: 80%

.print-container
  background: white
  height: 100%


</style>

<script>
import isPrintingForm from '@/mixins/mainWidth'

const mixins = [ isPrintingForm ]

  export default {
    components: { },
    mixins
  }
</script>
