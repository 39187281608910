<template lang='pug'>
div
  el-row

    el-col(:span='12')
      el-checkbox(v-model='preferences.drinks')
        | Drinks

    el-col(:span='12')
      el-checkbox(v-model='preferences.desserts')
        | Desserts

  el-row

    el-col(:span='12')
      el-checkbox(v-model='preferences.no_red_meat')
        | No red meat

    el-col(:span='12')
      el-checkbox(v-model='preferences.no_pork_products')
        | No pork products

  el-row(v-if='!isRequest')

    el-col(:span='12')
      el-checkbox(v-model='preferences.no_onions')
        | Light / No onions

    el-col(:span='12')
      el-checkbox(v-model='preferences.half_pans')
        | Half pans

  el-row

    el-col(:span='12')
      el-checkbox(v-model='preferences.kosher')
        | Kosher options


    el-col(:span='12')
      el-checkbox(v-model='preferences.light_food')
        | Light food

  el-row

    el-col(:span='12')
      el-checkbox(v-model='preferences.set_up')
        | Set up

    el-col(:span='12')
      el-checkbox(v-model='preferences.utensils')
        | Utensils

  template(v-if='isRequest')

    el-row

      el-col(:span='12')
        el-checkbox(v-model='preferences.lunch_box')
          | Lunch box / IND

      el-col(:span='12')
        el-checkbox(v-model='preferences.hot_food')
          | Hot food

    el-row

      el-col(:span='12')
        el-checkbox(v-model='preferences.include_salad')
          | Include salad

      el-col(:span='12')
        el-checkbox(v-model='preferences.vegetarian_options')
          | Vegetarian options

    el-row

      el-col(:span='12')
        el-checkbox(v-model='preferences.vegan_options')
          | Vegan options

      el-col(:span='12')
        el-checkbox(v-model='preferences.no_dairy')
          | No dairy

    el-row

      el-col(:span='12')
        el-checkbox(v-model='preferences.gluten_free_needed')
          | Gluten free needed

      el-col(:span='12')
        el-checkbox(v-model='preferences.no_seafood')
          | No fish/seafood

    el-row

      el-col(:span='12')
        el-checkbox(v-model='preferences.not_spicy')
          | Not spicy

      el-col(:span='12')
        el-checkbox(v-model='preferences.staff_needed')
          | Staff needed

  el-row

    el-col(:span='12')
      el-checkbox(v-model='preferences.healthy')
        | Healthy options

</template>



<script>

  const defaultPreferences = {
    drinks: false,
    desserts: false,
    no_red_meat: false,
    no_pork_products: false,
    healthy: false,
    half_pans: false,
    kosher: false,
    light_food: false,
    set_up: false,
    utensils: false,
    lunch_box: false,
    hot_food: false,
    include_salad: false,
    vegetarian_options: false,
    vegan_options: false,
    no_dairy: false,
    gluten_free_needed: false,
    no_seafood: false,
    not_spicy: false,
    staff_needed: false,
    no_onions: false
  }

  function data(){
    const freshClone = Object.assign({}, defaultPreferences, this.initialValue)
    return { preferences: freshClone }
  }

  const props ={
    initialValue: {
      required: true
    },
    isRequest: {
      default: false
    }
  }

  const components = {  }

  const methods = {  }

  const watch = {
    initialValue: function (newValue){
      console.log("***** INITIAL VAL CHANGED", newValue)
      const freshClone = Object.assign({}, defaultPreferences, this.initialValue)
      this.preferences = freshClone
    },
    // preferences: {
    //   handler: function (newValue) {
    //     const freshClone = Object.assign({}, newValue)
    //     console.log("Notifying new prefs", freshClone)
    //     this.$emit('change', freshClone)
    //   },
    //   deep: true
    // }
  }

  export default { props, data, methods, watch }
</script>
