<template lang='pug'>
.main-container

  .view-content

    el-card
      div(slot='header')
        i.mat-icon store
        | Vendors
        el-button(type="primary", @click="newVendor", style='float: right')
          i.mat-icon add_circle
          | Add vendor
      el-row(:gutter='10', class="filtering")
        el-col(:span='9')
          el-input(v-model='sorting' prefix-icon="el-icon-search" placeholder="Search" )
        el-col(:span='3', class="inline-flex")
          el-checkbox(label="Inactive vendors", v-model="deletedVendors")
      el-table(:data='vendors', v-loading='vendors == null')
        el-table-column(label='Name')
          template(slot-scope='scope')
            router-link(:to="'/staff/vendors/' + scope.row.id") {{ scope.row.name }}
        //- el-table-column(prop='code', label='Code')
        el-table-column(label='Actions')
          template(slot-scope='scope')
            el-button(type='text', size='small', @click='editVendor(scope.row)') Edit
            el-button(v-if="!deletedVendors", type='text', size='small', @click='deleteVendor(scope.row, scope.$index)') Delete
            el-button(v-else, type='text', size='small', @click="restoreVendor(scope.row.id)") Restore
      el-col()
        el-pagination( 
          v-if="pagination.pages > 1"
          @current-change="onPaginationChange"
          :page-size="pagination.per_page", 
          :pager-count="5", 
          layout="prev, pager, next", 
          :total="pagination.total") 
  router-view
</template>

<script>
  import Toolbar from '../layout/Toolbar.vue'
  import _ from 'underscore'

  function data ( ) {
    return {
      vendors: null,
      sorting:'',
      deletedVendors: false,
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null,
      }
    }
  }

  function onPaginationChange(current_page){
    this.getVendors(current_page);
  }

  function created(){
    this.getVendors()
  }

  function newVendor ( ) {
    this.$router.push('/staff/vendors/new')
  }

  function getVendors (current_page) {
    const reqParams = {
      params: { 
        deleted: this.deletedVendors,
        ...(current_page && { page:current_page}),
        ...(this.sorting && { q: this.sorting })
      }
    }
    return this.$simplecater.get('/vendors', reqParams).then((r)=> {
      this.vendors = r.data.data.sort((a, b) => a.name.localeCompare(b.name) );
      this.pagination = r.data.pagination;
    })
  }

  function editVendor ( x ) {
    this.$router.push('/staff/vendors/edit/' + x.id)
  }

  function editVendorAddresses ( x ) {
    this.$router.push('/staff/vendors/edit/' + x.id + '/addresses')
  }

  function deleteVendor ( x, idx ) {
    if ( confirm('Are you sure?') ) {
      return this.$simplecater.delete('/vendors/' + x.id).then( ( r )=> {
        let newVendors = _.map(this.vendors, _.id)
        newVendors.splice(idx, 1)
        this.vendors = newVendors
      }).catch( ( err ) => {
        this.$message.error('Failed to delete!')
      })
    }
  }

  function restoreVendor(id) {
    this.$simplecater.put(`/vendors/${id}/restore`)
      .then(() => {
        this.$message.success('Restore vendor complete')
        this.getVendors(this.pagination.current)
      })
      .catch(() => (this.$message.error('Something went wrong with restoring')))
  }

  const watch = {
    sorting: function(){
      this.getVendors();
    },
    deletedVendors() {
      this.getVendors()
    },
    $route: function(newRoute) {
      if (newRoute.path === '/staff/vendors') {
        this.getVendors();
      }
    }
  };

  export default {
    methods: { newVendor, onPaginationChange, getVendors, editVendor, deleteVendor, editVendorAddresses, restoreVendor },
    components: { Toolbar, },
    created,
    data,
    watch
  }
</script>

<style lang="sass" scoped>
.inline-flex
  display: inline-flex
.filtering
  display: flex
  align-items: center
.cell .el-button
  padding-left: 0
</style>
