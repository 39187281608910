<template>
    <button class="cancel-default" @click="$emit('onClick')">
        {{title}}
    </button>
</template>

<style lang="sass" scoped>
.cancel-default
  height: 3rem
  background: #FFFFFF
  display: flex
  align-items: center
  padding: .75rem 1rem
  color: black
  border-radius: 12px
  border: 1px solid #EBEBE4
  font-weight: 500
  font-size: 1rem
  cursor: pointer
  max-height: 2.5rem
</style>

<script>
export default {
  props: {
    title: {
        type: String,
        require: true,
    }
  }
};
</script>
