<template>
  <div class="delivery-information">
    <div class="delivery-information-content">
      <div class="delivery-row">
        <div v-if="deliveryTime" class="delivery-column">
          <div>
            <div class="subtitle">Delivery Time</div>
            <div class="delivery-property">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.0001 6.2081V8.30002C8.0001 8.43581 8.05404 8.56604 8.15006 8.66206L9.2801 9.7921M1.6001 4.6721V4.1601C1.6001 2.74625 2.74625 1.6001 4.1601 1.6001H4.6721M11.3281 1.6001L11.8401 1.6001C13.2539 1.6001 14.4001 2.74625 14.4001 4.1601V4.6721M3.9041 12.6081L2.1121 14.4001M13.8881 14.4001L12.0961 12.6081M13.6321 8.5121C13.6321 11.6226 11.1106 14.1441 8.0001 14.1441C4.88963 14.1441 2.3681 11.6226 2.3681 8.5121C2.3681 5.40163 4.88963 2.8801 8.0001 2.8801C11.1106 2.8801 13.6321 5.40163 13.6321 8.5121Z"
                  stroke="#125F43"
                  stroke-width="1.5"
                  stroke-linecap="round"
                />
              </svg>
              <div>
                {{ $formatTime(deliveryTime) }}
              </div>
            </div>
          </div>
        </div>
        <div v-if="contact" class="delivery-column">
          <div>
            <div class="subtitle">Customer Name</div>
            <div class="delivery-property">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.3332 14C13.3332 13.0696 13.3332 12.6044 13.2183 12.2259C12.9598 11.3736 12.2929 10.7067 11.4406 10.4482C11.0621 10.3333 10.5969 10.3333 9.6665 10.3333H6.33317C5.4028 10.3333 4.93761 10.3333 4.55908 10.4482C3.70681 10.7067 3.03986 11.3736 2.78133 12.2259C2.6665 12.6044 2.6665 13.0696 2.6665 14M10.9998 5C10.9998 6.65685 9.65669 8 7.99984 8C6.34298 8 4.99984 6.65685 4.99984 5C4.99984 3.34315 6.34298 2 7.99984 2C9.65669 2 10.9998 3.34315 10.9998 5Z"
                  stroke="#125F43"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div>
                {{ contact.salutation }} {{ contact.first_name }}
                {{ contact.last_name }}
              </div>
            </div>
          </div>
        </div>
        <div class="delivery-column">
          <div>
            <div class="subtitle">Address line</div>
            <div class="delivery-property">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.99984 8.66665C9.10441 8.66665 9.99984 7.77122 9.99984 6.66665C9.99984 5.56208 9.10441 4.66665 7.99984 4.66665C6.89527 4.66665 5.99984 5.56208 5.99984 6.66665C5.99984 7.77122 6.89527 8.66665 7.99984 8.66665Z"
                  stroke="#125F43"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.99984 14.6666C10.6665 12 13.3332 9.61217 13.3332 6.66665C13.3332 3.72113 10.9454 1.33331 7.99984 1.33331C5.05432 1.33331 2.6665 3.72113 2.6665 6.66665C2.6665 9.61217 5.33317 12 7.99984 14.6666Z"
                  stroke="#125F43"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div v-if="contact">
                {{ address ? address.address_line_1 : "" }}
              </div>
            </div>
          </div>
        </div>
        <div class="delivery-column">
          <div>
            <div class="subtitle">City</div>
            <div class="delivery-property">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.6668 8.91629C13.0214 9.37923 14.6668 10.4365 14.6668 11.6667C14.6668 13.3235 11.6821 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 13.3235 1.3335 11.6667C1.3335 10.4365 2.97896 9.37923 5.3335 8.91629M8.00016 11.3333V6M8.00016 6C9.10473 6 10.0002 5.10457 10.0002 4C10.0002 2.89543 9.10473 2 8.00016 2C6.89559 2 6.00016 2.89543 6.00016 4C6.00016 5.10457 6.89559 6 8.00016 6Z"
                  stroke="#125F43"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <div>
                {{ address ? address.city : "" }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="delivery-row second-row">
        <div class="delivery-column delivery-column-second-row">
          <div class="subtitle">Delivery Date</div>
          <div class="delivery-property">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 6.66659H2M10.6667 1.33325V3.99992M5.33333 1.33325V3.99992M5.2 14.6666H10.8C11.9201 14.6666 12.4802 14.6666 12.908 14.4486C13.2843 14.2569 13.5903 13.9509 13.782 13.5746C14 13.1467 14 12.5867 14 11.4666V5.86659C14 4.74648 14 4.18643 13.782 3.7586C13.5903 3.38228 13.2843 3.07632 12.908 2.88457C12.4802 2.66659 11.9201 2.66659 10.8 2.66659H5.2C4.0799 2.66659 3.51984 2.66659 3.09202 2.88457C2.71569 3.07632 2.40973 3.38228 2.21799 3.7586C2 4.18643 2 4.74648 2 5.86659V11.4666C2 12.5867 2 13.1467 2.21799 13.5746C2.40973 13.9509 2.71569 14.2569 3.09202 14.4486C3.51984 14.6666 4.0799 14.6666 5.2 14.6666Z"
                stroke="#125F43"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div>
              {{ deliveryDate }}
            </div>
          </div>
        </div>
        <div class="delivery-column delivery-column-second-row  ">
          <div class="subtitle">Phone Number</div>
          <div class="delivery-property">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.0002 1.33331V2.26665C10.0002 2.64001 10.0002 2.8267 9.9275 2.96931C9.86359 3.09475 9.7616 3.19674 9.63616 3.26065C9.49355 3.33331 9.30686 3.33331 8.9335 3.33331H7.06683C6.69346 3.33331 6.50678 3.33331 6.36417 3.26065C6.23873 3.19674 6.13674 3.09475 6.07283 2.96931C6.00016 2.8267 6.00016 2.64001 6.00016 2.26665V1.33331M5.46683 14.6666H10.5335C11.2802 14.6666 11.6536 14.6666 11.9388 14.5213C12.1897 14.3935 12.3937 14.1895 12.5215 13.9386C12.6668 13.6534 12.6668 13.28 12.6668 12.5333V3.46665C12.6668 2.71991 12.6668 2.34654 12.5215 2.06133C12.3937 1.81044 12.1897 1.60647 11.9388 1.47864C11.6536 1.33331 11.2802 1.33331 10.5335 1.33331H5.46683C4.72009 1.33331 4.34672 1.33331 4.06151 1.47864C3.81063 1.60647 3.60665 1.81044 3.47882 2.06133C3.3335 2.34654 3.3335 2.71991 3.3335 3.46665V12.5333C3.3335 13.28 3.3335 13.6534 3.47882 13.9386C3.60665 14.1895 3.81063 14.3935 4.06151 14.5213C4.34672 14.6666 4.72009 14.6666 5.46683 14.6666Z"
                stroke="#125F43"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div v-if="contact">
              {{ modifiedPhoneNumber(contact.phone_number) }}
            </div>
          </div>
        </div>
        <div class="delivery-column delivery-column-second-row">
          <div class="subtitle">Suite</div>
          <div class="delivery-property">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.99984 8.66665C9.10441 8.66665 9.99984 7.77122 9.99984 6.66665C9.99984 5.56208 9.10441 4.66665 7.99984 4.66665C6.89527 4.66665 5.99984 5.56208 5.99984 6.66665C5.99984 7.77122 6.89527 8.66665 7.99984 8.66665Z"
                stroke="#125F43"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7.99984 14.6666C10.6665 12 13.3332 9.61217 13.3332 6.66665C13.3332 3.72113 10.9454 1.33331 7.99984 1.33331C5.05432 1.33331 2.6665 3.72113 2.6665 6.66665C2.6665 9.61217 5.33317 12 7.99984 14.6666Z"
                stroke="#125F43"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <div v-if="suite">
              {{ suite }}
            </div>
            <div v-else>
              {{ address ? address.address_line_2 : "" }}
            </div>
          </div>
        </div>
        <div class="delivery-column delivery-column-second-row  ">
          <div class="subtitle">State</div>
          <div class="delivery-property">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#clip0_900_33689)">
                <path
                  d="M10.0002 1.63851C9.3688 1.44022 8.69697 1.33331 8.00016 1.33331C4.31826 1.33331 1.3335 4.31808 1.3335 7.99998C1.3335 11.6819 4.31826 14.6666 8.00016 14.6666C11.6821 14.6666 14.6668 11.6819 14.6668 7.99998C14.6668 6.85643 14.3789 5.78013 13.8716 4.83961M11.3335 3.83331H11.3368M7.0002 14.5922L7.00027 13.1233C7.00027 13.0437 7.02874 12.9668 7.08052 12.9064L8.7377 10.9729C8.87389 10.814 8.8317 10.5704 8.65 10.4666L6.74585 9.37847C6.69412 9.34891 6.65125 9.30602 6.62172 9.25426L5.38047 7.07907C5.31587 6.96586 5.19121 6.90069 5.06138 6.91225L1.37629 7.24048M14.0002 3.99998C14.0002 5.47274 12.6668 6.66665 11.3335 7.99998C10.0002 6.66665 8.66683 5.47274 8.66683 3.99998C8.66683 2.52722 9.86074 1.33331 11.3335 1.33331C12.8063 1.33331 14.0002 2.52722 14.0002 3.99998ZM11.5002 3.83331C11.5002 3.92536 11.4255 3.99998 11.3335 3.99998C11.2414 3.99998 11.1668 3.92536 11.1668 3.83331C11.1668 3.74127 11.2414 3.66665 11.3335 3.66665C11.4255 3.66665 11.5002 3.74127 11.5002 3.83331Z"
                  stroke="#125F43"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_900_33689">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <div>
              {{ address ? address.state : "" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="sass">
.delivery-information
    min-width: 300px
    width: 100%
    background: white
    box-shadow: 0px 8px 40px -4px rgba(31, 31, 24, 0.03)
    border-radius: 16px
.delivery-information-content
    display: flex
    flex-direction: column
.delivery-property
    display: flex
    gap: .375rem
    align-items: center
    margin-top: .125rem
    //margin-bottom: 1.25rem
    div
        font-size: 1rem
        line-height: 1.5rem
.delivery-column
    border-right: 1px solid #E8E8E3
    padding: 24px 18px 0 18px
    flex: 1
.delivery-column:last-child
    border-right: none
.delivery-column-second-row
    padding-bottom: 24px 
svg
    min-width: 16px
    align-self: flex-start
    margin-top: 6px
.delivery-row
    display: flex   
</style>

<script>
import modifyPhoneNumber from "@/mixins/modifiedPhoneNumber";
export default {
  // "WAITING_FOR_ESTIMATES"
  // "ESTIMATES_AVAILABLE"
  // "ESTIMATE_ORDERED"
  props: {
    contact: {
      Type: Object,
    },
    address: {
      Type: Object,
    },
    suite: {
      require: false,
    },
    deliveryTime: {
      type: String,
    },
    deliveryDate: {
      type: String,
    },
  },
  mixins: [modifyPhoneNumber],
};
</script>
 