<template>
  <div class="custome-checkbox">
    <div class="checkbox-form">
      <div class="answers">
        <label
          class="item"
        >
          <span>{{ option }}</span>
          <input
            :id="option"
            type="checkbox"
            v-model="model"
          >
          <span class="checkmark" :class="{checkboxValidationError: validationError}"/>
        </label>
      </div>
    </div>
    <div class="checkbox-title description-text" :class="{validationError: validationError}">
       {{ title }}
  </div>
  </div>
</template>
<script>
export default {
  props: {
    option: {
      type: Object,
    },
    value: {
      require: true
    },
    title: {
        typeof: String,
        default: ''
    },
    validationError: {
        type: Boolean,
        default: false
    },
    updateVal: {
        default: false
    }
  },
  data(){
    return {
      checked: false,
    };
  },
    computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    onChange() {
      this.$emit('input', this.checked);
    }
  },
}
</script>

<style scoped>
.custome-checkbox {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.checkbox-form {
	display: flex;
	flex-direction: column;
	align-items: center;
    justify-content: center;
}

.checkbox-form .answers {
	display: flex;
	flex-direction: column;
	align-items: left;
	width: 100%;
}

.checkbox-form label {
	margin-left: 1em;
}

.checkbox-form .item {
	display: block;
	position: relative;
	padding-left: 35px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 1em;
	height: 1.5625rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	display: flex;
	align-items: center;
    margin: auto;
}

.checkbox-form .item input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkbox-form .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
    border-radius: .25rem;
	background: white;
    border: 1px solid #EBEBE4;
}

.checkbox-form .item:hover input ~ .checkmark {
	background-color: #F4F9F5;
}

.checkbox-form .item input:checked ~ .checkmark {
	background: #125F43;
}

.checkbox-form .checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox-form .item input:checked ~ .checkmark:after {
	display: block;
}

.checkbox-form .item .checkmark:after {
	left: 5.5px;
	top: 0;
	width: 6px;
	height: 10px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.validationError {
    color: #D92D20;
}

.checkboxValidationError {
    border-color: #D92D20 !important;
}

</style>