<style scoped lang="sass">
.footer-section
    display: flex
    gap: 1rem
    justify-content: flex-end
    margin-top: 10vh 
</style>
<template>
<el-form :label-width="labelWidth" :label-position="labelPosition" :model="contact" :rules="rules" ref="contactForm">
    <el-form-item v-if="!hideSalutation" label="Salutation" prop="salutation">
        <user-salutation-switch @onSalitationClick="contact.salutation = $event" :initial-state="contact.salutation"/>
    </el-form-item>
    <el-form-item label="First name" prop="first_name">
        <el-input v-model="contact.first_name"></el-input>
    </el-form-item>
    <el-form-item label="Last name" prop="last_name">
        <el-input v-model="contact.last_name"></el-input>
    </el-form-item>
    <el-form-item label="Email" prop="email">
        <el-input v-model="contact.email"></el-input>
    </el-form-item>
    <el-form-item label="Phone number" prop="phone_number">
        <el-input v-model="contact.phone_number"><template slot="prepend">+1</template></el-input>
    </el-form-item>
    <div class="footer-section">
        <cancel-button v-if="!hideCancel" class="cancel-btn" @onClick="$router.back()" title="Cancel"></cancel-button>
        <default-button title="Save Changes" @onClick="onSave">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
    </default-button>
    </div>
</el-form>
</template>



<script>
import ContactBuilder from './ContactBuilder.vue'
import UserSalutationSwitch from './UserSalutationSwitch.vue'
import DefaultButton from '@/layout/Blocks/DefaultButton.vue'
import CancelButton from '@/layout/Blocks/CancelButton.vue'

export default {
    components: { UserSalutationSwitch, DefaultButton, CancelButton }, 
    mixins: [ContactBuilder],
    props: {
        hideCancel: {
            default: false
        },
        hideSalutation: {
            default: false
        }
    }
 }
</script>
