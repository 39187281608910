<style scoped lang="sass">
.section
    margin: 2.25rem 0 1rem 0
.order-main-contant
    // display: flex
    // gap: 1.5rem
    // .left-part
    //     flex: 2
    // .right-part
    //     flex: 1
.notes
    display: flex
    gap: 1.5rem
    justify-content: space-between
    .left-note
        width: 100%
    .right-note
        width: 100%
    .note-content
        display: flex
        justify-content: center
        align-items: center
        gap: .625rem
        color: #A2A29D
        min-height: 8.25rem
</style>
<template>
  <div>
    <el-card>
      <user-stepper
        :steps="steps"
        :active="[order.status] || 0"
        :hideStep="true"
      />
    </el-card>
    <!-- <order-card :order="order" :orederView="true" @onPrintClick="$openInBlank(`/print/orders/${order.id}`)"/> -->
    <div>
      <div class="description-text section">
        Delivery Address
      </div>
      <delivery-information
        :contact="contact"
        :address="requestAddress"
        :suite="request.suite"
        :deliveryTime="request.delivery_time"
        :deliveryDate="request.formatted_delivery_date"
      />
    </div>
    <div class="order-main-contant">
      <div class="description-text section">
        Your Order
      </div>
      <user-order-estimate-viewer
        @onPrintClick="$openInBlank(`/print/orders/${order.id}`)"
        :data="itemsWithoutTaxTip"
        :order_id="order.id"
        :title="order.vendor_name"
        :subtotalCents="subtotalCents"
        :totalCents="totalCents"
        :tax="tax.sale_price_cents"
        :tips="tips"
        :customer_po="request.customer_po"
        :pricePerPerson="pricePerPerson"
        :people="request.people"
      />
    </div>
    <div class="description-text section">
      Notes
    </div>
    <div class="notes">
      <div class="left-note">
        <el-card>
          <div class="note-content">
            <svg
              v-if="!order.request_notes"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3337 7.99992V4.53325C13.3337 3.41315 13.3337 2.85309 13.1157 2.42527C12.9239 2.04895 12.618 1.74299 12.2416 1.55124C11.8138 1.33325 11.2538 1.33325 10.1337 1.33325H5.86699C4.74689 1.33325 4.18683 1.33325 3.75901 1.55124C3.38269 1.74299 3.07673 2.04895 2.88498 2.42527C2.66699 2.85309 2.66699 3.41315 2.66699 4.53325V11.4666C2.66699 12.5867 2.66699 13.1467 2.88498 13.5746C3.07673 13.9509 3.38269 14.2569 3.75901 14.4486C4.18683 14.6666 4.74689 14.6666 5.86699 14.6666H8.00033M10.667 10.6666L14.0003 13.9999M14.0003 10.6666L10.667 13.9999"
                stroke="#A2A29D"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{
              order.request_notes ? order.request_notes : "No Notes on Accept"
            }}
          </div>
        </el-card>
      </div>
      <div class="right-note">
        <el-card>
          <div class="note-content">
            <svg
              v-if="!order.accept_notes"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.3337 7.99992V4.53325C13.3337 3.41315 13.3337 2.85309 13.1157 2.42527C12.9239 2.04895 12.618 1.74299 12.2416 1.55124C11.8138 1.33325 11.2538 1.33325 10.1337 1.33325H5.86699C4.74689 1.33325 4.18683 1.33325 3.75901 1.55124C3.38269 1.74299 3.07673 2.04895 2.88498 2.42527C2.66699 2.85309 2.66699 3.41315 2.66699 4.53325V11.4666C2.66699 12.5867 2.66699 13.1467 2.88498 13.5746C3.07673 13.9509 3.38269 14.2569 3.75901 14.4486C4.18683 14.6666 4.74689 14.6666 5.86699 14.6666H8.00033M10.667 10.6666L14.0003 13.9999M14.0003 10.6666L10.667 13.9999"
                stroke="#A2A29D"
                stroke-width="1.67"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {{ order.accept_notes ? order.accept_notes : "No Request Notes" }}
          </div>
        </el-card>
      </div>
    </div>
  </div>
</template>

<script>
import Order from "./Order.vue";
import UserStepper from "./UserStepper.vue";
import DeliveryInformation from "./DeliveryInformation.vue";
import UserOrderEstimateViewer from "./UserOrderEstimateViewer.vue";

export default {
  mixins: [Order],
  components: {
    UserStepper,
    DeliveryInformation,
    UserOrderEstimateViewer
  },
  props: {
    contWidth: {
      type: Number | String
    }
  },
  data() {
    return {
      steps: [
        {
          completeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2H3.30616C3.55218 2 3.67519 2 3.77418 2.04524C3.86142 2.08511 3.93535 2.14922 3.98715 2.22995C4.04593 2.32154 4.06333 2.44332 4.09812 2.68686L4.57143 6M4.57143 6L5.62332 13.7314C5.75681 14.7125 5.82355 15.2031 6.0581 15.5723C6.26478 15.8977 6.56108 16.1564 6.91135 16.3174C7.30886 16.5 7.80394 16.5 8.79411 16.5H17.352C18.2945 16.5 18.7658 16.5 19.151 16.3304C19.4905 16.1809 19.7818 15.9398 19.9923 15.6342C20.2309 15.2876 20.3191 14.8247 20.4955 13.8988L21.8191 6.94969C21.8812 6.62381 21.9122 6.46087 21.8672 6.3335C21.8278 6.22177 21.7499 6.12768 21.6475 6.06802C21.5308 6 21.365 6 21.0332 6H4.57143ZM10 21C10 21.5523 9.55228 22 9 22C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20C9.55228 20 10 20.4477 10 21ZM18 21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21C16 20.4477 16.4477 20 17 20C17.5523 20 18 20.4477 18 21Z" stroke="#125F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>`,
          activeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2H3.30616C3.55218 2 3.67519 2 3.77418 2.04524C3.86142 2.08511 3.93535 2.14922 3.98715 2.22995C4.04593 2.32154 4.06333 2.44332 4.09812 2.68686L4.57143 6M4.57143 6L5.62332 13.7314C5.75681 14.7125 5.82355 15.2031 6.0581 15.5723C6.26478 15.8977 6.56108 16.1564 6.91135 16.3174C7.30886 16.5 7.80394 16.5 8.79411 16.5H17.352C18.2945 16.5 18.7658 16.5 19.151 16.3304C19.4905 16.1809 19.7818 15.9398 19.9923 15.6342C20.2309 15.2876 20.3191 14.8247 20.4955 13.8988L21.8191 6.94969C21.8812 6.62381 21.9122 6.46087 21.8672 6.3335C21.8278 6.22177 21.7499 6.12768 21.6475 6.06802C21.5308 6 21.365 6 21.0332 6H4.57143ZM10 21C10 21.5523 9.55228 22 9 22C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20C9.55228 20 10 20.4477 10 21ZM18 21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21C16 20.4477 16.4477 20 17 20C17.5523 20 18 20.4477 18 21Z" stroke="#CFA531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>`,
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 2H3.30616C3.55218 2 3.67519 2 3.77418 2.04524C3.86142 2.08511 3.93535 2.14922 3.98715 2.22995C4.04593 2.32154 4.06333 2.44332 4.09812 2.68686L4.57143 6M4.57143 6L5.62332 13.7314C5.75681 14.7125 5.82355 15.2031 6.0581 15.5723C6.26478 15.8977 6.56108 16.1564 6.91135 16.3174C7.30886 16.5 7.80394 16.5 8.79411 16.5H17.352C18.2945 16.5 18.7658 16.5 19.151 16.3304C19.4905 16.1809 19.7818 15.9398 19.9923 15.6342C20.2309 15.2876 20.3191 14.8247 20.4955 13.8988L21.8191 6.94969C21.8812 6.62381 21.9122 6.46087 21.8672 6.3335C21.8278 6.22177 21.7499 6.12768 21.6475 6.06802C21.5308 6 21.365 6 21.0332 6H4.57143ZM10 21C10 21.5523 9.55228 22 9 22C8.44772 22 8 21.5523 8 21C8 20.4477 8.44772 20 9 20C9.55228 20 10 20.4477 10 21ZM18 21C18 21.5523 17.5523 22 17 22C16.4477 22 16 21.5523 16 21C16 20.4477 16.4477 20 17 20C17.5523 20 18 20.4477 18 21Z" stroke="#9AA1AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>`,
          number: 1,
          title: "Submit Order",
          value: "SUBMITTED"
        },
        {
          activeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20 6L9 17L4 12" stroke="#CFA531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>`,
          completeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20 6L9 17L4 12" stroke="#125F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>`,
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20 6L9 17L4 12" stroke="#9AA1AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
          number: 2,
          title: "Accepted by SimpleCater",
          value: "RECEIVED"
        },
        {
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.35395 21C10.0591 21.6224 10.9853 22 11.9998 22C13.0142 22 13.9405 21.6224 14.6456 21M17.9998 8C17.9998 6.4087 17.3676 4.88258 16.2424 3.75736C15.1172 2.63214 13.5911 2 11.9998 2C10.4085 2 8.88235 2.63214 7.75713 3.75736C6.63192 4.88258 5.99977 6.4087 5.99977 8C5.99977 11.0902 5.22024 13.206 4.34944 14.6054C3.6149 15.7859 3.24763 16.3761 3.2611 16.5408C3.27601 16.7231 3.31463 16.7926 3.46155 16.9016C3.59423 17 4.19237 17 5.38863 17H18.6109C19.8072 17 20.4053 17 20.538 16.9016C20.6849 16.7926 20.7235 16.7231 20.7384 16.5408C20.7519 16.3761 20.3846 15.7859 19.6501 14.6054C18.7793 13.206 17.9998 11.0902 17.9998 8Z" stroke="#9AA1AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
          activeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.35395 21C10.0591 21.6224 10.9853 22 11.9998 22C13.0142 22 13.9405 21.6224 14.6456 21M17.9998 8C17.9998 6.4087 17.3676 4.88258 16.2424 3.75736C15.1172 2.63214 13.5911 2 11.9998 2C10.4085 2 8.88235 2.63214 7.75713 3.75736C6.63192 4.88258 5.99977 6.4087 5.99977 8C5.99977 11.0902 5.22024 13.206 4.34944 14.6054C3.6149 15.7859 3.24763 16.3761 3.2611 16.5408C3.27601 16.7231 3.31463 16.7926 3.46155 16.9016C3.59423 17 4.19237 17 5.38863 17H18.6109C19.8072 17 20.4053 17 20.538 16.9016C20.6849 16.7926 20.7235 16.7231 20.7384 16.5408C20.7519 16.3761 20.3846 15.7859 19.6501 14.6054C18.7793 13.206 17.9998 11.0902 17.9998 8Z" stroke="#CFA531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>`,
          completeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.35395 21C10.0591 21.6224 10.9853 22 11.9998 22C13.0142 22 13.9405 21.6224 14.6456 21M17.9998 8C17.9998 6.4087 17.3676 4.88258 16.2424 3.75736C15.1172 2.63214 13.5911 2 11.9998 2C10.4085 2 8.88235 2.63214 7.75713 3.75736C6.63192 4.88258 5.99977 6.4087 5.99977 8C5.99977 11.0902 5.22024 13.206 4.34944 14.6054C3.6149 15.7859 3.24763 16.3761 3.2611 16.5408C3.27601 16.7231 3.31463 16.7926 3.46155 16.9016C3.59423 17 4.19237 17 5.38863 17H18.6109C19.8072 17 20.4053 17 20.538 16.9016C20.6849 16.7926 20.7235 16.7231 20.7384 16.5408C20.7519 16.3761 20.3846 15.7859 19.6501 14.6054C18.7793 13.206 17.9998 11.0902 17.9998 8Z" stroke="#125F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>`,
          number: 3,
          title: "Notified Restaurant",
          value: "ORDERED"
        },
        {
          icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16 16V6.2C16 5.0799 16 4.51984 15.782 4.09202C15.5903 3.71569 15.2843 3.40973 14.908 3.21799C14.4802 3 13.9201 3 12.8 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H16ZM16 16H20.4C20.9601 16 21.2401 16 21.454 15.891C21.6422 15.7951 21.7951 15.6422 21.891 15.454C22 15.2401 22 14.9601 22 14.4V11.6627C22 11.4182 22 11.2959 21.9724 11.1808C21.9479 11.0787 21.9075 10.9812 21.8526 10.8917C21.7908 10.7908 21.7043 10.7043 21.5314 10.5314L19.4686 8.46863C19.2957 8.29568 19.2092 8.2092 19.1083 8.14736C19.0188 8.09253 18.9213 8.05213 18.8192 8.02763C18.7041 8 18.5818 8 18.3373 8H16M9 18.5C9 19.8807 7.88071 21 6.5 21C5.11929 21 4 19.8807 4 18.5C4 17.1193 5.11929 16 6.5 16C7.88071 16 9 17.1193 9 18.5ZM20 18.5C20 19.8807 18.8807 21 17.5 21C16.1193 21 15 19.8807 15 18.5C15 17.1193 16.1193 16 17.5 16C18.8807 16 20 17.1193 20 18.5Z" stroke="#9AA1AF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>`,
          activeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 16V6.2C16 5.0799 16 4.51984 15.782 4.09202C15.5903 3.71569 15.2843 3.40973 14.908 3.21799C14.4802 3 13.9201 3 12.8 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H16ZM16 16H20.4C20.9601 16 21.2401 16 21.454 15.891C21.6422 15.7951 21.7951 15.6422 21.891 15.454C22 15.2401 22 14.9601 22 14.4V11.6627C22 11.4182 22 11.2959 21.9724 11.1808C21.9479 11.0787 21.9075 10.9812 21.8526 10.8917C21.7908 10.7908 21.7043 10.7043 21.5314 10.5314L19.4686 8.46863C19.2957 8.29568 19.2092 8.2092 19.1083 8.14736C19.0188 8.09253 18.9213 8.05213 18.8192 8.02763C18.7041 8 18.5818 8 18.3373 8H16M9 18.5C9 19.8807 7.88071 21 6.5 21C5.11929 21 4 19.8807 4 18.5C4 17.1193 5.11929 16 6.5 16C7.88071 16 9 17.1193 9 18.5ZM20 18.5C20 19.8807 18.8807 21 17.5 21C16.1193 21 15 19.8807 15 18.5C15 17.1193 16.1193 16 17.5 16C18.8807 16 20 17.1193 20 18.5Z" stroke="#CFA531" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>`,
          completeIcon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16 16V6.2C16 5.0799 16 4.51984 15.782 4.09202C15.5903 3.71569 15.2843 3.40973 14.908 3.21799C14.4802 3 13.9201 3 12.8 3H5.2C4.0799 3 3.51984 3 3.09202 3.21799C2.71569 3.40973 2.40973 3.71569 2.21799 4.09202C2 4.51984 2 5.0799 2 6.2V12.8C2 13.9201 2 14.4802 2.21799 14.908C2.40973 15.2843 2.71569 15.5903 3.09202 15.782C3.51984 16 4.0799 16 5.2 16H16ZM16 16H20.4C20.9601 16 21.2401 16 21.454 15.891C21.6422 15.7951 21.7951 15.6422 21.891 15.454C22 15.2401 22 14.9601 22 14.4V11.6627C22 11.4182 22 11.2959 21.9724 11.1808C21.9479 11.0787 21.9075 10.9812 21.8526 10.8917C21.7908 10.7908 21.7043 10.7043 21.5314 10.5314L19.4686 8.46863C19.2957 8.29568 19.2092 8.2092 19.1083 8.14736C19.0188 8.09253 18.9213 8.05213 18.8192 8.02763C18.7041 8 18.5818 8 18.3373 8H16M9 18.5C9 19.8807 7.88071 21 6.5 21C5.11929 21 4 19.8807 4 18.5C4 17.1193 5.11929 16 6.5 16C7.88071 16 9 17.1193 9 18.5ZM20 18.5C20 19.8807 18.8807 21 17.5 21C16.1193 21 15 19.8807 15 18.5C15 17.1193 16.1193 16 17.5 16C18.8807 16 20 17.1193 20 18.5Z" stroke="#125F43" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>`,
          number: 4,
          title: "Assigned for Delivery",
          value: "ASSIGNED"
        }
      ]
    };
  }
};
</script>
