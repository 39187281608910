<template lang='pug'>
.main-container

  .view-content

    el-card
      div(slot='header')
        i.mat-icon toys
        | Payments

      el-table(:data='payments', v-loading='payments == null')
        template(slot='empty')
          | Retrieving
        el-table-column(prop='Date', label='Date', width='170')
          template(slot-scope='scope')
            | {{$formatDate(scope.row.date)}}
        el-table-column(label='Total cents')
          template(slot-scope='scope')
            | {{$formatCents(scope.row.total_cents)}}
        el-table-column(prop='type', label='Type')
        el-table-column(prop='status', label='Status')
        el-table-column(prop='notes', label='Notes')
  router-view
</template>

<script>
  import Toolbar from '../layout/Toolbar.vue'
  import _ from 'underscore'

  function data ( ) {
    return {
      payments: null
    }
  }


  function getPayments ( ) {
    return this.$simplecater.get(`/payments/invoice/${this.$route.params.id}`).then((r)=> {
      this.payments = _.chain(r.data.data).value()
    })
  }

  function created ( ) {
    this.getPayments()
  }

  const watch = {
    $route: function(newRoute) {
      if (newRoute.path === '/payments/:id') {
        this.getPayments();
      }
    }
  };

  export default {
    methods: { getPayments},
    components: { Toolbar, },
    created,
    data,
    watch
  }
</script>
