<style scoped lang="sass">
.logo-staff
  display: inline-block
  padding: 0.8rem 5rem
  vertical-align: top
  background-image: url(../assets/logo-new-2.svg)
  background-position: center
  background-size: cover
  background-repeat: no-repeat
  height: 35px
.navbar
  position: fixed
  top: 0
  left: 0
  right: 0
  height: $navbarHeight
  z-index: 500
  background: white
  background: rgba(white, 0.98)
  box-shadow: 0 1px 5px 0 rgba(black, 0.15)
  display: flex
  flex-direction: rows
  .navbar-left
    flex: 1
    .logo
      display: inline-block
      padding: #{$navbarHeight * 0.2} 1rem
      height: 100%
      vertical-align: top
      img
        // height: $navbarHeight * 0.6
        height: auto
        width: 160px
    // TODO: TEMP
    ul.el-menu
      display: inline-block
      border-bottom-color: transparent
  .navbar-right
    flex: 1
    display: flex
    flex-direction: rows
    align-items: center
    .inner
      width: 100%
      text-align: right
      .user-label
        display: inline-block
        padding: 0.35em 0.7em
        color: white
        background: #8e44ad
        border-radius: 2px
        margin-left: 15px
        font-size: 0.7rem
        text-transform: uppercase
        font-weight: 600
      .user-email
        display: inline-block
        color: $colorLight
        font-size: 0.8rem
        padding: 0 1rem
        font-weight: 600
        font-weight: 400
      .user-logout
        display: inline-block
        color: $colorLight
        text-transform: uppercase
        font-size: 0.7rem
        margin-right: 1rem
        font-weight: 600
        cursor: pointer
        transition: background 0.1s ease-in-out
        border: 1px solid $colorLightest
        padding: 0.3em 0.6em
        border-radius: 2px
        &:hover
          background: #f6f6f6
</style>
<template lang="pug">
.navbar
  .navbar-left
    .logo
      .logo-staff 
  .navbar-right
    .inner
      router-link(to="/user/requests/new")
        el-button(type="primary" v-if="userGroup.toLowerCase() === 'customer'") Create New Request
      .user-label {{userGroup}}
      .user-email {{userEmail}}
      .user-logout(@click='logout()') Log out
</template>

<script>
  import auth from '../auth.js'
  export default {
    data() {
      return {
        userGroup: auth.getSession().group,
        userEmail: auth.getSession().email,
        activeIndex: '1',
        activeIndex2: '1'
      };
    },
    methods: {
      loginRedirect( email, password, destination ) {
        console.log("LOGRD")
        this.$nprogress.start()
        auth.login(email, password).then((session)=>{
          this.$nprogress.done()
          this.$router.push(destination)
        }).catch((description, error)=>{
          this.$nprogress.done()
          this.$message.error(description)
        })
      },
      logout ( ) {
        window.localStorage.removeItem('session')
        this.$router.push('/')
      }
    }
  }
</script>
