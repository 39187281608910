<template lang='pug'>
el-tag(:type='severity', :size='size') {{friendlyName}}
</template>

<script>
  const methods = { }

  const computed = {
    severity: function(){
      switch(this.status){
        case 'WAITING_FOR_ESTIMATES':
          return 'warning'
        case 'ON_ESTIMATES_CREATION':
          return 'warning'
        case 'ESTIMATES_AVAILABLE':
          return 'success'
        case 'ESTIMATE_ORDERED':
          return 'success'
        case 'UPDATED_BY_CUSTOMER':
          return 'warning'
        case 'CANCELLED':
          return 'danger'
        case 'PENDING_PAYMENT':
          return 'warning'
        case 'PENDING_ASSIGNMENT':
          return 'danger'
        case 'ACCEPTED':
          return 'success'
        case 'REJECTED':
          return ''
        case 'RECEIVED':
          return 'success'
        case 'ORDERED':
          return 'success'
        case 'PAID':
          return 'success'
        case 'PENDING_CHANGES':
          return 'warning'
        case 'AVAILABLE':
          return 'success'
        case 'DRAFT':
          return ''
        case 'ASSIGNED':
          return 'success'
        case 'SUBMITTED':
          return 'warning'
        case 'COMPLETED':
          return 'info'
        case 'CLOSED':
          return 'info'
        case 'DECLINED':
          return 'danger'
      }
    },
    friendlyName: function(){
      return this.status.replace(/_/g, ' ')
    }
  }

  const props = {
    status: {
      required: true
    },
    size: {
      default: 'mini'
    }
  }

  export default { methods, props, computed }

</script>
