<template lang='pug'>
div
  .delivery-wrapper
    .info-card
      el-card
        div(slot='header', v-if='fullDelivery != null')
          i.mat-icon motorcycle
          | Delivery for <strong>{{ fullDelivery.customer_account_name }}</strong>
        template(v-if='fullDelivery !== null')
          el-row(:gutter='$rowGutter')
            el-col(:span='8')
              h3.cl-menu Pickup
              div(v-if='pickupAddress')
                big
                  i.mat-icon.cl-primary access_time
                  strong  {{ $formatTime(fullDelivery.pickup_time) }}
                div
                  strong {{ fullDelivery.vendor_name }}
                i.mat-icon.cl-primary place
                span.address-1  {{ pickupAddress.name || pickupAddress.address_line_1 }}
                .address-2(v-if='pickupAddress.address_line_2') {{ pickupAddress.address_line_2 }}
                .address-2(v-else)
                  em.cl-light &nbsp;
                .address-secondary {{ pickupAddress.city }}, {{ pickupAddress.state }} {{ pickupAddress.zip_code }}
            el-col(:span='8')
              h3.cl-menu Delivery
              div(v-if='deliveryAddress && request')
                big(v-if='order')
                  i.mat-icon.cl-primary access_time
                  strong  {{ $formatTime(fullDelivery.delivery_time) }}
                div
                i.mat-icon.cl-primary place
                span.address-1  {{ deliveryAddress.name || deliveryAddress.address_line_1 }}
                .address-2(v-if='request.suite') {{request.suite}}
                .address-2(v-else)
                  em.cl-light &nbsp;
                .address-secondary {{ deliveryAddress.city }}, {{ deliveryAddress.state }} {{ deliveryAddress.zip_code }}
            el-col(:span='8')
              h3.cl-menu Contact
              div(v-if='contact')
                div
                  div
                    strong {{ order.customer_account_name }}
                  div {{ contact.salutation }} {{ contact.first_name }} {{ contact.last_name }}
                  div {{ modifiedPhoneNumber(contact.phone_number) }}
                  div
                    a(:href="'mailto:' + contact.email") {{ contact.email }}

</template>

<script>
import modifyPhoneNumber from '@/mixins/modifiedPhoneNumber'

const mixins = [modifyPhoneNumber]

function data(){
  return {
    fullDelivery: null,
    deliveryAddress: null,
    pickupAddress: null,
    order: null,
    request: null,
    contact: null,
  }
}

function loadDelivery(){
  const target = `/deliveries/${this.deliverySummary.id}`
  // TODO catch
  return this.$simplecater.get(target).then( ( r ) => {
    this.fullDelivery = r.data.data

    this.$simplecater.get(`/orders/${this.fullDelivery.order_id}`).then( ( r ) => {
      this.order = r.data.data
      // Request
      this.$simplecater.get(`/requests/${this.order.request_id}`).then((r)=>{
        this.request = r.data.data
        // Contact
        this.$simplecater.get(`/contacts/${this.request.contact_id}`).then((r)=>{
          this.contact = r.data.data
        }).catch(()=>{
          this.$message.error("Error loading the contact information")
        })
      }).catch(()=>{
        this.$message.error("Error loading the request information")
      })
    }).catch(()=>{
      this.$message.error("Failed to load the order information")
    })

    this.$simplecater.get(`/addresses/${this.fullDelivery.delivery_address_id}`).then( ( r ) => {
      this.deliveryAddress = r.data.data
    }).catch(()=>{
      this.$message.error("Failed to load the delivery address information")
    })

    this.$simplecater.get(`/addresses/${this.fullDelivery.pickup_address_id}`).then( ( r ) => {
      this.pickupAddress = r.data.data
    }).catch(()=>{
      this.$message.error("Failed to load the pickup address information")
    })
  }).catch(()=>{
    this.$message.error("Failed to load the invoice")
  })
}

function created(){
  this.loadDelivery()
}

const watch = {
  '$route' (to, from) {
    console.log("MOVING!")
  }
}

const props = {
  deliverySummary: {
    required: true, type: Object
  }
}

const methods = {
  created, loadDelivery
}

const components = { }

const computed = {

}

export default { created, data, methods, props, components, computed, watch, mixins }
</script>

<style scoped lang="sass">

  .address-1
    color: #000
    font-weight: 600
  .address-2
    color: #444
  .address-secondary
    color: #999
  h3
    margin: 0.3rem 0
    font-weight: 600
</style>
