<template>
<el-card v-loading="account == null">
    <!-- <div slot="header"><i class="mat-icon">place</i>Address</div> -->
    <address-builder :label-position="labelPosition" :hideCancel="true"  :display-save="true" :account="account" @saveClick="onSave" :base-data="addressBase" v-if="backendAccount != null" :loading="loading"></address-builder>
</el-card>
</template>

<script>
  import Vue from 'vue'
  import AddressBuilder from '../../common/UserAddressBuilder.vue'

  function data(){
    return {
      loading: false,
      account: Object.assign({}, this.backendAccountaccount)
    }
  }

  function updateAccount(){
    this.loading = true
    this.$simplecater.put('/account/address', this.account).then((r)=>{
      this.$message("The account has been updated")
      this.$emit('change', Object.assign({},this.account))
      this.loading = false
    }).catch(()=>{
      this.$message.error("Failed to save the update")
      this.loading = false
    })
  }

  function onSave(address){
    console.log("SAVING ACCOUNT address", address)
    this.account = Object.assign({},this.account, address)
    this.updateAccount()
  }


  const methods = {
    onSave, updateAccount
  }

  const props = {
    labelWidth: { default: Vue.$labelWidth },
    labelPosition: { default: Vue.$defaultlabelPosition },
    // displaySave: {default: false}
    backendAccount: { required: true }
  }

  const watch = {
    backendAccount: function(newVal){
      console.log("NEW BACKEND ACCOUNT IN ADDRESS", newVal)
      this.account = Object.assign({}, newVal)
    }
  }

  const computed = {
    addressBase: function(){
      return {
        address_line_1: this.account.address_line_1,
        address_line_2: this.account.address_line_2,
        state: this.account.state,
        zip_code: String(this.account.zip_code || ""),
        city: this.account.city
      }
    }
  }

  const components = { AddressBuilder }

  export default { props, data, methods, components, watch, computed }
</script>
