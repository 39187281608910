<style lang="sass">
  .reset-password
    font-weight: 500
    font-size: 14px
    line-height: 20px
    letter-spacing: -0.03em
    color: #125F43
    margin-top: 8px
    margin-bottom: 16px
    cursor: pointer

  .login-err
    display: flex
    justify-content: flex-start
    align-items: center
    gap: 6px
    font-size: 14px
    line-height: 20px
    color: #D92D20
    margin-top: 16px
</style>

<template lang="pug">
el-form(@keyup.enter.native='submit')
  el-form-item(label='Email')
    el-input(class="test" v-model='email', :autofocus='true')
  el-form-item(label='Password')
    el-input(v-model='password', :type="showPassword ? 'text' : 'password'", auto-complete='off',  @keyup.enter='submit')
      i.mat-icon(@click="showPassword = !showPassword", slot="suffix") {{ showPassword ? 'visibility_off' : 'visibility' }}

  el-form-item
    .reset-password(@click="$emit('resetPasswordClick')") Reset Password
  el-form-item
    el-button(type='primary', @click='submit', :loading='submitting', class='full-width-button') Sign In
  //- el-alert(v-if='loginError != null', :title='loginError', type='error')
  .login-err(v-show='loginError != null')
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.0003 13.3332V9.99984M10.0003 6.6665H10.0087M18.3337 9.99984C18.3337 14.6022 14.6027 18.3332 10.0003 18.3332C5.39795 18.3332 1.66699 14.6022 1.66699 9.99984C1.66699 5.39746 5.39795 1.6665 10.0003 1.6665C14.6027 1.6665 18.3337 5.39746 18.3337 9.99984Z" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    | Incorrect email or password
</template>

<script>
  import auth from "../../auth.js"

  export default {
    data() {
      return {
        email: '',
        password: '',
        loginError: null,
        submitting: false,
        showPassword: false
      };
    },

    methods: {
      submit() {
        this.loginError = null
        this.submitting = true
        const handleNewSession = (session) => {
          this.submitting = false
          if(session.group === 'staff'){
            this.$router.push('/staff/overview')
          }else{
            this.$router.push('/user/overview')
          }
        }
        const handleFailedLogin = (description, error) => {
          console.log("Login failed", description, error)
          this.submitting = false
          this.loginError = description
        }
        auth.login(this.email.trim(), this.password.trim())
          .then(handleNewSession)
          .catch(handleFailedLogin)
      }
    }
  }
</script>

<style lang="sass" scoped>
.mat-icon
  margin-right: 5px
  cursor: pointer
  font-size: 20px

.el-form-item__label
  color: red 
</style>