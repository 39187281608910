export default {
  data() {
    return {
      mainWidth: 'auto',
      isPrintingForm: false
    }
  },
  mounted() {
    window.addEventListener('beforeprint', this.beforePrint)
    window.addEventListener('afterprint', this.afterPrint)
  },
  beforeUnmounted() {
    window.removeEventListener('beforeprint', this.beforePrint)
    window.removeEventListener('afterprint', this.afterPrint) 
  },
  methods: {
    beforePrint () {
      this.mainWidth = '160'
      this.isPrintingForm = true
    },
    afterPrint () {
      this.mainWidth = 'auto'
      this.isPrintingForm = false
    }
  }
}