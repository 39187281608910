<template>
  <div>
    <div class="sub-header-print"></div>
    <letter-head v-if="estimate && request" :no-padding="true" noBorder>
      <div class="not-final">Not Final Invoice</div>
      <div class="printable-title top">
        <div class="request-number">Request # {{ request.id }}</div>
        <div class="menu-name">{{ estimate.name }}</div>
      </div>
    </letter-head>
    <div class="printable-top-columns" v-if="request && estimate">
      <el-row :gutter="$rowGutter">
        <el-col :span="12">
          <div class="top-title">Delivery Address</div>

          <div class="custom-card" :style="{ fontSize: isPrintingForm ? '10px' : '14px' }" style="height: 100%;">
            <strong>{{ request.customer_account_name }} 111</strong>
            <div class="address-line">
              <div v-if="address">{{ address.address_line_1 }}</div>
              <div v-if="!request.suite && address && address.address_line_2">&nbsp;{{ address.address_line_2 }}</div>
              <div v-if="request !== null && request.suite">&nbsp;{{ request.suite }} </div>
            </div>
            <div class="address-line" v-if="address">{{ address.city }}, {{ address.state }} {{ address.zip_code }} </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="top-title">Delivery Details</div>
          <div class="custom-card" :style="{ fontSize: isPrintingForm ? '10px' : '14px' }">
            <div class="line-with-icon" style="margin-bottom: 4px;">
              <strong class="standout-accent">{{ request.name }}</strong>
            </div>
            <div class="line-with-icon date-info-container-wrapper" style="margin-bottom: 4px;">
              <div class="date-info-container">
                <div class="line-with-icon">
                  <!-- <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.5 5H1.5M8 1V3M4 1V3M3.9 11H8.1C8.94008 11 9.36012 11 9.68099 10.8365C9.96323 10.6927 10.1927 10.4632 10.3365 10.181C10.5 9.86012 10.5 9.44008 10.5 8.6V4.4C10.5 3.55992 10.5 3.13988 10.3365 2.81901C10.1927 2.53677 9.96323 2.3073 9.68099 2.16349C9.36012 2 8.94008 2 8.1 2H3.9C3.05992 2 2.63988 2 2.31901 2.16349C2.03677 2.3073 1.8073 2.53677 1.66349 2.81901C1.5 3.13988 1.5 3.55992 1.5 4.4V8.6C1.5 9.44008 1.5 9.86012 1.66349 10.181C1.8073 10.4632 2.03677 10.6927 2.31901 10.8365C2.63988 11 3.05992 11 3.9 11Z"
                      stroke="#125F43" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> -->
                  {{ 'Del Date: ' }}
                  <br>
                </div>
                <span class="standout-accent standout" style="margin-left: 5px;">{{ request.formatted_delivery_date }}
                </span>
              </div>
              <div class="date-info-container">
                <div class="line-with-icon">
                  <!-- <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10.5 5H1.5M8 1V3M4 1V3M3.9 11H8.1C8.94008 11 9.36012 11 9.68099 10.8365C9.96323 10.6927 10.1927 10.4632 10.3365 10.181C10.5 9.86012 10.5 9.44008 10.5 8.6V4.4C10.5 3.55992 10.5 3.13988 10.3365 2.81901C10.1927 2.53677 9.96323 2.3073 9.68099 2.16349C9.36012 2 8.94008 2 8.1 2H3.9C3.05992 2 2.63988 2 2.31901 2.16349C2.03677 2.3073 1.8073 2.53677 1.66349 2.81901C1.5 3.13988 1.5 3.55992 1.5 4.4V8.6C1.5 9.44008 1.5 9.86012 1.66349 10.181C1.8073 10.4632 2.03677 10.6927 2.31901 10.8365C2.63988 11 3.05992 11 3.9 11Z"
                      stroke="#125F43" stroke-linecap="round" stroke-linejoin="round" />
                  </svg> -->
                  {{ 'Del Time: ' }}
                  <br>
                </div>
                <span class="standout-accent standout" style="margin-left: 5px;">{{ request.formatted_delivery_time
                  }}</span>
              </div>
            </div>
            <div>
              <div class="line-with-icon">
                <!-- <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 10.5C10 9.80222 10 9.45333 9.91388 9.16943C9.71998 8.53023 9.21977 8.03002 8.58057 7.83612C8.29667 7.75 7.94778 7.75 7.25 7.75H4.75C4.05222 7.75 3.70333 7.75 3.41943 7.83612C2.78023 8.03002 2.28002 8.53023 2.08612 9.16943C2 9.45333 2 9.80222 2 10.5M8.25 3.75C8.25 4.99264 7.24264 6 6 6C4.75736 6 3.75 4.99264 3.75 3.75C3.75 2.50736 4.75736 1.5 6 1.5C7.24264 1.5 8.25 2.50736 8.25 3.75Z"
                    stroke="#125F43" stroke-linecap="round" stroke-linejoin="round" />
                </svg> -->

                {{ '# of People: ' }}<span class="standout-accent standout">{{ request.people }}</span>
              </div>
            </div>
          </div>
        </el-col>
      </el-row><br />
      <div class="products-from" v-if="estimate" :style="{ fontSize: isPrintingForm ? '12px' : '16px' }"
        style="font-size: 0.8rem; font-weight: bold">Products from&nbsp;<strong class="cl-primary">{{
          estimate.vendor_name }}</strong></div>
    </div>
    <page-content v-loading="request === null || estimate === null">
      <div class="user-custome-estimates-table">
        <table class="user-custome-table">
          <thead>
            <tr>
              <th class="left-text">Name</th>
              <th>Qty</th>
              <th>Total Serves</th>
              <th>Unit Price</th>
              <th>Total Price</th>
            </tr>
          </thead>
          <tbody>
            <tr class="user-custome-table-row" v-for="(item, i) in itemsWithoutTax" :key="i">
              <td class="user-custome-table-item left-text">
                <div class="pos-name">{{ item.name }}</div>
                <div class="secondary" v-if="item.type == 'PRODUCT'">{{ item.description }}</div>
                <div class="secondary serves-count" v-if="item.serves">Serves: {{ item.serves }}</div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  {{ item.quantity }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  {{ item.serves * item.quantity }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  {{ $formatCents(item.sale_price_cents) }}
                </div>
              </td>
              <td>
                <div class="td-content-with-icon">
                  {{ $formatCents(item.total_cents) }}
                </div>
              </td>
            </tr>
            <tr class="user-custome-table-row total-row" cellspacing="0" cellpadding="0">
              <td>
              </td>
              <td></td>
              <td></td>
              <td  class="user-custome-table-item">
                <div class="sub-total secondary-text">Subtotal</div>
                <div class="sub-total secondary-text" v-if='tax'>Tax</div>
                <div class="sub-total secondary-text" v-if='tips'>Driver tips</div>
                <div>Total</div>
              </td>
              <td>
                <div class="sub-total sub-total-price">{{ $formatCents(subtotalCents) }}</div>
                <div class="sub-total secondary-text" v-if='tax'>{{ $formatCents(tax.sale_price_cents) }}</div>
                <div class="sub-total secondary-text" v-if='tips'>{{ $formatCents(tips) }}</div>
                <div>{{ $formatCents(totalCents) }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div :style="`marginTop: ${isPrintingForm ? '30px' : '30px'}; page-break-inside: avoid;`" class="check-footer"
        :class="`${isPrintingForm ? 'custom-card-print' : 'custom-card'}`">
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <div class="check-footer-contact-us">
            Contact Us
          </div>
          <div class="check-footer-info" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
            for any issues, questions or concerns
          </div>
        </div>
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`"
            viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.1737 11.8043C12.1017 13.7372 13.3667 15.5487 14.9689 17.1508C16.571 18.7529 18.3825 20.018 20.3153 20.946C20.4816 21.0258 20.5647 21.0657 20.6699 21.0963C21.0437 21.2053 21.5027 21.127 21.8193 20.9004C21.9083 20.8366 21.9846 20.7604 22.137 20.6079C22.6031 20.1418 22.8362 19.9087 23.0706 19.7564C23.9544 19.1817 25.0938 19.1817 25.9777 19.7564C26.2121 19.9087 26.4451 20.1418 26.9113 20.608L27.1711 20.8678C27.8797 21.5764 28.234 21.9307 28.4265 22.3112C28.8092 23.0679 28.8092 23.9616 28.4265 24.7184C28.234 25.0989 27.8797 25.4532 27.1711 26.1617L26.9609 26.3719C26.2548 27.0781 25.9017 27.4312 25.4216 27.7008C24.889 28.0001 24.0616 28.2152 23.4507 28.2134C22.9001 28.2118 22.5238 28.1049 21.7712 27.8913C17.7267 26.7434 13.9102 24.5774 10.7262 21.3934C7.54224 18.2095 5.37627 14.393 4.22831 10.3485C4.0147 9.59586 3.9079 9.21956 3.90626 8.66897C3.90444 8.05801 4.11959 7.23068 4.41882 6.69801C4.68848 6.21797 5.04157 5.86489 5.74773 5.15872L5.95791 4.94854C6.6665 4.23995 7.02079 3.88566 7.4013 3.6932C8.15805 3.31044 9.05174 3.31044 9.80848 3.6932C10.189 3.88566 10.5433 4.23995 11.2519 4.94854L11.5117 5.20837C11.9778 5.67451 12.2109 5.90758 12.3633 6.14195C12.938 7.0258 12.938 8.16525 12.3633 9.0491C12.2109 9.28347 11.9778 9.51654 11.5117 9.98268C11.3593 10.1351 11.2831 10.2113 11.2193 10.3004C10.9926 10.617 10.9143 11.076 11.0233 11.4498C11.054 11.555 11.0939 11.6381 11.1737 11.8043Z"
              stroke="#E5DC84" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div class="check-footer-info">
            800 310-0650
          </div>
        </div>
        <div class="check-footer-item" :style="`fontSize: ${isPrintingForm ? '10px' : '18px'}`">
          <svg :width="`${isPrintingForm ? '12' : '18'}`" :height="`${isPrintingForm ? '12' : '18'}`"
            viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.1665 9.33337L14.0531 16.954C14.9346 17.5711 15.3754 17.8796 15.8549 17.9991C16.2784 18.1047 16.7213 18.1047 17.1448 17.9991C17.6243 17.8796 18.065 17.5711 18.9466 16.954L29.8332 9.33337M9.5665 26.6667H23.4332C25.6734 26.6667 26.7935 26.6667 27.6491 26.2307C28.4018 25.8472 29.0137 25.2353 29.3972 24.4827C29.8332 23.627 29.8332 22.5069 29.8332 20.2667V11.7334C29.8332 9.49316 29.8332 8.37306 29.3972 7.51741C29.0137 6.76476 28.4018 6.15284 27.6491 5.76935C26.7935 5.33337 25.6734 5.33337 23.4332 5.33337H9.5665C7.32629 5.33337 6.20619 5.33337 5.35054 5.76935C4.59789 6.15284 3.98597 6.76476 3.60248 7.51741C3.1665 8.37306 3.1665 9.49316 3.1665 11.7334V20.2667C3.1665 22.5069 3.1665 23.627 3.60248 24.4827C3.98597 25.2353 4.59789 25.8472 5.35054 26.2307C6.20619 26.6667 7.32629 26.6667 9.5665 26.6667Z"
              stroke="#E5DC84" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <div class="check-footer-info">
            info@simplecater.com
          </div>
        </div>
      </div>
    </page-content>
  </div>
</template>

<script>
import mainWidth from '@/mixins/mainWidth'
import isPrintingForm from '@/mixins/mainWidth'
import LetterHead from './LetterHead.vue'
import PageContent from './PageContent.vue'
import _ from 'underscore'
import TableSummary from './TableSummary.vue'

function data() {
  return {
    address: null,
    request: null,
    estimate: null
  }
}

function mounted() {
  this.getData()
}

function getData() {
  const fullEstimate = `/estimates/${this.id}`

  return this.$simplecater.get(fullEstimate).then((r) => {
    const estimateData = r.data.data;
    estimateData.items = estimateData.items.map(item => ({ ...item, total_cents: item.sale_price_cents * item.quantity }));
    this.estimate = estimateData

    this.$simplecater.get(`/requests/${this.estimate.request_id}`).then((r) => {
      this.request = r.data.data;
      this.$simplecater.get(`/addresses/${this.request.address_id}`).then((r) => {
        this.address = r.data.data
      }).catch(() => {
        this.$message.error("Error loading the address information")
      })
    }).catch(() => {
      this.$message.error("Error loading the request information")
    });
  }).catch(() => {
    this.$message.error("Failed to load the list of products")
  })
}

const mixins = [mainWidth, isPrintingForm]

const methods = { getData };

const components = { LetterHead, PageContent, TableSummary }

const props = {
  id: { required: true }
}

const computed = {
  totalCents() {
    return (this.estimate) ? this.itemsTotalCents + this.tips : 0
  },

  itemsTotalCents() {
    return (this.estimate) ? _.reduce(this.estimate.items, (acc, i) => (acc + i.total_cents), 0) : 0
  },

  itemsWithoutTax() {
    return (this.estimate) ? _.filter(this.estimate.items, (x) => (x.type != "TAX")) : []
  },

  tax() {
    return (this.estimate) ? _.find(this.estimate.items, (x) => (x.type === "TAX")) : null
  },

  tips() {
    return (this.estimate && this.estimate.tips > 0) ? this.estimate.tips : 0
  },

  subtotalCents() {
    return (this.estimate) ? _.reduce(this.estimate.items, (memo, i) => {
      return (i.type != "TAX") ? memo + (i.sale_price_cents * i.quantity) : memo
    }, 0) : 0
  },
}

export default { data, mixins, components, mounted, methods, props, computed }
</script>

<style scoped lang="sass">

    .top-column
      width: 100%
  
    .standout
      font-weight: bold
      color: #6E6E68
      margin-left: 0
  
    .description
      font-style: italic
      font-size: 12px
      word-break: break-word
      color: #888
  
    .not-final
      font-size: 20px
      font-weight: bold
      color: #000
  
    .printable-title.top
      margin-top: 0px
  
    .standout-accent
      color: #000
  
    .serves
      color: #444
  
    .request-number  
      color: #888
      font-size: 1.125rem
      font-weight: 500
      margin-top: .5rem
  
    .menu-name
      color: #23813E
      font-size: .875rem
      font-weight: 500
  
    .top-title
      color: #000
      font-weight: 600 !important
      margin-bottom: 10px
  
    .address-line
      display: flex
      color: #70706B
  
    .line-with-icon
      display: flex
      align-items: center
      gap: 4px
  
    .bottom-space
      margin-bottom: 10px
  
    .custom-card
      min-height: 148px
      font-weight: 600
      padding: 20px
  
    .table-sum
      border: 1px solid rgb(242, 242, 242)
      border-radius: 0px 0 18px 18px
      padding: 12px
  
    .table-sum-content
      width: 40%
  
    .table-sum-space
      background: #F0F6F4
      display: flex
      justify-content: flex-end
      padding: 12px 0
      border-radius: 8px

    .user-custome-estimates-table
      border: 1px solid rgb(242, 242, 242)
      padding:  0 20px 20px 20px
      border-radius: 18px

    .user-custome-table
      border-collapse: collapse
      font-size: 14px
      thead
        background: rgb(240, 246, 244)
        color: rgb(17, 95, 67)
        font-size: 14px
      .pos-name
        font-size: 14px 
      .total-row
        background: #F0F6F4
        border-radius: 18px
        td
          border: none

    .date-info-container
      display: flex   

    .date-info-container-wrapper
      display: flex
      flex-direction: column
      align-items: flex-start 
  </style>