<template>
  <div class="numeric-input">
    <div class="svg-icon">
      <slot></slot>
    </div>
    <div class="input-wrapper">
      <input type="text" v-model="value" @input="updateValue" :style="{width: width}"/>
      <div class="input-buttons">
        <button type="button" @click="decrementValue" :disabled="value <= 1">-</button>
        <button type="button" @click="incrementValue">+</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: 1,
    };
  },
  props: {
    initialValue: {
      type: Number | String,
      default: 1
    },
    decimal: {
      type: Boolean,
      default: false
    },
    inheritanceVal: {
      type: Boolean,
      default: false
    },

    width: {
      require: false
    }
  },
  methods: {
    updateValue(event) {
      let newValue;
      if(this.decimal) {
            const valueWithDot = event.target.value.replace(/,/g, '.')
            newValue = valueWithDot[valueWithDot.length - 1] === '.' ? valueWithDot : valueWithDot.replace(/[^0-9.]/g, '').replace(/\.{2,}/g, '.');
      } else {
            newValue = event.target.value.replace(/[^0-9]/g, '')
      }

      if (newValue === '') {
        this.value = '';
      } else if (newValue < 1) {
        this.value = 1;
      } else {
        this.value = newValue[newValue.length - 1] === '.' ? newValue : +newValue;
      }
      this.$emit('updateValue', this.value)
    },
    incrementValue() {  
      this.value = parseInt(this.value === '' ? 0 : this.value) + 1 + '';
      this.$emit('updateValue', +this.value)
    },
    decrementValue() {
      if (this.value > 1) {
        this.value = parseInt(this.value) - 1 + '';
        this.$emit('updateValue', +this.value)
      }
    },
  },

  mounted () {
    console.log(this.initialValue)
    if (this.inheritanceVal) {
      this.value = this.initialValue
    }
  },

  watch: {
    initialValue(val) {
      this.value = val
    }
  }
};
</script>

<style scoped>
.input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
}

input {
   min-width: 8.8rem;
   border-radius: 0px 12px 12px 0px;
   height: 2.5rem;
}

.numeric-input {
    display: flex;
}

input:focus-visible {
    outline: none
}

.input-wrapper input[type="text"] {
  appearance: none;
  -webkit-appearance: none;
  width: 8.2rem;
  padding: 1rem;
  text-align: start;
  border: .0625rem solid #ccc;
  font-weight: 500;
}

.input-buttons {
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  position: absolute;
  right: 0;
  padding-right: 1rem;
  gap: .25rem
}

.input-buttons button {
  border: none;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  background: #EBEBE4;
  border-radius: 6px;
}

.input-buttons button:hover {
  background-color: #f1f1f1;
}

.svg-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FAFAF8;
    border-width: 1px 0px 1px 1px;
    border-style: solid;
    border-color: #EBEBE4;
    border-radius: 12px 0px 0px 12px;
    width: 3rem;
    height: 2.5rem;
}
</style>
