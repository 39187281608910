<template>
<div>
    <el-table :data="invoices" v-loading="invoices == null">
        <el-table-column type="expand"><template slot-scope="props">
                <invoice :invoice-summary="props.row"></invoice>
            </template></el-table-column>
        <el-table-column label="Date" width="140"><template slot-scope="scope">
                <div><i class="mat-icon secondary">date_range</i> {{$formatDate(scope.row.delivery_date)}}</div>
            </template></el-table-column>
        <el-table-column label="Customer"><template slot-scope="scope">{{scope.row.customer_account_name}}<br />
                <div class="secondary">Order #{{scope.row.order_id}}</div>
            </template></el-table-column>
        <el-table-column label="Event"><template slot-scope="scope"><strong>{{ scope.row.request_name }}</strong>
                <div v-if="scope.row.vendor_name">{{ scope.row.vendor_name }}</div>
                <div class="secondary">Invoice #{{ scope.row.id }}</div>
            </template></el-table-column>
        <el-table-column label="Total" width="90"><template slot-scope="scope">
                <div>{{$formatCents(scope.row.total_cents)}}</div><small><strong>{{sourceToString(scope.row.payment_source)}}</strong></small>
            </template></el-table-column>
        <el-table-column label="Balance" width="90"><template slot-scope="scope">
                <div>{{$formatCents(scope.row.balance_cents)}}</div>
            </template></el-table-column>
        <el-table-column label="Status"><template slot-scope="scope">
                <status-tag :status="scope.row.status"></status-tag>
            </template></el-table-column>
        <el-table-column label="Actions"><template slot-scope="scope">
                <el-dropdown class="order-list__dropdown"><span class="el-dropdown-link">Choose action<i class="el-icon-arrow-down el-icon--right"></i></span>
                    <el-dropdown-menu class="order-list__dropdown-menu" slot="dropdown">
                        <el-dropdown-item v-if="showPayment(scope.row)">
                            <el-button type="text" @click="openPaymentModal(scope.row)">{{getPaymentTitle(scope.row)}}</el-button>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="scope.row.tips === 0">
                            <el-button type="text" @click="openTipsModal(scope.row)">Add tips</el-button>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="scope.row.id!= null">
                            <el-button type="text" @click="$openInBlank(`/print/invoices/${scope.row.id}`)">Invoice Summary&nbsp;<span class="mat-icon">open_in_new</span></el-button>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="scope.row.order_id!= null">
                            <el-button type="text" @click="$openInBlank(`/print/orders/${scope.row.order_id}`)">Invoice Detailed&nbsp;<span class="mat-icon">open_in_new</span></el-button>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="scope.row.order_id!= null">
                            <el-button type="text">
                                <router-link :to="'/staff/payments/' + scope.row.id">Payments&nbsp;</router-link>
                            </el-button>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="scope.row.payment_source != &quot;OFFLINE&quot; &amp;&amp; scope.row.status == &quot;PENDING_PAYMENT&quot; ">
                            <el-button type="text" @click="retryDialog(scope.row.id)">Retry</el-button>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </template>
            </el-table-column>
    </el-table>
    <scheduler :invoice="invoiceOnSchedule" :visible.sync="displayScheduler" v-if="displayScheduler"></scheduler>
    <el-col>
        <el-pagination 
          v-if="pagination.pages > 1" 
          @current-change="onPaginationChange" 
          :page-size="pagination.per_page"
          :pager-count="5"
          layout="prev, pager, next" 
          :total="pagination.total"> 
        </el-pagination>

    </el-col>
    <pop-up :showPopUp="showChangePaymentPopUp" width='300px' title="Change payment type" @closePopUp="closeChangePayMethodPopUp()">
      <div class="change-pay-pop-up">
        <div class="change-pay-pop-up-message">{{errorMessage}}</div>
        <el-select class="full-width-select" v-model="currentPayMethod" placeholder="Select pay method">
                <el-option class="user-select" v-for="item in userCards" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div class="change-pay-pop-up-footer">
          <base-button @onClick="closeChangePayMethodPopUp()">Cancel</base-button>
          <base-button :confirmBtn="true" @onClick="changePayMethod()">OK</base-button>
        </div>
      </div>
      
    </pop-up>
    <router-view></router-view>
</div>
</template>

<style scoped lang="sass">
.change-pay-pop-up
 font-family: "Open Sans", Helvetica, sans-serif
 &-footer
    display: flex
    gap: 1rem
 &-message
    margin-bottom: 1.875rem
 &-footer
    margin-top: 1.125rem


</style>

<script>
  import StatusTag from '../../common/StatusTag.vue'
  import Scheduler from './Scheduler.vue'
  import Invoice from './Invoice.vue'
  import PopUp from '@/common/PopUp.vue'
  import BaseButton from '@/user/blocks/BaseButton.vue'

  function data(){
    return {
      invoices: null,
      displayScheduler: false,
      invoiceOnSchedule: null,
      showChangePaymentPopUp: false,
      errorMessage: 'error',
      currentInvoceId: '',
      currentPayMethod: '',
      userCards: [],
      pagination: {
        per_page: null,
        current: null,
        pages: null,
        total: null,
      }
    }
  }
  function onPaginationChange(current_page){
    this.getData(current_page);
  }
  function created(){
    this.getData()
  }
  function retryDialog(id){
    const title = "Retry invoice payment"
    const msg = "A new attempt to charge the customer's card will be made. Continue?"
    this.$confirm(msg, title, {
      confirmButtonText: 'Charge card',
      cancelButtonText: 'Cancel',
      type: 'warning'
    }).then(() => {
      this.retry(id)
    })
  }
  function openPaymentModal ({ id, balance_cents, payment_source, total_cents, customer_id, ...rest }) {
    if(isBill({ balance_cents, payment_source, total_cents })) 
      this.$router.push({ path: '/staff/invoices/payment', query: { id, balance: balance_cents } })
    else 
      this.$confirm("NOTE: Customer's credit card will be charged", "Payment by card", {
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(() => {
          this.$simplecater.post(`invoices/${id}/process`, {payment_source})
            .then(() => {
              this.getData()
              this.$message("The invoice are successfully paid")
            })
            .catch(async (err) => {
              this.$message.error("Failed to pay the invoice");
              this.errorMessage = "Unfortunatly payment has been failed with reason: " + err?.response?.data.error.reason + ". You can change payment type if you want:";
              const getUserCards = await this.$simplecater.get(`customers/${customer_id}/cards`);
              this.currentInvoceId = id
              this.userCards = getUserCards.data.data;
              this.showChangePaymentPopUp = true;
              // this.$confirm("Unfortunatly payment has been failed with reason: " + err.response.data.error.reason + ". You can change payment type if you want:", "Change payment type", {
              //   confirmButtonText: "Change",
              //   cancelButtonText: "Cancel",
              //   type: "warning"
              // })
              //   .then(() => {
              //     this.$simplecater.post(`/invoices/${id}/offline-payment`)
              //       .then(() => {
              //         this.getData()
              //         this.$message("Type of payment successfully changed")
              //       })
              //   })
            })
        })
  }
  function changePayMethod () {
      this.$simplecater.post(`invoices/${this.currentInvoceId}/change-payment`, { payment_source: this.currentPayMethod }).then(() => {
          this.getData()
          this.closeChangePayMethodPopUp()
          // this.$simplecater.post(`invoices/${this.currentInvoceId}/process`, { payment_source: this.currentPayMethod })
          //   .then(() => {
          //     this.closeChangePayMethodPopUp()
          //     this.getData()
          //     this.$message("The invoice are successfully paid")
          //   }).catch((err) => {
          //     this.$message.error("Failed to pay the invoice");
          //   })
      }).catch(err => {
              this.errorMessage = 'Unfortunatly payment has been failed with reason: Invalid payment source. You can change payment type if you want:'
              this.$message.error("Failed to change payment method.");
            })
  }
  function closeChangePayMethodPopUp() {
    this.currentPayMethod = ''
    this.showChangePaymentPopUp = false
  }
  function openTipsModal (row) {
    this.$router.push({ path: '/staff/invoices/tips', query: { id: row.id, balance: row.balance_cents } })
  }
  function scheduleDialog(invoice){
    this.invoiceOnSchedule = invoice
    this.displayScheduler = true
  }
  function retry(id){
    const target = `/invoices/${id}/retry`
    this.$simplecater.post(target).then(r => {
      this.$message("The payment was processed")
      this.getData()
    }).catch((e)=>{
      // TODO improve message because only a generic error comes back
      const msg = "Failed to process payment."
      this.$message.error(msg)
    })
  }
  function schedule(id){
    this.$message("PENDING WIRING")
  }
  function goToInvoice(id){
    this.$router.push(`/invoices/${id}`)
  }
  function goToDelivery(id){
    this.$router.push(`/deliveries/${id}`)
  }
  function getData(current_page){
    let reqParams = (current_page ?{params:{ page:current_page}}:{params:{}});
    if(this.sorting)
      this.$isNumber(this.sorting) ? reqParams.params.id=this.sorting : reqParams.params.q=this.sorting
    if(this.payment?.length === 1)
      reqParams.params.type = this.payment[0]
    this.$simplecater.get(this.targetUri,reqParams)
      .then((r)=>{
        this.invoices = r.data.data;
        this.pagination = r.data.pagination;
      })
      .catch(()=>(this.$message.error('Failed to load data')))
  }
  function sourceToString(source){
    if (source == "OFFLINE") return "Check"
    return "Stripe"
  }
  const isBill = ({ balance_cents, payment_source, total_cents, }) => payment_source === 'OFFLINE' || !(balance_cents === total_cents && balance_cents !== 0)
  const getPaymentTitle = (source) => `Make Payment by ${isBill(source) ? 'bill' : 'card'}`
  const showPayment = ({ balance_cents, payment_source, total_cents, status }) => {
    const isStripeAndNotZero = payment_source !== "OFFLINE" && (balance_cents !== total_cents && balance_cents !== 0)
    return status == "PENDING_PAYMENT" || isStripeAndNotZero
  }
  const watch = {
    sorting: function(){
      this.getData();
    },
    payment: function(){
      this.getData();
    },
    filter: function(newFilter){
      console.log("FILTER FROM TO", this.filter, newFilter)
    },
    $route: function() {
      this.getData();
    }
  }
  const computed = {
    targetUri: function(){
      if(this.filter == 'all'){
        return '/invoices'
      }else{
        return `/invoices/${this.filter}`
      }
    }
  }
  const components = {
    StatusTag, Scheduler, Invoice, PopUp, BaseButton
  }
  const methods = {
    getData, onPaginationChange, retry, retryDialog, sourceToString, openPaymentModal, openTipsModal,
    getPaymentTitle, showPayment, changePayMethod, closeChangePayMethodPopUp
  }
  const props = {
    filter: { required: true },
    payment: { required: false },
    sorting:{ required: false }
  }
  export default { methods, components, data, created, watch, props, computed }
</script>