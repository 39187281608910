<template>
    <div class="user-stepper">
        <div class="step-with-line" v-for="(step, i) in steps" :class="i + 1 === steps.length ? 'last-step' : ''">
            <div class="step">
            <div class="step-icon" :class="[{stepIconSmall: smallVersion}, step.number === activeStep ? 'active' : step.number < activeStep ? 'completed' : '']">
                <div v-html="step.number === activeStep ? step.activeIcon : step.number < activeStep ? step.completeIcon : step.icon"></div>
            </div>
            <div class="step-info">
                <div v-show="!hideStep" class="step-number">
                    Step: {{step.number}}
                </div>
                <div class="step-title"> 
                    {{step.title}}
                </div>
            </div>
            </div>
            <div class="line" v-if="i + 1 < steps.length">
            </div>
        </div>
    </div>
</template>

<style scoped lang="sass">
 .user-stepper
    display: flex
    flex-direction: row
    width: 100%
    justify-content: space-between
    .step
        display: flex
        gap: 1rem
        align-items: center

    .step-icon
        width: 2.8rem
        height: 2.8rem
        min-height: 2.8rem
        min-width: 2.8rem
        border-radius: 100%
        background: #F7F8F9
        display: flex
        align-items: center
        justify-content: center
    .step-number
        color: #125F43
        font-size: .75rem
        margin-bottom: .125rem
    .step-title
        color: #1F1F18
        font-size: .75rem
    .step-with-line
        display: flex
        flex-direction: row
        align-items: center
        width: 100%  
    .line
        height: .0625rem
        background: #E8E8E3
        width: auto
        margin: 0 20px
        flex: 1 
    .last-step
        width: fit-content
        min-width: fit-content
    .active
        background: #F5F2D0
    .completed
        background: #F4F9F5
    .stepIconSmall
        width: 2rem
        min-width: 2rem
        height: 2rem
        min-height: 2rem
    .stepSmallTitle
        font-size: .875rem                   

</style>

<script>
  export default {
  // "WAITING_FOR_ESTIMATES"
  // "ESTIMATES_AVAILABLE"
  // "ESTIMATE_ORDERED"
    props: {
        steps: {
            Type: Array
        },
        active: {
            default: 0
        },
        smallVersion: {
            type: Boolean,
            default: false
        },
        hideStep: {
            require: false,
            default: false
        }
    },

    computed: {
        activeStep: function () {
           const activeStepObj = this.steps?.find(step => step.value === this.active[0])
           if(!activeStepObj) {
            return this.steps[0].number
           }
           return activeStepObj.number
        }
    },
   }
</script>
 