import auth0 from 'auth0-js'
import jwtDecode from 'jwt-decode'
import Vue from 'vue'
import axios from 'axios'

const authService = new auth0.WebAuth({
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientID: process.env.VUE_APP_CLIENT_ID
});

const client = authService.client

const statelessLogin = (username, password) => {
  return new Promise( (resolve, reject)=>{
    client.login({
      realm: 'Username-Password-Authentication',
      username: username,
      password: password,
      audience: process.env.VUE_APP_API_URL,
      scope: 'openid',
      }, function(err, authResult) {
        if(err){
          console.log("Login failed", err.description)
          reject(err.description, err)
        }else{
          const payload = jwtDecode(authResult.idToken)
          const session = {
            group: payload["https://simplecater.com/group"],
            expiration: payload.exp,
            email: payload.email,
            verified: payload.email_verified,
            token: authResult.accessToken
          }
          resolve(session)
        }
    })
  })
}

const login = (username, password) => {
  return new Promise( (resolve, reject)=>{
    statelessLogin(username, password).then((session)=>{
      localStorage.setItem("session", JSON.stringify(session))
      setupSimpleCaterClient(session)
      resolve(session)
    }).catch(reject)
  })
}

const createSimpleCaterClient = (token)=>{
  const instance = axios.create({
    baseURL: process.env.VUE_APP_API_URL + '/v1'
  })
  instance.defaults.headers.common['Authorization'] = "Bearer " + token
  return instance
}

const setupSimpleCaterClient = (sessionToUse) => {
  const session = sessionToUse || getSession() || {}
  const token = session.token
  Vue.prototype.$simplecater = createSimpleCaterClient(token)
}

const stillValid = (timestamp) => {
  return Math.ceil(Date.now()/1000) < timestamp;
}

const logout = () => {
  localStorage.removeItem("session")
}

const getSession = () => {
  return JSON.parse(localStorage.getItem("session"))
}

const validSessionGroupIs = (group) => {
  const session = getSession() || {}
  if(session){
    return group === session.group && stillValid(session.expiration)
  }else{
    return false
  }
}

const validStaffSession = () => {
  return validSessionGroupIs('staff')
}

const validCustomerSession = () => {
  return validSessionGroupIs('customer')
}

const anyValidSession = () => {
  const session = getSession() || {}
  return stillValid(session.expiration)
}

const changePassword = (inscribedEmail = null) => {
  return new Promise((resolve, reject)=>{
    const connection = "Username-Password-Authentication"
    const email = inscribedEmail || getSession().email
    authService.changePassword({ email, connection }, (e)=>{
      if (e == null) {
        console.log("LINK SENT")
        resolve()
      }else{
        reject(e)
      }
    })
  })
}

export default {
  login, logout, validCustomerSession, validStaffSession, getSession,
  setupSimpleCaterClient, changePassword, anyValidSession
}
