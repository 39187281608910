<template lang='pug'>
div
  el-row(:gutter='$rowGutter')

    el-col(:span='24')
      el-card
        div(slot='header')
          i.mat-icon playlist_add_check
          | Items
        template(v-if='fullOrder !== null')
          el-table(v-loading='loadingProducts' :data='itemsWithoutTax')
            el-table-column(prop='name', label='Name')
            el-table-column(prop='quantity', label='Qty.')
            el-table-column(prop='sale_price_cents', label='Unit price')
              template(slot-scope='scope')
                | {{$formatCents(scope.row.sale_price_cents) }}
            el-table-column(prop='total_cents', label='Price')
              template(slot-scope='scope')
                | {{$formatCents(scope.row.total_cents) }}

          el-row(:gutter='$rowGutter')
            el-col(:span='13') &nbsp;
            el-col(:span='9', v-if='subtotalCents')
              .numeric-summary
                .label Subtotal
                .value {{ $formatCents(subtotalCents) }}
              .numeric-summary(v-if='tax')
                .label Tax
                .value {{ $formatCents(tax.sale_price_cents) }}
              .numeric-summary(v-if='tips')
                .label Driver tip
                .value {{ $formatCents(tips) }}
              .numeric-summary
                .label
                  big Total
                .value
                  big {{$formatCents(totalCents)}}

          el-button(type='primary' @click='openEditProductModal')
            | Edit products

          el-button(type='primary' @click='cloneOrder')
            | Clone order

    el-col(:span='24', style='margin-top: 1.5rem')
      el-card
        div(slot='header')
          i.mat-icon playlist_add_check
          | Misc
        template(v-if='fullOrder !== null')
          el-row(:gutter='$rowGutter')
            el-col(:span='8')
              h3(style='margin-top:0') Request notes
              blockquote.classic(v-if='fullOrder.request_notes && fullOrder.request_notes.trim() != ""') {{fullOrder.request_notes}}
              em(v-else) N/A
              h3 Notes on accept
              blockquote.classic(v-if='fullOrder.accept_notes && fullOrder.accept_notes.trim() != ""') {{fullOrder.accept_notes}}
              em(v-else) N/A
              h3 Staff notes on order
              blockquote.classic(v-if='fullOrder.vendor_order_notes && fullOrder.vendor_order_notes.trim() != ""') {{fullOrder.vendor_order_notes}}
              em(v-else) N/A

            el-col(:span='8')
              |

  el-dialog(
    v-if='modalVisible'
    title='Edit products'
    :visible.sync='modalVisible'
    width='60%')
    estimate-builder(
      v-if='fullRequest'
      :update='recalculateEstimate' :remote-id='fullOrder.estimate_id',
      :cuisines='cuisines',  :index='0',
      :total-budget-cents='totalBudgetCents',
      :request-id='fullOrder.request_id', :tax-rate='taxRate'
      :fullOrder='fullOrder'
      )
</template>



<script>
import EstimateBuilder from './EstimateBuilder'
import _ from 'underscore'

function data() {
  return {
    loadingProducts: false,
    fullOrder: null,
    fullRequest: null,
    customer: null,
    modalVisible: false,
    vendorId: 0
  }
}

function loadOrder() {
  this.loadingProducts = true;
  const target = `/orders/${this.orderSummary.id}`
  // TODO catch
  return this.$simplecater.get(target).then((r) => {
    this.loadingProducts = false;
    this.fullOrder = r.data.data
    this.estimates = this.fullOrder.estimates
  }).catch(() => {
    this.loadingProducts = false;
    this.$message.error("Failed to load the order")
  })
}

function loadRequest() {
  const target = `/requests/${this.fullOrder.request_id}`;
  return this.$simplecater.get(target).then((r) => {
    this.fullRequest = r.data.data;
  }).catch(() => {
    this.$message.error("Failed to load the request")
  })
}

function loadCustomer() {
  const target = `/customers/${this.fullOrder.customer_id}`
  return this.$simplecater.get(target).then((r) => {
    this.customer = r.data.data
  }).catch(() => {
    this.$message.error("Failed to load the customer information")
  })
}

function openEditProductModal() {
  this.vendorId = this.fullOrder.vendor_id;
  this.modalVisible = true;
}

function recalculateEstimate(curentVendorID) {
  this.modalVisible = false;
  const body = { estimate_id: this.fullOrder.estimate_id };
  return this.$simplecater.post(`/orders/${this.fullOrder.id}/rebuild_items`, body).then(() => {
    this.$emit('updateOrders');
    if (curentVendorID && this.vendorId
      && this.activeIndex && curentVendorID !== this.vendorId
      && (this.activeIndex === 'assigned' || this.activeIndex === 'ordered')) {
      this.$message('You’ve changed vendor for order. Order has been reset to the “Received” status');
    } else {
      this.$message('Product changes saved');
    }
  }).catch(() => {
    this.$message.error('Products changes failed')
  });
}

function cloneOrder() {
  this.$confirm('This order will be cloned. Continue?', 'Warning', {
    confirmButtonText: 'OK',
    cancelButtonText: 'Cancel',
    type: 'warning'
  }).then(() => {
    this.$simplecater.post(`/orders/${this.fullOrder.id}/replicate`).then(() => {
      this.$message('Order is cloned');
    })
  }).catch(() => { })
}


function mounted() {
  this.loadOrder().then(() => {
    return this.loadRequest().then(() => {
      this.loadCustomer();
    });
  });
}

const watch = {
  '$route'(to, from) {
    console.log("MOVING!")
  }
}

const props = {
  orderSummary: {
    required: true, type: Object
  },
  activeIndex: {
    required: false,
    type: String
  }
}

const methods = {
  loadOrder, loadRequest, recalculateEstimate, openEditProductModal, cloneOrder, loadCustomer
}

const components = { EstimateBuilder }

const computed = {

  totalCents() {
    return _.reduce(this.fullOrder.items, (acc, i) => (acc + i.total_cents), 0) + (this.tips ? this.tips : 0)
  },

  itemsWithoutTax() {
    return this.fullOrder ? this.fullOrder.items.filter(item => item.type !== 'TAX') : null;
  },

  tax() {
    return this.fullOrder ? this.fullOrder.items.find(item => item.type === 'TAX') : null;
  },

  tips() {
    return this.fullOrder ? this.fullOrder.tips : null;
  },

  taxRate() {
    return !this.customer.tax_exempt ? this.fullRequest.tax_rate : 0;
  },

  subtotalCents() {
    return _.reduce(this.fullOrder.items, (memo, i) => {
      return (i.type != "TAX") ? memo + (i.sale_price_cents * i.quantity) : memo
    }, 0)
  },

  totalBudgetCents() {
    return this.fullRequest.budget_cents * this.fullRequest.people
  },

  cuisines() {
    return this.fullRequest && this.fullRequest.cuisines ? _.sortBy(this.fullRequest.cuisines, (x) => x.name.toLowerCase()) : null;
  }

}

export default { mounted, data, methods, props, components, computed, watch }
</script>

<style>
  .order__modal-cont {
    display: flex;
    align-items: center;
  }
  .order__modal-text {
    padding: 0 20px 0 0;
    margin: 0;
  }
  .order__modal-select {
    width: 40%;
  }
</style>
