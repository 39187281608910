<style scoped lang="sass">
.order-name
  display: inline-block
  font-weight: 600
  margin: 0 0.5rem
.secondary
  // display: inline-block
  color: #888
  font-size: 0.8em
  margin-left: 1.7rem
  .v-divider
    display: inline-block
    width: 1px
    background: #ccc
    height: 1.5em
    margin: 0 0.5rem
    vertical-align: middle
</style>

<template lang='pug'>
.main-container
  .view-content
    el-card(class='el-card__with-bg')
      div(ref='table')
        div(v-if='order && request && requestAddress && contact' slot='header')
          i.mat-icon assignment
          router-link(to='/user/orders') Orders
          |  &gt;
          .order-name {{ request.name }}
          status-tag(:status='order.status')
          .secondary
            i.mat-icon date_range
            |  {{ $formatDate(order.delivery_date) }}
            .v-divider
            i.mat-icon access_time
            |  {{ $formatTime(order.delivery_time) }}
            .v-divider
            i.mat-icon person
            | {{ request.people }}
            .v-divider
            span {{ $formatCents(totalCents) }}
            .v-divider
            em  Order \#{{ order.id }}
        order(v-if='order && request && requestAddress && contact', :order='order', :request='request',:request-address='requestAddress', :contact='contact', :contWidth='width')
</template>

<script>
import Order from './blocks/Order.vue'
import StatusTag from '../common/StatusTag.vue'
import _ from 'underscore'

function data() {
  return {
    order: null,
    request: null,
    requestAddress: null,
    contact: null,
    loading: true,
  }
}

function fetchData() {
  if (this.$route.matched[1].path !== '/user/requests/:id') {
    this.$simplecater.get('/orders/' + this.$route.params.id).then((r) => {
      this.order = r.data.data

      if (this.order.invoice_id) {
        this.$simplecater.get(`/invoices/${this.order.invoice_id}`).then((r) => {
          this.$mergeWithTips(r.data.data.tips, this.order)
        }).catch(() => {
          this.$message.error("Error loading the address information")
        })
      }

      this.$simplecater.get('/requests/' + this.order.request_id).then((r) => {
        this.request = r.data.data
        // Contact
        this.$simplecater.get('/contacts/' + this.request.contact_id).then((r) => {
          this.contact = r.data.data
        }).catch(() => {
          this.$message.error("Failed to load contact information")
        })
      }).catch(() => {
        this.$message.error("Failed to load order's request")
      })

      // Request Address
      this.$simplecater.get('/addresses/' + this.order.request_address_id).then((r) => {
        this.requestAddress = r.data.data
      }).catch(() => {
        this.$message.error("Failed to load order's address")
      })
    }).catch(() => {
      this.$message.error("Failed to load the order")
    })
  }
}

let computed = {
  totalCents() {
    return (this.order) ? this.totalCents + this.order.tips : 0
  },
  totalItems() {
    return (this.order) ? _.reduce(this.order.items, (acc, i) => (acc + i.total_cents), 0) : 0
  },
  width() {
    return this.$refs && this.$refs.table && this.$refs.table.clientWidth ? this.$refs.table.clientWidth : '';
  }
}

function created() {
  this.fetchData()
  this.$bus.$on('estimate-accepted', this.fetchData)
}

const methods = {
  fetchData,
}

const components = { Order, StatusTag }

// TODO - Re-fetch on route change/update
export default { data, methods, created, components, computed }
</script>