<template lang='pug'>
#main
  slot
</template>

<style scoped lang="sass">
header
  margin: 15mm
#main
  margin: 5mm 15mm
.clearfix
  clear: both
</style>

<script>
  export default {
    components: { }
  }
</script>
