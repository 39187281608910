<template>
    <div class="user-preferences-container">
        <div class="user-preferences">
        <custome-checkbox class="preference" v-if="preferences.drinks" title="Drinks" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.desserts" title="Desserts" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.no_red_meat" title="No red meat" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.healthy" title="Healthy" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.no_pork_products" title="No pork products" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.no_onions" title="No onions" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.half_pans" title="Half pans" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.kosher" title="Kosher" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.light_food" title="Light / No onions" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.set_up" title="Set up" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.utensils" title="Utensils" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.lunch_box" title="Lunch box / IND" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.hot_food" title="Hot food" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.include_salad" title="Include salad" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.vegetarian_options" title="Vegetarian options" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.vegan_options" title="Vegan options":disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.no_dairy" title="No dairy" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.gluten_free_needed" title="Gluten free needed" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.no_seafood" title="No fish/seafood" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.not_spicy" title="No spicy" :disable="true"/>
        <custome-checkbox class="preference" v-if="preferences.staff_needed" title="Staff needed" :disable="true"/>
        </div>
    </div>
</template>

<style scoped lang="sass">
.user-preferences-container
    //display: flex
.user-preferences
    margin: auto
    // column-count: auto
    // gap: 6.25rem
    // padding-top: 1.25rem
    // column-width: 10rem
    // width: 30rem
    display: flex
    flex-flow: wrap
    .preference
        margin-bottom: 1.25rem
        flex: 0 0 50%
</style>

<script>
import CustomeCheckbox from './CustomeCheckbox.vue'

export default {
    props: {
        preferences: {
            require: true
        }
    },
    components: {
        CustomeCheckbox
    }

   }
</script>
 