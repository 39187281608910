<style scoped lang="sass">
.save-preferences
  width: 100%
  display: flex
  justify-content: flex-end
  margin-top: 1.25rem
</style>
<template>
<el-card>
    <el-form v-if="preferences != null" ref="preferencesForm" :rules="rules" :model="preferences" :label-position="labelPosition">
        <el-form-item label="" prop="budget">
            <preferences-builder :initial-value="preferences" ref="preferencesBuilder" :squareShapes="true"></preferences-builder>
        </el-form-item>
        <el-form-item label="Budget per person" prop="budget">
            <el-input v-model="preferences.budget"><template slot="prepend">$</template></el-input>
        </el-form-item>
        <el-form-item>
          <div class="save-preferences">
            <default-button title="Save Changes" @onClick="onSave">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M2.66699 8H13.3337M13.3337 8L9.33366 4M13.3337 8L9.33366 12" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </default-button>
          </div>
        </el-form-item>
    </el-form>
</el-card>
</template>

<script>
  import Vue from 'vue'
  import PreferencesBuilder from './UserPreserencesBuilder.vue'
  import DefaultButton from '@/layout/Blocks/DefaultButton.vue'

  function data(){
    return {
      saving: false,
      rules: {},
      preferences: null
    }
  }

  function getPreferences(){
    this.$simplecater.get('/preferences').then((r)=> {
      const preferences = r.data.data
      this.preferences = {
        ...preferences, budget: preferences.budget_cents / 100
      }
    })
  }

  function updatePreferences(){
    const submitData = Object.assign(
      {},
      this.$refs.preferencesBuilder.preferences,
      { budget_cents: this.preferences.budget * 100 }
    )
    this.savingPreferences = true
    this.$simplecater.put('/preferences', submitData).then((r)=> {
      this.savingPreferences = false
      this.$message.info("Your preferences have been updated")
    }).catch(()=>{
      this.savingPreferences = false
      // TODO catch
      this.$message.error("The operation failed, please try again")
    })
    console.log("SENDING", submitData)
  }

  function created(){
    this.getPreferences()
  }

  function onSave(){
    this.$refs.preferencesForm.validate((valid)=>{
      if (valid) {
        this.updatePreferences()
      }else{
        this.$message.error("Please check your input")
      }
    })
  }

  const methods = {
    onSave, getPreferences, updatePreferences
  }

  const props = {
    labelWidth: { default: Vue.$labelWidth },
    size: { default: Vue.$defaultFormSize },
    labelPosition: { default: Vue.$defaultlabelPosition },
    displaySave: {default: false}
  }

  const components = { PreferencesBuilder, DefaultButton }

  export default { props, data, methods, created, components }
</script>
