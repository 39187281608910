export default {
    name: 'modifyPhoneNumber',
    methods: {
        modifiedPhoneNumber: (rowNumber) => {
            if(rowNumber) {
                const cleanNumber = rowNumber.replace(/[^\d]/g, '')
                const firstPart =  `(${cleanNumber.slice(0, 3)})`
                const secondPart = `${cleanNumber.slice(3, -4)}-${cleanNumber.slice(6)}`
                return `${firstPart} ${secondPart}`
            } else return null;
        }
    }
}