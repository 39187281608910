<template lang="pug">
div
  el-table(:data='addresses', v-loading='addresses == null')
    el-table-column(label='Address')
      template(slot-scope='scope')
        | {{scope.row.address_line_1}}
        br
        | {{scope.row.address_line_2}}
    el-table-column(label='City / Zip code')
      template(slot-scope='scope')
        | {{scope.row.city}}, {{scope.row.state}}
        br
        | {{scope.row.zip_code}}
    el-table-column(label='Actions')
      template(slot-scope='scope')
        el-button(type='text' size='small', @click='editAddress(scope.row.id)') Edit
        el-button(type='text' size='small', @click='deleteAddress(scope.row.id)') Delete
</template>


<script>
import Drawer from '../../layout/Drawer.vue'
import AddressBuilder from '../../common/AddressBuilder.vue'

function data ( ) {
  return {
    loading: false,
    activeTab: 'addresses'
  }
}

function created ( ) {}

const components = { Drawer, AddressBuilder }

const methods = {
  editAddress ( id ) {
    this.$router.push('/staff/vendors/' + this.vendor.id + '/addresses/' + id + '/edit')
  },
  deleteAddress(id) {
    this.$simplecater.delete(`/vendors/${this.vendor.id}/addresses/${id}`)
      .then(() => {
        this.$message.success('Address successfully deleted')
        this.$emit('vendorDeleted')
      })
      .catch(() => (this.$message.error('Something went wrong')))
  }
}

let props = {
  vendor: {},
  addresses: {},
}

export default { props, data, created, components, methods }
</script>

<style lang="sass" scoped>
.cell .el-button
  padding-left: 0
</style>