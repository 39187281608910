<template lang="pug">
drawer(:dismissRoute="'/staff/vendors/' + id")
  .drawer-title
    i.mat-icon mode_edit
    | Edit Address

  address-builder(:displaySave='true', @saveClick='saveAddress', :loading='loading', :base-data='address', :has-notes='true')
</template>


<script>
import Drawer from '../layout/Drawer.vue'
import AddressBuilder from '../common/AddressBuilder.vue'

function saveAddress ( address ) {
  const target = `/vendors/${address.vendor_id}/addresses/${address.id}`
  this.loading = true
  this.$simplecater.put(target, address).then((r)=>{
    this.$message("The address has been created")
    this.loading = false
    this.$bus.$emit('address-saved')
    this.$router.push('/staff/vendors/' + this.$route.params.id)
  }).catch(()=>{
    this.$message.error("Failed to save the address")
    this.loading = false
  })
}

function data ( ) {
  return {
    loading: false,
    address: null,
  }
}

function created ( ) {
  const target = `/addresses/${this.aid}`
  this.loading = true
  this.$simplecater.get(target).then((r)=>{
    let a = r.data.data
    if ( typeof a.notes != 'string' ) a.notes = ''
    this.address = a
    this.loading = false
  }).catch(()=>{
    this.$message.error("Failed to fetch address")
    this.loading = false
  })
}

const components = { Drawer, AddressBuilder }

const methods = { saveAddress }

let props = {
  id: {},
  aid: {},
}

export default { props, data, created, components, methods }
</script>
