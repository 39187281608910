<style scoped lang="sass">
  .el-checkbox-group
    margin-left: 20px
</style>
<template lang='pug'>
.main-container

  .view-content
    el-card
      div(slot='header')
        i.mat-icon receipt
        | Invoices
      el-tabs( v-model='activeIndex', type='card' )
        el-row(:gutter='10')
          el-col(:span='24')
            el-col(:span='9')
              el-input(v-model='sorting' prefix-icon="el-icon-search" placeholder="Search")
            el-checkbox-group(v-model="payment" style="display: inline-block;")
              el-checkbox(label="stripe" value="stripe")
                | Stripe
              el-checkbox(label="check" value="check")
                | Check
        el-tab-pane(
          v-for='filter in filters' :label='$upcaseFirst(filter)',
          :name='filter', :key='filter'
        )
          invoice-list(:filter='filter', :payment='payment', :sorting='sorting',  v-if='filter == activeIndex')
</template>

<script>
  import InvoiceList from './blocks/InvoiceList.vue'
  function data(){
    return {
      filters: ['open', 'closed', 'all'],
      payment: ['stripe', 'check'],
      sorting:'',
      invoices: null,
      activeIndex: 'open',
      loadingInvoices: true,
      loadingContacts: true
    }
  }
  function getInvoices(){
    this.loadingInvoices = true
    return this.$simplecater.get('/invoices').then((r)=> {
      this.invoices = r.data.data
      this.loadingInvoices = false
    })
  }
  // function goToAddressBuilder(){
  //   this.$message("Pending wiring")
  //   //this.$router.push("/user/invoices/new")
  // }
  //
  //
  // function goToContactBuilder(){
  //   this.$message("Pending wiring")
  //   //this.$router.push("/user/invoices/new")
  // }
  function created(){
    this.getInvoices()
  }
  const watch = {
    '$route' (to, from) {
      console.log("Reloading data")
      this.getInvoices()
    }
  }
  const components = { InvoiceList }
  const methods = {
    getInvoices
  }
  export default { methods, components, data, created }
</script>